import Flex from "@react-css/flex";
import React, { useEffect, useState, useRef } from "react";
import classes from "./Volunteer.module.scss";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import FormHeader from "../../components/form-header-text/FormHeader";
import LeftSide from "../../components/form-left-side/LeftSide";
import MainLayout from "../../layouts/MainLayout";
import { withTranslation } from "react-i18next";
import FormInput from "../../components/form-input/FormInput";
import CitiesSelectList from "../../components/form-select-lists/CitiesSelectList";
import VolunteeringSelectList from "../../components/form-select-lists/VolunteeringSelectList";
import VolunteerServices from "./VolunteerSerivces";
import { baseUrl, isArabic } from "../../helpers/helpersFunctions";
import axios from "axios";
import FormAlert from "../../components/alerts/FormAlert";
import { useNavigate } from "react-router-dom";
import { Desktop, Tablet, Mobile } from "../../helpers/BreakPoints";
import Loader from "../../components/loader/Loader";

const Volunteer = ({ t }) => {
  const [isYes, setIsYes] = useState(false);
  const [isNo, setIsNo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [leftSideData, setVolunteerLeftSideData] = useState([]);
  const [leftSideImage, setLeftSideImage] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [passed, setPassed] = useState(null);
  const vServices = new VolunteerServices(baseUrl);
  const navigate = useNavigate();
  // Refs Part
  const firstName = React.createRef();
  const lastName = React.createRef();
  const contactNumber = React.createRef();
  const email = React.createRef();
  const DOB = React.createRef();
  const city = React.createRef();
  const volunteeringReason = React.createRef();

  const handleYesClick = (e) => {
    setIsYes(true);
    setIsNo(false);
  };

  const handleNoClick = (e) => {
    setIsYes(false);
    setIsNo(true);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      // Adding data to formData
      formData.append("first_name", firstName.current.value);
      formData.append("last_name", lastName.current.value);
      formData.append("contact_number", contactNumber.current.value);
      formData.append("email", email.current.value);
      formData.append("date_of_birth", DOB.current.value);
      formData.append("ever_participated", isYes ? 1 : 0);
      formData.append("city_id", city.current.value);
      formData.append("volunteer_reason_id", volunteeringReason.current.value);
      formData.append("type_of_volunteering", "Administrative");

      const response = await axios({
        method: "post",
        url: `${baseUrl}/VolunteerNow`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        setPassed(true);
        setShowAlert(true);
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setPassed(false);
        showAlert(true);
      }
    } catch (err) {
      setPassed(false);
      setShowAlert(true);
      throw err;
    }
  };

  useEffect(() => {
    document.title = t("Volunteer");
    vServices.getVolunteerFormData(setIsLoading, setVolunteerLeftSideData, setLeftSideImage);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Desktop>
            <MainLayout addPadding={false}>
              {/* Adding alert */}
              {showAlert && <FormAlert passed={passed} />}
              <Container fluid className={classes.formContainer}>
                <Row>
                  <Col lg={4} md={5} style={{ ispaddingLeft: "50px" }}>
                    <LeftSide
                      image={leftSideImage[0].image}
                      bodyTitle={
                        isArabic
                          ? leftSideData[0].value_ar
                          : leftSideData[0].value
                      }
                      bodyDesc={
                        isArabic
                          ? leftSideData[1].value_ar
                          : leftSideData[1].value
                      }
                    />
                  </Col>
                  <Col lg={8} md={7}>
                    <Container>
                      <Flex column className={classes.formFieldsHolder}>
                        <FormHeader
                          mainTitle={
                            isArabic
                              ? leftSideData[2].value_ar
                              : leftSideData[2].value
                          }
                          subTitle={
                            isArabic
                              ? leftSideData[3].value_ar
                              : leftSideData[3].value
                          }
                        />
                        <Form
                          method="POST"
                          className={classes.mainForm}
                          encType="multipart/form-data"
                          onSubmit={handleOnSubmit}
                        >
                          <Row className={classes.formFieldsRowContainer}>
                            <div className={classes.formToptext}>
                              <p>{t("Personal Info")}</p>
                            </div>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("First Name")}
                                required={true}
                                id={"first-name"}
                                formRef={firstName}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("Last Name")}
                                required={true}
                                id={"last-name"}
                                formRef={lastName}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("Contact number")}
                                required={true}
                                id={"contact-number"}
                                formRef={contactNumber}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"email"}
                                label={t("Email")}
                                required={true}
                                id={"email"}
                                formRef={email}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"date"}
                                label={t("Date of birth")}
                                required={true}
                                id={"date-of-birth"}
                                formRef={DOB}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <CitiesSelectList
                                label={t("City")}
                                id={"city"}
                                formRef={city}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <label>
                                {t("Have you participated in voluntary work?")}
                              </label>
                              <Flex row className={classes.yesNoBtnHolder}>
                                <Button
                                  variant="outline"
                                  data-value="1"
                                  className={
                                    isYes
                                      ? `${classes.btnActive} ${classes.yesBtn}`
                                      : classes.yesBtn
                                  }
                                  onClick={handleYesClick}
                                >
                                  {t("Yes")}
                                </Button>
                                <Button
                                  variant="outline"
                                  data-value="0"
                                  className={
                                    isNo
                                      ? `${classes.btnActive} ${classes.noBtn}`
                                      : classes.noBtn
                                  }
                                  onClick={handleNoClick}
                                >
                                  {t("No")}
                                </Button>
                              </Flex>
                            </Col>
                            <Col lg={5} md={5}>
                              <VolunteeringSelectList
                                label={"Reason for volunteering"}
                                id={"voulnteering-reasons"}
                                formRef={volunteeringReason}
                              />
                            </Col>
                            <div className={classes.btnSubmitHolder}>
                              <button
                                className={`btn ${classes.btnSend}`}
                                type="submit"
                              >
                                {t("Send")}
                              </button>
                            </div>
                          </Row>
                        </Form>
                      </Flex>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </MainLayout>
          </Desktop>

          {/* Tablet */}
          <Tablet>
            <MainLayout addPadding={true}>
              {/* Adding alert */}
              {showAlert && <FormAlert passed={passed} />}
              <Container fluid className={classes.formContainer}>
                <Row>
                  <Col lg={4} md={6}>
                    <LeftSide
                    image={leftSideImage[0].image}
                      bodyTitle={
                        isArabic
                          ? leftSideData[0].value_ar
                          : leftSideData[0].value
                      }
                      bodyDesc={
                        isArabic
                          ? leftSideData[1].value_ar
                          : leftSideData[1].value
                      }
                    />
                  </Col>
                  <Col lg={8} md={6}>
                    <Container>
                      <Flex column className={classes.formFieldsHolder}>
                        <FormHeader
                          mainTitle={
                            isArabic
                              ? leftSideData[2].value_ar
                              : leftSideData[2].value
                          }
                          subTitle={
                            isArabic
                              ? leftSideData[3].value_ar
                              : leftSideData[3].value
                          }
                        />
                        <Form
                          method="POST"
                          className={classes.mainForm}
                          encType="multipart/form-data"
                          onSubmit={handleOnSubmit}
                        >
                          <Row className={classes.formFieldsRowContainer}>
                            <div className={classes.formToptext}>
                              <p>{t("Personal Info")}</p>
                            </div>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("First Name")}
                                required={true}
                                id={"first-name"}
                                formRef={firstName}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("Last Name")}
                                required={true}
                                id={"last-name"}
                                formRef={lastName}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("Contact number")}
                                required={true}
                                id={"contact-number"}
                                formRef={contactNumber}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"email"}
                                label={t("Email")}
                                required={true}
                                id={"email"}
                                formRef={email}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"date"}
                                label={t("Date of birth")}
                                required={true}
                                id={"date-of-birth"}
                                formRef={DOB}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <CitiesSelectList
                                label={t("City")}
                                id={"city"}
                                formRef={city}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <label>
                                {t("Have you participated in voluntary work?")}
                              </label>
                              <Flex row className={classes.yesNoBtnHolder}>
                                <Button
                                  variant="outline"
                                  data-value="1"
                                  className={
                                    isYes
                                      ? `${classes.btnActive} ${classes.yesBtn}`
                                      : classes.yesBtn
                                  }
                                  onClick={handleYesClick}
                                >
                                  {t("Yes")}
                                </Button>
                                <Button
                                  variant="outline"
                                  data-value="0"
                                  className={
                                    isNo
                                      ? `${classes.btnActive} ${classes.noBtn}`
                                      : classes.noBtn
                                  }
                                  onClick={handleNoClick}
                                >
                                  {t("No")}
                                </Button>
                              </Flex>
                            </Col>
                            <Col lg={5} md={5}>
                              <VolunteeringSelectList
                                label={"Reason for volunteering"}
                                id={"voulnteering-reasons"}
                                formRef={volunteeringReason}
                              />
                            </Col>
                            <div className={classes.btnSubmitHolder}>
                              <button
                                className={`btn ${classes.btnSend}`}
                                type="submit"
                              >
                                {t("Send")}
                              </button>
                            </div>
                          </Row>
                        </Form>
                      </Flex>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </MainLayout>
          </Tablet>

          {/* Mobile */}
          <Mobile>
            <MainLayout addPadding={false}>
              {/* Adding alert */}
              {showAlert && <FormAlert passed={passed} />}
              <Container fluid className={classes.formContainer}>
                <Row>
                  <Col lg={4} md={5} style={{ marginTop: "30px" }}>
                    <LeftSide
                    image={leftSideImage[0].image}
                      bodyTitle={
                        isArabic
                          ? leftSideData[0].value_ar
                          : leftSideData[0].value
                      }
                      bodyDesc={
                        isArabic
                          ? leftSideData[1].value_ar
                          : leftSideData[1].value
                      }
                      removeHeightWidth={true}
                    />
                  </Col>
                  <Col lg={8} md={7}>
                    <Container style={{ marginTop: "30px" }}>
                      <Flex column className={classes.formFieldsHolder}>
                        <FormHeader
                          mainTitle={
                            isArabic
                              ? leftSideData[2].value_ar
                              : leftSideData[2].value
                          }
                          subTitle={
                            isArabic
                              ? leftSideData[3].value_ar
                              : leftSideData[3].value
                          }
                        />
                        <Form
                          method="POST"
                          className={classes.mainForm}
                          encType="multipart/form-data"
                          onSubmit={handleOnSubmit}
                        >
                          <Row className={classes.formFieldsRowContainer}>
                            <div className={classes.formToptext}>
                              <p>{t("Personal Info")}</p>
                            </div>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("First Name")}
                                required={true}
                                id={"first-name"}
                                formRef={firstName}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("Last Name")}
                                required={true}
                                id={"last-name"}
                                formRef={lastName}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("Contact number")}
                                required={true}
                                id={"contact-number"}
                                formRef={contactNumber}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"email"}
                                label={t("Email")}
                                required={true}
                                id={"email"}
                                formRef={email}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"date"}
                                label={t("Date of birth")}
                                required={true}
                                id={"date-of-birth"}
                                formRef={DOB}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <CitiesSelectList
                                label={t("City")}
                                id={"city"}
                                formRef={city}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <label>
                                {t("Have you participated in voluntary work?")}
                              </label>
                              <Flex row className={classes.yesNoBtnHolder}>
                                <Button
                                  variant="outline"
                                  data-value="1"
                                  className={
                                    isYes
                                      ? `${classes.btnActive} ${classes.yesBtn}`
                                      : classes.yesBtn
                                  }
                                  onClick={handleYesClick}
                                >
                                  {t("Yes")}
                                </Button>
                                <Button
                                  variant="outline"
                                  data-value="0"
                                  className={
                                    isNo
                                      ? `${classes.btnActive} ${classes.noBtn}`
                                      : classes.noBtn
                                  }
                                  onClick={handleNoClick}
                                >
                                  {t("No")}
                                </Button>
                              </Flex>
                            </Col>
                            <Col lg={5} md={5}>
                              <VolunteeringSelectList
                                label={"Reason for volunteering"}
                                id={"voulnteering-reasons"}
                                formRef={volunteeringReason}
                              />
                            </Col>
                            <div className={classes.btnSubmitHolder}>
                              <button
                                className={`btn ${classes.btnSend}`}
                                type="submit"
                              >
                                {t("Send")}
                              </button>
                            </div>
                          </Row>
                        </Form>
                      </Flex>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </MainLayout>
          </Mobile>
        </>
      )}
    </>
  );
};

export default withTranslation()(Volunteer);
