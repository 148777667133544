import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import classes from "./WorkWithUs.module.scss";

function WorkList({ data }) {
  const { t } = useTranslation();
  return (
    <Container className={classes.JobsPage}>
      <Row className={classes.JobsTitleHolder}>
        <Col>
          <h1>{t("jobs")}</h1>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-start mt-0 mb-5">
        <Col sm={12}>
          <ul>
            {data.map((d, i) => {
              const { PositionName, Country, Location, URL } = d;
              return (
                <li key={PositionName + Country + Location + i}>
                  <div>
                    <span>{PositionName}</span>
                    <span>{Country}</span>
                    <span>{Location}</span>
                  </div>
                  <button onClick={() => (window.location.href = URL)}>
                    {t("apply now")}
                  </button>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default WorkList;
