/**
 * here where we write our helper functions
 * @returns Date
 */

import i18n from "../i18n";

export const getTodaysDate = () => {
    const date = new Date();
    const today = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    return `${today} ${month}, ${date.getFullYear()}`;
}

export const isArabic = i18n.resolvedLanguage === "ar" ? true : false;

export const baseUrl = process.env.REACT_APP_BASE_URL;
export const storageUrl = process.env.REACT_APP_STORAGE_URL;