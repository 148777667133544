import React from "react";
import Flex from "@react-css/flex";
import { withTranslation } from "react-i18next";
import classes from "./BannerText.module.scss";
import { isArabic } from "../../helpers/helpersFunctions";
import { Desktop, Tablet, Mobile } from "../../helpers/BreakPoints";

const BannerText = ({ number, title, desc, deviceType = null, t }) => {
  return (
    <>
      <Desktop>
        <Flex row alignItems="center" className={classes.mainContainer}>
          <div
            className={classes.numberHolder}
            style={{
              marginRight: isArabic && 0,
              marginLeft: isArabic && "30px",
            }}
          >
            <p style={{ fontSize: deviceType === "tablet" && "40px" }}>
              {number}
            </p>
          </div>
          <div className={classes.section2TextHolder}>
            <div className={classes.titleHolder}>
              <h2 style={{ fontSize: deviceType === "tablet" && "13px" }}>
                {title}
              </h2>
            </div>
            <div className={classes.descTitle}>
              <p style={{ fontSize: deviceType === "tablet" && "13px" }}>
                {desc}
              </p>
            </div>
          </div>
        </Flex>
      </Desktop>

      {/* Tablet */}
      <Tablet>
        <Flex row alignItems="center" className={classes.mainContainer}>
          <div
            className={classes.numberHolder}
            style={{
              marginRight: isArabic && 0,
              marginLeft: isArabic && "30px",
            }}
          >
            <p style={{ fontSize: deviceType === "tablet" && "40px" }}>
              {number}
            </p>
          </div>
          <div className={classes.section2TextHolder}>
            <div className={classes.titleHolder}>
              <h2 style={{ fontSize: deviceType === "tablet" && "13px" }}>
                {title}
              </h2>
            </div>
            <div className={classes.descTitle}>
              <p style={{ fontSize: deviceType === "tablet" && "13px" }}>
                {desc}
              </p>
            </div>
          </div>
        </Flex>
      </Tablet>

      {/* Mobile */}
      <Mobile>
        <Flex column alignItems="center" className={classes.mainContainer}>
          <div className={classes.numberHolder} style={{ marginRight: 0 }}>
            <p>{number}</p>
          </div>
          <div
            className={classes.section2TextHolder}
            style={{ textAlign: "center" }}
          >
            <div className={classes.titleHolder}>
              <h2>{title}</h2>
            </div>
            <div className={classes.descTitle}>
              <p>{desc}</p>
            </div>
          </div>
        </Flex>
      </Mobile>
    </>
  );
};

export default withTranslation()(BannerText);
