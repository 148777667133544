import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import MainLayout from "../../layouts/MainLayout";
import classes from "./SuccessStories.module.scss";
import bluredYellowDoe from "../../assets/images/index-media/blured-yellow-dot.svg";
import { baseUrl, isArabic, storageUrl } from "../../helpers/helpersFunctions";
import { Link } from "react-router-dom";
import StoryCards from "../../components/story-card/StoryCards";
import StoriesServices from "./SuccessStoryServices";
import Loader from "../../components/loader/Loader";
import Flex from "@react-css/flex";
import { Desktop, Tablet, Mobile } from "../../helpers/BreakPoints";

const SuccessStories = ({ t }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [storiesTexts, setStoriesTexts] = useState([]);
  const [storiesImages, setStoriesImages] = useState([]);
  const [stories, setStories] = useState([]);
  const storiesServices = new StoriesServices(baseUrl);


  useEffect(() => {
    document.title = t("Our Youth");
    storiesServices.getAllData(setIsLoading, setStoriesTexts, setStoriesImages, setStories);
  }, []);

  return (
    <>
    { isLoading ? <Loader/> :
    <>
    {/* Desktop */}
    <Desktop>
      <MainLayout addPadding={false}>
        <section className={classes.storiesSection1}>
        <Container >
          <Row className={classes.section3ImagesRow}>
            <Container className="d-flex">
              <Row style={{paddingLeft: !isArabic ? "50px" : 0, paddingRight: isArabic ? "50px" : 0}}>
              <Col
                lg={4}
                md={2}
                className="d-flex align-items-center justify-content-end"
              >
                <div
                  className={classes.imageTextDivHolder}
                  style={{ position: "relative" }}
                >
                  <div className={classes.firstDivTitle}>
                    <h1>{isArabic ? storiesTexts[0].value_ar : storiesTexts[0].value}</h1>
                  </div>
                  <div className={classes.firstDivDesc}>
                    <p>{isArabic ? storiesTexts[1].value_ar : storiesTexts[1].value}</p>
                  </div>
                </div>
              </Col>
              { stories.map((story, index) => {
              return <Col lg={4} md={6} key={story.id}>
                <StoryCards
                  avatar={storageUrl + story.image}
                  title={isArabic ? story.title_ar : story.title }
                  desc={isArabic ? story.text_ar : story.text }
                id={story.id}
                />
              </Col>
              })}
              </Row>
            </Container>
          </Row>
        </Container>
        </section>

        <section className={classes.storiesSection2}>
          <Container>
            <Row>
              <Col lg={6} md={6} className={classes.imgColHolder}>
                <div className={classes.storiesSection2ImageHolder}>
                    <img className="img-fluid" src={ storageUrl + storiesImages[0].image} alt="Join Cover" />
                </div>
              </Col>
              <Col lg={6} md={6}>
                <Flex column className={classes.storiesSection2ImageHolder} width={298} style={{height:"100%"}} alignItemsCenter justifyContent={'space-evenly'}>
                    <div className={classes.storiesSection2TitleHolder}>
                        <h1>
                          {isArabic ? storiesTexts[2].value_ar : storiesTexts[2].value}
                        </h1>
                    </div>
                    <Flex row className={classes.succesStoriesBtnsHolder} alignItemsBaseline >
                        <div className={classes.textLinkHolder}>
                            <Link to="/volunteer">{t("Read more")}</Link>
                        </div>
                        <div className={classes.yellowBtnHolder}>
                        <Link className={classes.successStroyEligBtn} to="/eligibility">
                            {t("Requirements")}
                        </Link>
                        </div>
                    </Flex>
                </Flex>
              </Col>
            </Row>
          </Container>
        </section>
      </MainLayout>
      </Desktop>

      {/* Tablet */}
      <Tablet>
      <MainLayout addPadding={false}>
        <section className={classes.storiesSection1}>
        <Container >
          <Row className={classes.section3ImagesRow}>
            <Container className="d-flex">
              <Row >
              <Col
                lg={4}
                md={6}
                className="d-flex align-items-center justify-content-end"
              >
                <div
                  className={classes.imageTextDivHolder}
                  style={{ position: "relative" }}
                >
                  <div className={classes.firstDivTitle} style={{width:"auto"}}>
                    <h1 style={{fontSize:"40px"}}>{isArabic ? storiesTexts[0].value_ar : storiesTexts[0].value}</h1>
                  </div>
                  <div className={classes.firstDivDesc}>
                    <p>{isArabic ? storiesTexts[1].value_ar : storiesTexts[1].value}</p>
                  </div>
                </div>
              </Col>
              { stories.map((story, index) => {
              return <Col lg={4} md={6} key={story.id}>
                <StoryCards
                  avatar={storageUrl + story.image}
                  title={isArabic ? story.title_ar : story.title }
                  desc={isArabic ? story.text_ar : story.text }
                  id={story.id}
                  deviceType={"tablet"}
                />
              </Col>
              })}
              </Row>
            </Container>
          </Row>
        </Container>
        </section>

        <section className={classes.storiesSection2}>
          <Container>
            <Row>
              <Col lg={6} md={6} className={classes.imgColHolder}>
                <div className={classes.storiesSection2ImageHolder}>
                    <img className="img-fluid" src={ storageUrl + storiesImages[0].image} alt="Join Cover" />
                </div>
              </Col>
              <Col lg={6} md={6}>
                <Flex column className={classes.storiesSection2ImageHolder} width={298} alignItemsCenter>
                    <div className={classes.storiesSection2TitleHolder}>
                        <h1 style={{fontSize:"42px", lineHeight:"50px"}}>
                          {isArabic ? storiesTexts[2].value_ar : storiesTexts[2].value}
                        </h1>
                    </div>
                    <Flex row className={classes.succesStoriesBtnsHolder} alignItemsBaseline >
                        <div className={classes.textLinkHolder}>
                            <Link to="/volunteer">{t("Read more")}</Link>
                        </div>
                        <div className={classes.yellowBtnHolder}>
                        <Link className={classes.successStroyEligBtn} to="/eligibility">
                            {t("Requirements")}
                        </Link>
                        </div>
                    </Flex>
                </Flex>
              </Col>
            </Row>
          </Container>
        </section>
      </MainLayout>
      </Tablet>

      {/* Mobile */}
      <Mobile>
      <MainLayout addPadding={false}>
        <section className={classes.storiesSection1}>
        <Container>
          <Row className={classes.section3ImagesRow}>
            <Container className="d-flex">
              <Row >
              <Col
                lg={4}
                md={2}
                className="d-flex align-items-center justify-content-end"
              >
                <div
                  className={classes.imageTextDivHolder}
                  style={{ position: "relative" }}
                >
                  <div className={classes.firstDivTitle} style={{width:"auto", fontSize:"32px"}}>
                    <h1>{isArabic ? storiesTexts[0].value_ar : storiesTexts[0].value}</h1>
                  </div>
                  <div className={classes.firstDivDesc}>
                    <p>{isArabic ? storiesTexts[1].value_ar : storiesTexts[1].value}</p>
                  </div>
                </div>
              </Col>
              { stories.map((story, index) => {
              return <Col lg={4} md={6} key={story.id}>
                <StoryCards
                  avatar={storageUrl + story.image}
                  title={isArabic ? story.title_ar : story.title }
                  desc={isArabic ? story.text_ar : story.text }
                id={story.id}
                />
              </Col>
              })}
              </Row>
            </Container>
          </Row>
        </Container>
        </section>

        <section className={classes.storiesSection2}>
          <Container>
            <Row>
              <Col lg={6} md={6} className={classes.imgColHolder}>
                <div className={classes.storiesSection2ImageHolder}>
                    <img className="img-fluid" src={ storageUrl + storiesImages[0].image} alt="Join Cover" />
                </div>
              </Col>
              <Col lg={6} md={6}>
                <Flex column className={classes.storiesSection2ImageHolder} width={298} alignItemsCenter>
                    <div className={classes.storiesSection2TitleHolder} style={{marginTop:"15px"}}>
                        <h1 style={{fontSize:"32px", lineHeight:"34px"}}>
                          {isArabic ? storiesTexts[2].value_ar : storiesTexts[2].value}
                        </h1>
                    </div>
                    <Flex row className={classes.succesStoriesBtnsHolder} alignItemsBaseline >
                        <div className={classes.textLinkHolder}>
                            <Link to="/volunteer">{t("Read more")}</Link>
                        </div>
                        <div className={classes.yellowBtnHolder}>
                        <Link className={classes.successStroyEligBtn} to="/eligibility">
                            {t("Requirements")}
                        </Link>
                        </div>
                    </Flex>
                </Flex>
              </Col>
            </Row>
          </Container>
        </section>
      </MainLayout>
      </Mobile>
    </>}
    </>
  );
};

export default withTranslation()(SuccessStories);
