import React from "react";
import { withTranslation } from "react-i18next";
import classes from "./AdmissionRequirements.module.scss";
import { Desktop, Tablet, Mobile } from "../../helpers/BreakPoints";


const AdmissionRequirements = ({ icon, title, desc, isLeft=true, isMobile=false,t }) => {

  return (
    <>
    {/* Desktop Part */}
    <Desktop>
      <div
      className={ isLeft ? classes.admissionRequiremntHolderLeft : classes.admissionRequiremntHolderRight }
      style={{marginTop: isMobile && "15px", width: isMobile && "100%", display:"flex", flexDirection:"column"}}
      >
        <div className={classes.iconHolder}>
          <img src={icon} alt="Bachelor" />
        </div>
        <div className={classes.admissionTitleHolder}>
          <h2>{title}</h2>
        </div>
        <div className={classes.admissionDescHolder}>
          <p>
            {desc}
          </p>
        </div>
      </div>
      </Desktop>

      {/* Tablet */}
      <Tablet>
      <div
      className={ classes.admissionRequiremntHolderTablet }
      style={{marginTop: isMobile && "15px", width: isMobile && "100%", display:"flex", flexDirection:"column"}}
      >
        <div className={classes.iconHolder}>
          <img src={icon} alt="Bachelor" />
        </div>
        <div className={classes.admissionTitleHolder} style={{alignSelf:"center"}}>
          <h2 style={{width:"auto"}}>{title}</h2>
        </div>
        <div className={classes.admissionDescHolder}>
          <p style={{width:"auto"}}>
            {desc}
          </p>
        </div>
      </div>
      </Tablet>

      {/* Mobile */}
      <Mobile>
      <div
      className={ isLeft ? classes.admissionRequiremntHolderLeft : classes.admissionRequiremntHolderRight }
      style={{marginTop: isMobile && "15px", width: isMobile && "100%", display:"flex", flexDirection:"column"}}
      >
        <div className={classes.iconHolder}>
          <img src={icon} alt="Bachelor" />
        </div>
        <div className={classes.admissionTitleHolder}>
          <h2>{title}</h2>
        </div>
        <div className={classes.admissionDescHolder}>
          <p>
            {desc}
          </p>
        </div>
      </div>
      </Mobile>
    </>
  );
};

export default withTranslation()(AdmissionRequirements);
