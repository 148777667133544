import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./styles.scss";
import { Desktop, Tablet, Mobile } from '../../helpers/BreakPoints';



const NewsCardComponent = ({id, thumb, title, date, bg= false ,t}) => {
  return (
    <>
    <Link to={`/news/${id}`} className="news-slug">
        <Card className="card-main" style={{background: (bg === true) && '#F0F4F7'}}>
            <Card.Img className="card-slider-image" variant="top" src={thumb} />
            <Card.Body className="swiperCardBody">
              <Card.Text className="card-body-text">
              {title}
              </Card.Text>
              <div className="date-text-holder text-white">
              {/* <small style={{color: (bg === true) && "#ABABB3"}}>{date}</small> */}
              </div>
            </Card.Body>
          </Card>
          </Link>
    </>
  )
}

export default withTranslation()(NewsCardComponent);