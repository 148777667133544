import React from "react";
import { Card } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classes from "./StoryCards.module.scss";



const StoryCards = ({id, avatar, title, desc, deviceType=null,t}) => {

  const tabletStyle = {
    width:"auto",
    left:0,
    right:0,
    margin:"auto"
  }

  return (
    <>
      <Link to={`/story-details/${id}`}>
      <Card style={{ width: "18rem" }} className={classes.card}>
        <Card.Img variant="top" src={avatar} style={{height:"288px", width:"288px", borderRadius:"25px"}} />
        <Card.Body className={classes.cardBody} style={deviceType === "tablet" ? tabletStyle : {}}>
          <Card.Title className={classes.cardTitle}>
            {title}
          </Card.Title>
          <Card.Text className={classes.cardDesc}>
            {desc.slice(0,150) + "..."}
          </Card.Text>
        </Card.Body>
      </Card>
      </Link>
    </>
  );
};

export default withTranslation()(StoryCards);
