import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/MainLayout";
import { withTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./News.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { getTodaysDate } from "../../helpers/helpersFunctions";
import NewsCardComponent from "../../components/news-slider/NewsCardComponent";
import { baseUrl, storageUrl, isArabic } from "../../helpers/helpersFunctions";
import Loader from "../../components/loader/Loader";
import PaginationItems from "../../components/pagination/Pagination";

const News = ({ t }) => {
  const location = useLocation();
  const [otherNews, setOtherNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pages, setPages] = useState({
    lastPage: "",
    currentPage: "",
  });
  const fetchOtherNews = async () => {
    setIsLoading(true);
    await fetch(`${baseUrl}/GetNews/4${location.search}`)
      .then((res) => res.json())
      .then(
        (news) => {
          setOtherNews(news.Result.data);
          setPages({
            lastPage: news.Result.last_page,
            currentPage: news.Result.current_page,
          });
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          console.log("something went wrong fetching fetchOtherNews =>", error);
        }
      );
  };

  useEffect(() => {
    document.title = t("News");
    fetchOtherNews();
  }, [location.search]);
  const navigate = useNavigate();
  const paginate = (pageNumber) => {
    navigate(`/news?page=${pageNumber}`);
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <MainLayout addPadding={false}>
          <Container>
            <div className={classes.newsTitleHolder}>
              <h1 className="text-center">{t("News")}</h1>
            </div>
            <Row>
              {otherNews.map((item, index) => {
                return (
                  <Col lg={4} md={6} className={classes.newsCol} key={item.id}>
                    <NewsCardComponent
                      id={item.id}
                      thumb={storageUrl + item.thumb}
                      title={isArabic ? item.title_ar : item.title}
                      date={getTodaysDate()}
                      bg={true}
                    />
                  </Col>
                );
              })}
            </Row>
            <PaginationItems
              lastPage={pages.lastPage}
              currentPage={pages.currentPage}
              paginate={paginate}
            />
          </Container>
        </MainLayout>
      )}
    </>
  );
};

export default withTranslation()(News);
