import React from "react";
import classes from "./LeftSide.module.scss";
import pepsiLogo from "../../assets/images/forms-media/pepsi-sponsor-logo.png";
import { getTodaysDate, storageUrl } from "../../helpers/helpersFunctions";
import logoText from "../../assets/images/forms-media/form-header-text.svg";
import formLogo from "../../assets/images/forms-media/form-logo.svg";
import Flex from "@react-css/flex";
import { withTranslation } from "react-i18next";

const LeftSide = ({bodyTitle, bodyDesc, removeHeightWidth=false,image, t}) => {

  const mobileStyle={
    height:"auto",
    width:"auto"
  }

  return (
    <>
      {" "}
      <Flex column className={classes.formLeftSideHolder}
      justifySpaceBetween
      style={removeHeightWidth ? mobileStyle : {}}>
        <div className={classes.formBodyHolder}>
          <div className={classes.bodyTitleHolder}>
            <h1>{bodyTitle}</h1>
          </div>
          <div className={classes.bodyDescHolder}>
            <p>
              {bodyDesc}
            </p>
          </div>
        </div>
        <div className={classes.formFooterHolder}>
          <div
          className={classes.footerTextHolder}
          style={removeHeightWidth ? mobileStyle : {}}>
             <img src={storageUrl +  image} className="img-fluid" alt="form Image" />
            </div>
        </div>
      </Flex>
    </>
  );
};

export default withTranslation()(LeftSide);
