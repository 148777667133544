import React from "react";
import classes from "./ImpactBox.module.scss";
import Flex from "@react-css/flex";
import { withTranslation } from "react-i18next";


const ImpactBox = ({icon, number, title, desc, t }) => {
  return (
    <>
      <Flex column className={classes.impactBoxHolder} alignItemsCenter>
        <div className={classes.impactBoxiconHolder}>
          <img src={icon} alt="impact icon" />
        </div>
        <div className={classes.impactBoxNumberHolder}>
          <p>{number}</p>
        </div>
        <div className={classes.impactBoxTitleHolder}>
          <h3>{title}</h3>
        </div>
        <div className={classes.impactBoxDescHolder}>
          <small>{desc}</small>
        </div>
      </Flex>
    </>
  );
};

export default withTranslation()(ImpactBox);
