import React from 'react';
import { Alert } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { isArabic } from '../../helpers/helpersFunctions';

const FormAlert = ({passed, t}) => {

    const getSuccessText = () => {
        return !isArabic ? "Form has been successfully submitted" : "تم تقديم طلبك بنجاح"
    }

    const getFailureText = () => {
        return !isArabic ? "Failed to submit your form, please check your data!" :
        "لم يتم تقديم طلبك بنجاح, الرجاء التأكد من صحة البيانات";
    }

  return (
    <>
    <Alert className="text-center text-black" variant={passed ? 'sucess' : 'error' }>
        {passed ? getSuccessText() : getFailureText() }
    </Alert>
    </>
  )
}

export default withTranslation()(FormAlert);