export default class EligibilityServices {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    /**
     *
     * @param {*} setLeftSideData
     * @returns
     */
    async getEligibilityData(setData) {
        await fetch(`${this.baseUrl}/GetTexts/eligibility`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setData(data.Result);
                },
                (error) => {
                    console.log("something went wrong fetching getEligibilityData =>", error);
                }
            );

        return;
    }


    /**
     *
     * @param {*} setData
     * @returns
     */
    async getFileUrl(setFile) {
        await fetch(`${this.baseUrl}/GetFiles/eligibility`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setFile(data.Result);
                },
                (error) => {
                    console.log("something went wrong fetching getFileUrl =>", error);
                }
            );

        return;
    }





    async getEligibilityIcons(setData) {
        await fetch(`${this.baseUrl}/GetImages/eligiable_orphans`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setData(data.Result);
                },
                (error) => {
                    console.log("something went wrong fetching getEligibilityIcons =>", error);
                }
            );

        return;
    }


    async getAdmissionRequirements(setData) {
        await fetch(`${this.baseUrl}/GetAdmissionRequirements`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setData(data.Result);
                },
                (error) => {
                    console.log("something went wrong fetching getAdmissionRequirements =>", error);
                }
            );

        return;
    }


    /**
     *
     * @param {*} setIsLoading
     * @param {*} setLeftSideData
     */

    async getAllData(setIsLoading, setData, setIcons, setAdmission, setFile) {
        try {
            const response = await Promise.allSettled([
                this.getEligibilityData(setData),
                this.getEligibilityIcons(setIcons),
                this.getAdmissionRequirements(setAdmission),
                this.getFileUrl(setFile)
            ]);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            throw err;
        }
    }
}