import React from 'react';
import { withTranslation } from 'react-i18next';
import classes from "./OrphanStats.module.scss";
import Flex from '@react-css/flex';

const OrphanStats = ({number, title, desc, deviceType=null, t}) => {
  return (

    <>
    <Flex column className={classes.orphansSattsContianer}>
            <div className={classes.orphansNumberHolder}>
                <p>{number}</p>
            </div>
            <div className={classes.orphansTitleHolder}>
                <p style={{fontSize: (deviceType === "tablet") && "16px"}}>{title}</p>
            </div>
            <div className={classes.orphansDescHolder}>
                <p>{desc}</p>
            </div>
    </Flex>
    </>
  )
}

export default withTranslation()(OrphanStats);