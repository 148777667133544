import React from 'react';
import { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import {isArabic, baseUrl}  from '../../helpers/helpersFunctions';
import "../form-input/FormInput.module.scss";

const CitiesSelectList = ({label, id,required=false, formRef, t}) => {
    const [citiesList, setCitiesList] = useState([]);

    useEffect(() => {
        fetch( `${baseUrl}/GetCities`)
          .then(res => res.json())
          .then(
            (data) => {
              setCitiesList(data.Result);
            },
            (error) => {
              console.log("error", error);
            }
          )
      }, [])
    return (
    <>
  <div className="input-form-holder mb-3">
  <label htmlFor={id} className="form-label">{label}{required && t(" (required)")}</label>
  <select className="form-select" aria-label="City" id={id} style={{height:"60px", borderRadius:"16px"}}>
  <option defaultValue={t("Select")}>{t("Select")}</option>
    {
        citiesList.map((city, index) => {
            return <option
             key={city.id}
             value={city.id}
             ref={formRef}
             >{isArabic ? city.name_ar : city.name}</option>
        })
    }
</select>
</div>
    </>
  )
}

export default withTranslation()(CitiesSelectList);