import Flex from "@react-css/flex";
import React from "react";
import { Container } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import classes from "./NewsDetails.module.scss";
import queenCover from "../../assets/images/news-media/news-cover.png";
import { useState } from "react";
import { Desktop, Mobile, Tablet } from "../../helpers/BreakPoints";
import { isArabic, storageUrl, baseUrl } from "../../helpers/helpersFunctions";
import Loader from "../../components/loader/Loader";

const NewsDetails = ({ t }) => {
  const [currentNews, setCurrentNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let { newsId } = useParams();

  const fetchCurrentNews = async () => {
    await fetch(`${baseUrl}/GetNewsDetails/${newsId}`)
      .then((res) => res.json())
      .then(
        (data) => {
          setCurrentNews(data.Result);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          console.log(
            "something went wrong fetching fetchCurrentNews =>",
            error
          );
        }
      );

    return;
  };

  useState(() => {
    document.title = t("News");
    fetchCurrentNews();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* Desktop */}
          <Desktop>
            <MainLayout addPadding={false}>
              <Container>
                <Flex
                  column
                  className={classes.newsDetailsHolder}
                  alignItemsCenter
                >
                  <div className={classes.coverHolder}>
                    <img
                      className="img-fluid"
                      src={
                        currentNews
                          ? storageUrl + currentNews.image
                          : queenCover
                      }
                      alt="News Cover"
                    />
                  </div>

                  <div className={classes.newsDetailsTitle}>
                    <h1>
                      {isArabic ? currentNews.title_ar : currentNews.title}
                    </h1>
                  </div>
                  <div className={classes.newsDetailsDesc}>
                    <pre
                      dangerouslySetInnerHTML={{
                        __html: isArabic
                          ? currentNews.text_ar
                          : currentNews.text,
                      }}
                    ></pre>
                  </div>
                </Flex>
              </Container>
            </MainLayout>
          </Desktop>

          {/* Tablet */}
          <Tablet>
            <MainLayout addPadding={false}>
              <Container>
                <Flex
                  column
                  className={classes.newsDetailsHolder}
                  alignItemsCenter
                >
                  <div className={classes.coverHolder}>
                    <img
                      className="img-fluid"
                      src={
                        currentNews
                          ? storageUrl + currentNews.image
                          : queenCover
                      }
                      alt="News Cover"
                    />
                  </div>

                  <div
                    className={classes.newsDetailsTitle}
                    style={{ width: "auto" }}
                  >
                    <h1
                      style={{
                        fontSize: "32px",
                        lineHeight: "34px",
                        textAlign: "center",
                      }}
                    >
                      {isArabic ? currentNews.title_ar : currentNews.title}
                    </h1>
                  </div>
                  <div className={classes.newsDetailsDesc}>
                    <pre
                      dangerouslySetInnerHTML={{
                        __html: isArabic
                          ? currentNews.text_ar
                          : currentNews.text,
                      }}
                    ></pre>
                  </div>
                </Flex>
              </Container>
            </MainLayout>
          </Tablet>

          {/* Mobile */}
          <Mobile>
            <MainLayout addPadding={false}>
              <Container>
                <Flex
                  column
                  className={classes.newsDetailsHolder}
                  alignItemsCenter
                >
                  <div className={classes.coverHolder}>
                    <img
                      className="img-fluid"
                      src={
                        currentNews
                          ? storageUrl + currentNews.image
                          : queenCover
                      }
                      alt="News Cover"
                    />
                  </div>

                  <div
                    className={classes.newsDetailsTitle}
                    style={{ width: "auto" }}
                  >
                    <h1
                      style={{
                        fontSize: "26px",
                        lineHeight: "34px",
                        textAlign: "center",
                      }}
                    >
                      {isArabic ? currentNews.title_ar : currentNews.title}
                    </h1>
                  </div>
                  <div className={classes.newsDetailsDesc}>
                    <pre
                      dangerouslySetInnerHTML={{
                        __html: isArabic
                          ? currentNews.text_ar
                          : currentNews.text,
                      }}
                    ></pre>
                  </div>
                </Flex>
              </Container>
            </MainLayout>
          </Mobile>
        </>
      )}
    </>
  );
};

export default withTranslation()(NewsDetails);
