import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import newFooterLogo from "../../assets/images/footer-icon/new-footer-logo.svg";
import logoText from "../../assets/images/footer-icon/logo-text.svg";
import Flex from "@react-css/flex";
import { Row, Col, Container } from "react-bootstrap";
import classes from "./Footer.module.scss";
import { Link } from "react-router-dom";
import { Desktop, Mobile, Tablet } from "../../helpers/BreakPoints";
import facebookIcon from "../../assets/images/footer-icon/facebook-icon.svg";
import twitterIcon from "../../assets/images/footer-icon/twitter-icon.svg";
import instagramIcon from "../../assets/images/footer-icon/instagram-icon.svg";
import youtubeIcon from "../../assets/images/footer-icon/youtube-icon.svg";
import linkedinIcon from "../../assets/images/footer-icon/linkedin-icon.svg";
import { baseUrl, isArabic } from "../../helpers/helpersFunctions";

const Footer = ({ t }) => {

  const [footerData, setFooterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const now = new Date();

  useEffect(() => {
    fetch(`${baseUrl}/GetTexts/footer`)
    .then((res) => res.json())
    .then(
    (data) => {
        setFooterData(data.Result);
        setIsLoading(false);
    },
    (error) => {
      setIsLoading(false);
      console.log("Something went wrong fetching Footer Data!", error);
    }
    )
  },[]);


  return (
    <>
    <Desktop>
    <footer className={classes.footerHolder}>
      <Flex style={{paddingBottom:"81px"}}>
        <Container>
          <Row>
            <Col lg={4} md={6}>
              <Flex column className={classes.LeftSideContentHolder} justifySpaceBetween style={{height:"100%"}}>
                <div className={classes.logoHolder} style={{height:"25px"}}>
                  <img src={newFooterLogo} alt="Al aman Footer" />
                  <img src={logoText} alt="Al aman Footer" />
                </div>
                <div className={classes.leftSideTitleHolder}>
                  <h1>{isArabic ? footerData[0]?.value_ar : footerData[0]?.value}</h1>
                </div>
                <div className={classes.leftSideDescHolder}>
                  <p>
                  {isArabic ? footerData[1]?.value_ar : footerData[1]?.value}
                  </p>
                </div>
                <div className={classes.leftsideBtnHoler}>
                  <Link className={classes.yellowBtnDonate} to="/donate">
                    {t("Donate")}
                  </Link>
                </div>
              </Flex>
            </Col>
            <Col lg={4} md={6}>
              <Flex column className={classes.MiddleContentHolder}>
                <div className={classes.MidTitleHolder}>
                  <h3>{isArabic ? footerData[2]?.value_ar : footerData[2]?.value}</h3>
                </div>
                <Flex
                  column
                  className={classes.workingHoursHolder}
                  height={"54px"}
                >
                  <div className={classes.workingHoursTitleHolder}>
                    <h3>{isArabic ? footerData[3]?.value_ar : footerData[3]?.value}</h3>
                  </div>
                  <div className={classes.workingHoursTitleHolder}>
                    <p>{isArabic ? footerData[4]?.value_ar : footerData[4]?.value}</p>
                  </div>
                </Flex>
                <div className={classes.addressContactHolder}>
                  <div className={classes.addressTitleHolder}>
                    <h3>{isArabic ? footerData[5]?.value_ar : footerData[5]?.value}</h3>
                  </div>
                  <div className={classes.addressHolder}>
                    <pre>
                    {isArabic ? footerData[6]?.value_ar : footerData[6]?.value}
                    </pre>
                      {/* <p>{t("P.O. Box 930297 Amman, Jordan 11193")}</p>
                      <p>{t("Email: info@alamanfund.jo")}</p>
                      <p>{t("Tel: +962-6-5664427")}</p>
                      <p>{t("Fax: +962-6-5664471")}</p> */}
                  </div>
                </div>
                <Flex row style={{width:"190px"}} justifySpaceBetween alignItemsBaseline>
                      <div className={classes.socialMediaIconHolder}>
                        <a href="https://www.facebook.com/AlAmanFund">
                          <img src={facebookIcon} alt="Facebook" />
                          </a>
                      </div>
                      <div className={classes.socialMediaIconHolder}>
                          <a href="https://www.instagram.com/alamanfund/">
                          <img src={instagramIcon} alt="Instagram" />
                          </a>
                      </div>
                      <div className={classes.socialMediaIconHolder}>
                        <a href="https://twitter.com/AlAmanFundJo">
                          <img src={twitterIcon} alt="Twitter" />
                        </a>
                      </div>
                      <div className={classes.socialMediaIconHolder}>
                        <a href="https://www.linkedin.com/company/al-aman-fund-for-the-future-of-orphans/">
                          <img src={linkedinIcon} alt="Linkedin" />
                        </a>
                      </div>
                      <div className={classes.socialMediaIconHolder}>
                        <a href="https://www.youtube.com/channel/UCtbaPmL65hYXRSNoKSrPgsQ">
                          <img src={youtubeIcon} alt="Youtube" />
                          </a>
                      </div>
                </Flex>
              </Flex>
            </Col>
            <Col lg={4} md={6}>
              <div className={classes.RightSideTitleHolder}>
                  <h3>
                  {isArabic ? footerData[7]?.value_ar : footerData[7]?.value}
                  </h3>
              </div>
              <div className={classes.paragraphHolder}>
                <pre>
                {isArabic ? footerData[8]?.value_ar : footerData[8]?.value}
                </pre>
                  {/* <p>
                    {t("All donations go directly towards the different support schemes the Fund provides to its orphans, the administrative costs are directly covered from separate donations for this purpose.")}
                  </p>
                  <p>{t("Al-Aman Fund maintains a standard level of best practice by providing the donor with the orphan educational achievements and grades every semester also by updating the donors with the orphans’ situations.")}</p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </Flex>
      <div className="copy-right-holder" style={{paddingBottom:"36px"}}>
          <p className={classes.copyRightTxt}>
          {isArabic ? ` جميع الحقوق محفوظة لمؤسسة الأمان ${now.getFullYear()}.` :t(`Copyright Al-Aman Fund ${now.getFullYear()} All Rights Reserved.`)}
          </p>
      </div>
    </footer>
    </Desktop>

    {/* Tablet */}
    <Tablet>
    <footer className={classes.footerHolder}>
      <Flex style={{paddingBottom:"81px"}}>
        <Container>
          <Row>
            <Col lg={4} md={4}>
              <Flex column justifySpaceBetween className={classes.LeftSideContentHolder} style={{height:"100%"}}>
                <div className={classes.logoHolder} style={{width:"94%"}}>
                  <img src={newFooterLogo} alt="Al aman Footer" style={{height:"40px"}} />
                  <img src={logoText} alt="Al aman Footer" style={{height:"25px"}} />
                </div>
                <div className={classes.leftSideTitleHolder} style={{width:"auto", marginBottom:"40px" }}>
                  <h1 style={{fontSize:"16px", lineHeight:"unset"}}>{isArabic ? footerData[0]?.value_ar : footerData[0]?.value}</h1>
                </div>
                <div className={classes.leftSideDescHolder} style={{width:"auto"}}>
                  <p style={{fontSize:"14px"}}>
                  {isArabic ? footerData[1]?.value_ar : footerData[1]?.value}
                  </p>
                </div>
                <div className={classes.leftsideBtnHolder}>
                  <Link className={classes.yellowBtnDonate} to="/donate">
                    {t("Donate")}
                  </Link>
                </div>
              </Flex>
            </Col>
            <Col lg={4} md={4}>
              <Flex column className={classes.MiddleContentHolder}>
                <div className={classes.MidTitleHolder}>
                  <h3>{isArabic ? footerData[2]?.value_ar : footerData[2]?.value}</h3>
                </div>
                <Flex
                  column
                  className={classes.workingHoursHolder}
                  height={"54px"}
                >
                  <div className={classes.workingHoursTitleHolder}>
                    <h3>{isArabic ? footerData[3]?.value_ar : footerData[3]?.value}</h3>
                  </div>
                  <div className={classes.workingHoursTitleHolder}>
                    <p>{isArabic ? footerData[4]?.value_ar : footerData[4]?.value}</p>
                  </div>
                </Flex>
                <div className={classes.addressContactHolder}>
                  <div className={classes.addressTitleHolder}>
                    <h3>{isArabic ? footerData[5]?.value_ar : footerData[5]?.value}</h3>
                  </div>
                  <div className={classes.addressHolder}>
                    <pre>
                    {isArabic ? footerData[6]?.value_ar : footerData[6]?.value}
                    </pre>
                      {/* <p style={{fontSize:"14px"}}>{t("P.O. Box 930297 Amman, Jordan 11193")}</p>
                      <p style={{fontSize:"14px"}}>{t("Email: info@alamanfund.jo")}</p>
                      <p style={{fontSize:"14px"}}>{t("Tel: +962-6-5664427")}</p>
                      <p style={{fontSize:"14px"}}>{t("Fax: +962-6-5664471")}</p> */}
                  </div>
                </div>
                <Flex row style={{width:"190px"}} justifySpaceBetween alignItemsBaseline>
                      <div className={classes.socialMediaIconHolder}>
                        <a href="https://www.facebook.com/AlAmanFund">
                          <img src={facebookIcon} alt="Facebook" />
                          </a>
                      </div>
                      <div className={classes.socialMediaIconHolder}>
                          <a href="https://www.instagram.com/alamanfund/">
                          <img src={instagramIcon} alt="Instagram" />
                          </a>
                      </div>
                      <div className={classes.socialMediaIconHolder}>
                        <a href="https://twitter.com/AlAmanFundJo">
                          <img src={twitterIcon} alt="Twitter" />
                        </a>
                      </div>
                      <div className={classes.socialMediaIconHolder}>
                        <a href="https://www.linkedin.com/company/al-aman-fund-for-the-future-of-orphans/">
                          <img src={linkedinIcon} alt="Linkedin" />
                        </a>
                      </div>
                      <div className={classes.socialMediaIconHolder}>
                        <a href="https://www.youtube.com/channel/UCtbaPmL65hYXRSNoKSrPgsQ">
                          <img src={youtubeIcon} alt="Youtube" />
                          </a>
                      </div>
                </Flex>
              </Flex>
            </Col>
            <Col lg={4} md={4}>
              <Flex column  >
              <div className={classes.RightSideTitleHolder}>
                  <h3>
                  {isArabic ? footerData[7]?.value_ar : footerData[7]?.value}
                  </h3>
              </div>
              <div className={classes.paragraphHolder}>
                <pre style={{fontsize:"14px"}}>
                {isArabic ? footerData[8]?.value_ar : footerData[8]?.value}
                </pre>
                  {/* <p style={{fontSize:"14px"}}>
                    {t("All donations go directly towards the different support schemes the Fund provides to its orphans, the administrative costs are directly covered from separate donations for this purpose.")}
                  </p>
                  <p style={{fontSize:"14px"}}>{t("Al-Aman Fund maintains a standard level of best practice by providing the donor with the orphan educational achievements and grades every semester also by updating the donors with the orphans’ situations.")}</p> */}
              </div>
              </Flex>
            </Col>
          </Row>
        </Container>
      </Flex>
      <div className="copy-right-holder" style={{paddingBottom:"36px"}}>
      <p className={classes.copyRightTxt}>
          {isArabic ? ` جميع الحقوق محفوظة لمؤسسة الأمان ${now.getFullYear()}.` :t(`Copyright Al-Aman Fund ${now.getFullYear()} All Rights Reserved.`)}
          </p>
      </div>
    </footer>
    </Tablet>

    {/* Mobile */}
    <Mobile>
    <footer className={classes.footerHolder}>
      <Flex style={{paddingBottom:"25px", textAlign:"center"}} alignItemsCenter >
        <Container>
          <Row>
            <Col lg={4} md={6}>
              <Flex column className={classes.LeftSideContentHolder}
              style={{marginBottom:"20px", textAlign:"center"}}
              alignItemsCenter>
                <div className={classes.logoHolder} style={{marginBottom:"20px"}}>
                <img src={newFooterLogo} alt="Al aman Footer" />
                  <img src={logoText} alt="Al aman Footer" />
                </div>
                <div className={classes.leftSideTitleHolder} style={{marginBottom:"19px"}}>
                  <h1>{isArabic ? footerData[0]?.value_ar : footerData[0]?.value}</h1>
                </div>
                <div className={classes.leftSideDescHolder} style={{marginBottom:"16px"}}>
                  <p>
                  {isArabic ? footerData[1]?.value_ar : footerData[1]?.value}
                  </p>
                </div>
                <div className={classes.leftsideBtnHolder}>
                  <Link className={classes.yellowBtnDonate} to="/donate">
                    {t("Donate")}
                  </Link>
                </div>
              </Flex>
            </Col>
            <Col lg={4} md={6}>
              <Flex column className={classes.MiddleContentHolder}
              style={{marginBottom:"45px", textAlign:"center"}}
              alignItemsCenter
              >
                <div className={classes.MidTitleHolder}>
                  <h3>{isArabic ? footerData[2]?.value_ar : footerData[2]?.value}</h3>
                </div>
                <Flex
                  column
                  className={classes.workingHoursHolder}
                  height={"54px"}
                >
                  <div className={classes.workingHoursTitleHolder}>
                    <h3>{isArabic ? footerData[3]?.value_ar : footerData[3]?.value}</h3>
                  </div>
                  <div className={classes.workingHoursTitleHolder}>
                    <p>{isArabic ? footerData[4]?.value_ar : footerData[4]?.value}</p>
                  </div>
                </Flex>
                <div className={classes.addressContactHolder}>
                  <div className={classes.addressTitleHolder}>
                    <h3>{isArabic ? footerData[5]?.value_ar : footerData[5]?.value}</h3>
                  </div>
                  <div className={classes.addressHolder}>
                    <pre>
                    {isArabic ? footerData[6]?.value_ar : footerData[6]?.value}
                    </pre>
                      {/* <p>{t("P.O. Box 930297 Amman, Jordan 11193")}</p>
                      <p>{t("Email: info@alamanfund.jo")}</p>
                      <p>{t("Tel: +962-6-5664427")}</p>
                      <p>{t("Fax: +962-6-5664471")}</p> */}
                  </div>
                </div>
                <Flex row style={{width:"190px"}} justifySpaceBetween alignItemsBaseline>
                      <div className={classes.socialMediaIconHolder}>
                        <a href="https://www.facebook.com/AlAmanFund">
                          <img src={facebookIcon} alt="Facebook" />
                          </a>
                      </div>
                      <div className={classes.socialMediaIconHolder}>
                          <a href="https://www.instagram.com/alamanfund/">
                          <img src={instagramIcon} alt="Instagram" />
                          </a>
                      </div>
                      <div className={classes.socialMediaIconHolder}>
                        <a href="https://twitter.com/AlAmanFundJo">
                          <img src={twitterIcon} alt="Twitter" />
                        </a>
                      </div>
                      <div className={classes.socialMediaIconHolder}>
                        <a href="https://www.linkedin.com/company/al-aman-fund-for-the-future-of-orphans/">
                          <img src={linkedinIcon} alt="Linkedin" />
                        </a>
                      </div>
                      <div className={classes.socialMediaIconHolder}>
                        <a href="https://www.youtube.com/channel/UCtbaPmL65hYXRSNoKSrPgsQ">
                          <img src={youtubeIcon} alt="Youtube" />
                          </a>
                      </div>
                </Flex>
              </Flex>
            </Col>
            <Col lg={4} md={6}>
              <Flex column
              alignItemsCenter
              style={{textAlign:"center", marginBottom:"45px"}}>
              <div className={classes.RightSideTitleHolder}>
                  <h3>
                  {isArabic ? footerData[7]?.value_ar : footerData[7]?.value}
                  </h3>
              </div>
              <div className={classes.paragraphHolder}>
                <pre>
                {isArabic ? footerData[8]?.value_ar : footerData[8]?.value}
                </pre>
                  {/* <p>
                    {t("All donations go directly towards the different support schemes the Fund provides to its orphans, the administrative costs are directly covered from separate donations for this purpose.")}
                  </p>
                  <p>{t("Al-Aman Fund maintains a standard level of best practice by providing the donor with the orphan educational achievements and grades every semester also by updating the donors with the orphans’ situations.")}</p> */}
              </div>
              </Flex>
            </Col>
          </Row>
        </Container>
      </Flex>
      <div className="copy-right-holder" style={{paddingBottom:"36px"}}>
      <p className={classes.copyRightTxt}>
      {isArabic ? ` جميع الحقوق محفوظة لمؤسسة الأمان ${now.getFullYear()}.` :t(`Copyright Al-Aman Fund ${now.getFullYear()} All Rights Reserved.`)}
        </p>
      </div>
    </footer>
    </Mobile>
    </>
  );
};

export default withTranslation()(Footer);
