import React from "react";
import classes from "./FormHeader.module.scss";
import Flex from "@react-css/flex";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const FormHeader = ({mainTitle, subTitle, t}) => {
  return (
    <>
      <Flex
        row
        className={classes.formFieldsHeaderHolder}
        alignItemsStart
        justifySpaceBetween
      >
        <div className={classes.titleSubTitleHolder}>
          <p>{mainTitle}</p>
          <p>{subTitle}</p>
        </div>
      </Flex>
    </>
  );
};

export default withTranslation()(FormHeader);
