import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import Navbar from "react-bootstrap/Navbar";
import MenuItems from "./MenuItems";
import { Container, Nav } from "react-bootstrap";
import { Desktop, Tablet, Mobile } from "../../helpers/BreakPoints";
import logo from "../../assets/images/shared/logo.svg";
import classes from "./Header.module.scss";
import { Link } from "react-router-dom";
import burgerMenu from "../../assets/images/shared/burger-menu.svg";
import i18n from "../../i18n";
import { baseUrl, isArabic } from "../../helpers/helpersFunctions";

const Header = ({ t }) => {
  const [headerData, setHeaderData] = useState([]);

  const fetchHeaderData = async () => {
    await fetch(`${baseUrl}/GetTexts/navbar`)
      .then((res) => res.json())
      .then(
        (data) => {
          setHeaderData(data.Result);
        },
        (error) => {
          console.log("Something went wrong in fetchHeaderData => ", error);
        }
      );
  };

  useEffect(() => {
    fetchHeaderData();
  }, []);

  /**
   *
   * @param {*} lng
   */
  const changeLanguage = (lng) => {
    window.location.reload();
    i18n.changeLanguage(lng);
    localStorage.setItem("userLanguage", lng);
    document.documentElement.dir = lng === "ar" ? "rtl" : "ltr";
    document.documentElement.lang = lng;
  };

  return (
    <>
      <Desktop>
        <Navbar collapseOnSelect expand="lg" bg="white" variant="white">
          <MenuItems headerData={headerData} />
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav"></Navbar.Collapse>
        </Navbar>
      </Desktop>

      {/* Tablet */}
      <Tablet>
        <Navbar bg="white" expand="lg">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Navbar.Brand style={{ height: "100px" }} href="/">
              <img className="p-2" src={logo} alt="AL-AMAN" />
            </Navbar.Brand>
            <li
              className={`${classes.donateBtn}`}
              style={{
                marginRight: isArabic && "auto",
                marginLeft: !isArabic && "auto",
              }}
            >
              <Nav.Link href="/donate"> {t("Donate")}</Nav.Link>
            </li>
            <Navbar.Toggle className="border-0">
              <img src={burgerMenu} alt="logo" />
            </Navbar.Toggle>
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className={`${
                  isArabic
                    ? classes.mainNavLinkHolderAr
                    : classes.mainNavLinkHolder
                } text-center`}
              >
                <Nav.Link href="/our-programs">
                  {isArabic ? headerData[0]?.value_ar : headerData[0]?.value}
                </Nav.Link>
                <Nav.Link href="/eligibility">
                  {isArabic ? headerData[1]?.value_ar : headerData[1]?.value}
                </Nav.Link>
                <Nav.Link href="/our-youth">
                  {isArabic ? headerData[2]?.value_ar : headerData[2]?.value}
                </Nav.Link>
                <Nav.Link href="/about">
                  {isArabic ? headerData[3]?.value_ar : headerData[3]?.value}
                </Nav.Link>
                <Nav.Link href="/contact">
                  {isArabic ? headerData[4]?.value_ar : headerData[4]?.value}
                </Nav.Link>
                <Nav.Link href="/our-impact">
                  {isArabic ? headerData[5]?.value_ar : headerData[5]?.value}
                </Nav.Link>
                <Nav.Link href="/volunteer">
                  {isArabic ? headerData[7]?.value_ar : headerData[7]?.value}
                </Nav.Link>

                <Nav.Link href="/employ-an-orphan">
                  {isArabic ? headerData[8]?.value_ar : headerData[8]?.value}
                </Nav.Link>
                <Nav.Link href="/work-with-us">
                  {isArabic ? headerData[13]?.value_ar : headerData[13]?.value}
                </Nav.Link>

                <Nav.Link
                  style={{ fontWeight: "bolder" }}
                  href={window.location.pathname}
                  onClick={() => {
                    i18n.resolvedLanguage === "ar"
                      ? changeLanguage("en")
                      : changeLanguage("ar");
                  }}
                >
                  {i18n.resolvedLanguage === "ar" ? "English" : "العربية"}
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Tablet>

      {/* Mobile */}
      <Mobile>
        <Navbar bg="white" expand="lg">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Navbar.Brand href="/">
              <img className="p-1" src={logo} alt="AL-AMAN" />
            </Navbar.Brand>
            <li
              className={classes.donateBtnMobile}
              style={{
                marginLeft: isArabic && "unset",
                marginRight: isArabic && "auto",
              }}
            >
              <Nav.Link href="/donate"> {t("Donate")}</Nav.Link>
            </li>
            <Navbar.Toggle className="border-0">
              <img src={burgerMenu} alt="logo" />
            </Navbar.Toggle>
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className={`${
                  isArabic
                    ? classes.mainNavLinkHolderAr
                    : classes.mainNavLinkHolder
                } text-center`}
              >
                <Nav.Link href="/our-programs">
                  {isArabic ? headerData[0]?.value_ar : headerData[0]?.value}
                </Nav.Link>
                <Nav.Link href="/eligibility">
                  {isArabic ? headerData[1]?.value_ar : headerData[1]?.value}
                </Nav.Link>
                <Nav.Link href="/our-youth">
                  {isArabic ? headerData[2]?.value_ar : headerData[2]?.value}
                </Nav.Link>
                <Nav.Link href="/about">
                  {isArabic ? headerData[3]?.value_ar : headerData[3]?.value}
                </Nav.Link>
                <Nav.Link href="/contact">
                  {isArabic ? headerData[4]?.value_ar : headerData[4]?.value}
                </Nav.Link>
                <Nav.Link href="/our-impact">
                  {isArabic ? headerData[5]?.value_ar : headerData[5]?.value}
                </Nav.Link>
                <Nav.Link href="/volunteer">
                  {isArabic ? headerData[7]?.value_ar : headerData[7]?.value}
                </Nav.Link>
                <Nav.Link href="/employ-an-orphan">
                  {isArabic ? headerData[8]?.value_ar : headerData[8]?.value}
                </Nav.Link>
                <Nav.Link href="/work-with-us">
                  {isArabic ? headerData[13]?.value_ar : headerData[13]?.value}
                </Nav.Link>

                <Nav.Link
                  style={{ fontWeight: "bolder" }}
                  href={window.location.pathname}
                  onClick={() => {
                    i18n.resolvedLanguage === "ar"
                      ? changeLanguage("en")
                      : changeLanguage("ar");
                  }}
                >
                  {i18n.resolvedLanguage === "ar" ? "English" : "العربية"}
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Mobile>
    </>
  );
};

export default withTranslation()(Header);
