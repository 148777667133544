export default class OurImpactServices {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    /**
     *
     * @param {*} setLeftSideData
     * @returns
     */
    async getData(setData) {
        await fetch(`${this.baseUrl}/GetTexts/our_impact`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setData(data.Result);
                },
                (error) => {
                    console.log("something went wrong fetching getEligibilityData =>", error);
                }
            );

        return;
    }

    async getImages(setData) {
        await fetch(`${this.baseUrl}/GetImages/our_impact`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setData(data.Result);
                },
                (error) => {
                    console.log("something went wrong fetching getEligibilityIcons =>", error);
                }
            );

        return;
    }

    async getCounters(setData) {
        await fetch(`${this.baseUrl}/GetCounters/our_impact`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setData(data.Result);
                },
                (error) => {
                    console.log("something went wrong fetching getAdmissionRequirements =>", error);
                }
            );

        return;
    }

    async getSupportList(setDirect, setIndirect) {
        const directList = [];
        const indirectList = [];
        await fetch(`${this.baseUrl}/GetSDGoals`)
            .then((res) => res.json())
            .then(
                (data) => {
                    for (let i = 0; i <= data.Result.length; i++) {
                        if (data.Result[i].is_direct === 0) {
                            indirectList.push(data.Result[i]);
                            setIndirect(indirectList);
                        } else {
                            directList.push(data.Result[i]);
                            setDirect(directList);
                        }
                    }
                },
                (error) => {
                    console.log("something went wrong fetching getAdmissionRequirements =>", error);
                }
            );

        return;
    }


    /**
     *
     * @param {*} setIsLoading
     * @param {*} setLeftSideData
     */

    async getAllData(setIsLoading, setData, setImages, setCounters, setDirect, setIndirect) {
        try {
            const response = await Promise.allSettled([
                this.getData(setData),
                this.getImages(setImages),
                this.getCounters(setCounters),
                this.getSupportList(setDirect, setIndirect)
            ]);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            console.log("Something went wrong in")
            throw err;
        }
    }
}