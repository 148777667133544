export default class SuccessStoryServices {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }


    /**
     *
     * @param {*} setLeftSideData
     * @returns
     */
    async getStories(setData) {
        await fetch(`${this.baseUrl}/GetSuccessStories`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setData(data.Result);
                },
                (error) => {
                    console.log("something went wrong fetching getEligibilityData =>", error);
                }
            );

        return;
    }



    /**
     *
     * @param {*} setLeftSideData
     * @returns
     */
    async getData(setData) {
        await fetch(`${this.baseUrl}/GetTexts/success_stories`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setData(data.Result);
                },
                (error) => {
                    console.log("something went wrong fetching getEligibilityData =>", error);
                }
            );

        return;
    }

    async getImages(setData) {
        await fetch(`${this.baseUrl}/GetImages/success_stories`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setData(data.Result);
                },
                (error) => {
                    console.log("something went wrong fetching getEligibilityIcons =>", error);
                }
            );

        return;
    }

    /**
     *
     * @param {*} setIsLoading
     * @param {*} setLeftSideData
     */

    async getAllData(setIsLoading, setData, setImages, setStories) {
        try {
            const response = await Promise.allSettled([
                this.getData(setData),
                this.getImages(setImages),
                this.getStories(setStories)
            ]);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            console.log("Something went wrong in")
            throw err;
        }
    }
}