import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import classes from "./ImpactCircle.module.scss";
import "react-circular-progressbar/dist/styles.css";
import Flex from "@react-css/flex";
import { withTranslation } from "react-i18next";
import { Desktop, Tablet, Mobile } from "../../helpers/BreakPoints";

const ImpactCircle = ({ percentage, desc, color, t }) => {
  return (
    <>
      {/* Desktop */}
      <Desktop>
        <Flex row className={classes.circleContentHolder} alignItemsCenter>
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            styles={{
              // Customize the path, i.e. the "completed progress"
              root: {
                height: "242px",
                width: "242px",
              },
              path: {
                // Path color
                stroke: color === "yellow" ? "#FFC629" : `#2A7DE1`,
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: "round",
              },
              // Customize the circle behind the path, i.e. the "total progress"
              trail: {
                // Trail color
                stroke: color === "yellow" ? "#F5F0D0" : "#A8CBFF",
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              },
              // Customize the text
              text: {
                // Text color
                fill: color === "yellow" ? "#FFC629" : `#2A7DE1`,
                // Text size
                fontSize: "16px",
                fontFamily: "GiloryBold",
              },
              // Customize background - only used when the `background` prop is true
            }}
          />
          <div className={classes.circleTextHolder}>
            <p>{desc}</p>
          </div>
        </Flex>
      </Desktop>

      {/* Tablet */}
      <Tablet>
        <Flex
          row
          className={classes.circleContentHolder}
          alignItemsCenter
          style={{ width: "auto", padding: "10px" }}
        >
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            styles={{
              // Customize the path, i.e. the "completed progress"
              root: {
                height: "242px",
                width: "242px",
              },
              path: {
                // Path color
                stroke: color === "yellow" ? "#FFC629" : `#2A7DE1`,
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: "round",
              },
              // Customize the circle behind the path, i.e. the "total progress"
              trail: {
                // Trail color
                stroke: color === "yellow" ? "#F5F0D0" : "#A8CBFF",
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              },
              // Customize the text
              text: {
                // Text color
                fill: color === "yellow" ? "#FFC629" : `#2A7DE1`,
                // Text size
                fontSize: "16px",
                fontFamily: "GiloryBold",
              },
              // Customize background - only used when the `background` prop is true
            }}
          />
          <div className={classes.circleTextHolder}>
            <p>{desc}</p>
          </div>
        </Flex>
      </Tablet>

      {/* Mobile */}
      <Mobile>
        <Flex
          columnReverse
          className={classes.circleContentHolder}
          alignItemsCenter
          style={{ width: "auto", padding: "10px", marginBottom: "15px" }}
        >
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            styles={{
              // Customize the path, i.e. the "completed progress"
              root: {
                height: "242px",
                width: "242px",
              },
              path: {
                // Path color
                stroke: color === "yellow" ? "#FFC629" : `#2A7DE1`,
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: "round",
              },
              // Customize the circle behind the path, i.e. the "total progress"
              trail: {
                // Trail color
                stroke: color === "yellow" ? "#F5F0D0" : "#A8CBFF",
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              },
              // Customize the text
              text: {
                // Text color
                fill: color === "yellow" ? "#FFC629" : `#2A7DE1`,
                // Text size
                fontSize: "16px",
                fontFamily: "GiloryBold",
              },
              // Customize background - only used when the `background` prop is true
            }}
          />
          <div className={classes.circleTextHolder}>
            <p className="text-center">{desc}</p>
          </div>
        </Flex>
      </Mobile>
    </>
  );
};

export default withTranslation()(ImpactCircle);
