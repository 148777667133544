import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import MainLayout from "../../layouts/MainLayout";
import { Container, Col, Row } from "react-bootstrap";
import classes from "./OurImpact.module.scss";
import BannerText from "../../components/banner-text/BannerText";
import ImpactCircle from "../../components/our-impact-circle/ImpactCircle";
import ImpactBox from "../../components/our-impact-box/ImpactBox";
import SupportBox from "../../components/impact-support-box/SupportBox";
import OurImpactServices from "./OurImpactServices";
import { baseUrl, storageUrl, isArabic } from "../../helpers/helpersFunctions";
import { Desktop, Tablet, Mobile } from "../../helpers/BreakPoints";
import Loader from "../../components/loader/Loader";

const OurImpact = ({ t }) => {
  const [impactCounters, setImpactCounters] = useState([]);
  const [impactData, setImpactData] = useState([]);
  const [impactImages, setImpactImages] = useState([]);
  const [directData, setDirectData] = useState([]);
  const [indeirectData, setIndirectData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const impServices = new OurImpactServices(baseUrl);

  useEffect(() => {
    document.title = t("Our Impact");
    impServices.getAllData(
      setIsLoading,
      setImpactData,
      setImpactImages,
      setImpactCounters,
      setDirectData,
      setIndirectData,
    );
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* Desktop */}
          <Desktop>
            <MainLayout addPadding={false}>
              {/* Section 1 */}
              <section className={classes.impactSection1}>
                <Container>
                  <div className={classes.section1Header}>
                    <h1>
                      {isArabic
                        ? impactCounters[0].value + impactCounters[0].title_ar
                        : impactCounters[0].value + impactCounters[0].title}
                    </h1>
                    <p>
                      {isArabic
                        ? impactCounters[0].text_ar
                        : impactCounters[0].text}
                    </p>
                  </div>
                  <div className={classes.section1Title}>
                    <h1>
                      {isArabic ? impactData[0].value_ar : impactData[0].value}
                    </h1>
                  </div>
                  <div className={classes.section1Desc}>
                    <small>
                      {isArabic ? impactData[1].value_ar : impactData[1].value}
                    </small>
                  </div>
                </Container>
              </section>

              {/* Section2 */}
              <section
                className={`${classes.impactSection2} d-flex align-items-center w-100 mb-5}`}
                style={{ background: "#2A7DE1", height: "156px" }}
              >
                <Container className={classes.impactMainContainer}>
                  <Row>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={
                          isArabic
                            ? impactCounters[1].value
                            : impactCounters[1].value
                        }
                        title={
                          isArabic
                            ? impactCounters[1].title_ar
                            : impactCounters[1].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[1].text_ar
                            : impactCounters[1].text
                        }
                      />
                    </Col>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={
                          isArabic
                            ? impactCounters[2].value
                            : impactCounters[2].value
                        }
                        title={
                          isArabic
                            ? impactCounters[2].title_ar
                            : impactCounters[2].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[2].text_ar
                            : impactCounters[2].text
                        }
                      />
                    </Col>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={
                          isArabic
                            ? impactCounters[3].value
                            : impactCounters[3].value
                        }
                        title={
                          isArabic
                            ? impactCounters[3].title_ar
                            : impactCounters[3].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[3].text_ar
                            : impactCounters[3].text
                        }
                      />
                    </Col>
                  </Row>
                </Container>
              </section>

              {/* Section 3 */}
              <section className={classes.impactSection3}>
                <Container className={classes.circleContainer}>
                  <Row>
                    <Col lg={6} md={6}>
                      <ImpactCircle
                        percentage={
                          isArabic
                            ? impactCounters[4].value
                            : impactCounters[4].value
                        }
                        desc={
                          isArabic
                            ? impactCounters[4].text_ar
                            : impactCounters[4].text
                        }
                        color={"blue"}
                      />
                    </Col>
                    <Col lg={6} md={6}>
                      <ImpactCircle
                        percentage={
                          isArabic
                            ? impactCounters[5].value
                            : impactCounters[5].value
                        }
                        desc={
                          isArabic
                            ? impactCounters[5].text_ar
                            : impactCounters[5].text
                        }
                        color={"yellow"}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>

              {/* Section 4 */}
              <section className={classes.impactSection3}>
                <Container>
                  <Row>
                    <Col lg={3} md={6}>
                      <ImpactBox
                        icon={storageUrl + impactImages[0].image}
                        number={impactCounters[6].value}
                        title={
                          isArabic
                            ? impactCounters[6].title_ar
                            : impactCounters[6].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[6].text_ar
                            : impactCounters[6].text
                        }
                      />
                    </Col>
                    <Col lg={3} md={6}>
                      <ImpactBox
                        icon={storageUrl + impactImages[1].image}
                        number={impactCounters[7].value}
                        title={
                          isArabic
                            ? impactCounters[7].title_ar
                            : impactCounters[7].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[7].text_ar
                            : impactCounters[7].text
                        }
                      />
                    </Col>
                    <Col lg={3} md={6}>
                      <ImpactBox
                        icon={storageUrl + impactImages[2].image}
                        number={impactCounters[8].value}
                        title={
                          isArabic
                            ? impactCounters[8].title_ar
                            : impactCounters[8].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[8].text_ar
                            : impactCounters[8].text
                        }
                      />
                    </Col>
                    <Col lg={3} md={6}>
                      <ImpactBox
                        icon={storageUrl + impactImages[3].image}
                        number={impactCounters[9].value}
                        title={
                          isArabic
                            ? impactCounters[9].title_ar
                            : impactCounters[9].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[9].text_ar
                            : impactCounters[9].text
                        }
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
              {/* Section 4 Title */}
              <section className={classes.impactSection4}>
                <div className={classes.impactSection4Title}>
                  <p>
                    {isArabic ? impactData[2].value_ar : impactData[2].value}
                  </p>
                </div>
              </section>

              {/* Section 5 */}
              <section className={classes.impactSection5}>
                <Container>
                  <Row>
                    <Col lg={4} md={6}>
                      <img src={storageUrl + impactImages[4].image} alt="UN" />
                    </Col>
                    <Col lg={8} md={6}>
                      <Row className="mb-4">
                      <div className={classes.rowTitleHolder}>
                        <h1>{t("Direct")}</h1>
                      </div>
                        {directData.map((item, index) => {
                          return (
                            <Col key={item.id} lg={4} md={6}>
                              <SupportBox
                                key={item.id + 2}
                                icon={storageUrl + item.image}
                                title={isArabic ? item.title_ar : item.title}
                                desc={isArabic ? item.text_ar : item.text}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                      <Row>
                      <div className={classes.rowTitleHolder}>
                        <h1>{t("Indirect")}</h1>
                      </div>
                        {indeirectData.map((item, index) => {
                          return (
                            <Col key={item.id} lg={4} md={6}>
                              <SupportBox
                                key={item.id + 2}
                                icon={storageUrl + item.image}
                                title={isArabic ? item.title_ar : item.title}
                                desc={isArabic ? item.text_ar : item.text}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </section>
            </MainLayout>
          </Desktop>

          {/* Tablet */}
          <Tablet>
            <MainLayout addPadding={false}>
              {/* Section 1 */}
              <section className={classes.impactSection1}>
                <Container>
                  <div className={classes.section1Header}>
                    <h1>
                      {isArabic
                        ? impactCounters[0].value + impactCounters[0].title_ar
                        : impactCounters[0].value + impactCounters[0].title}
                    </h1>
                    <p>
                      {isArabic
                        ? impactCounters[0].text_ar
                        : impactCounters[0].text}
                    </p>
                  </div>
                  <div className={classes.section1Title}>
                    <h1 style={{ fontSize: "38px" }}>
                      {isArabic ? impactData[0].value_ar : impactData[0].value}
                    </h1>
                  </div>
                  <div className={classes.section1Desc}>
                    <small>
                      {isArabic ? impactData[1].value_ar : impactData[1].value}
                    </small>
                  </div>
                </Container>
              </section>

              {/* Section2 */}
              <section
                className={`${classes.impactSection2} d-flex align-items-center w-100 mb-5}`}
                style={{ background: "#2A7DE1", height: "156px" }}
              >
                <Container className={classes.impactMainContainer}>
                  <Row>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={
                          isArabic
                            ? impactCounters[1].value
                            : impactCounters[1].value
                        }
                        title={
                          isArabic
                            ? impactCounters[1].title_ar
                            : impactCounters[1].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[1].text_ar
                            : impactCounters[1].text
                        }
                        deviceType={"tablet"}
                      />
                    </Col>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={
                          isArabic
                            ? impactCounters[2].value
                            : impactCounters[2].value
                        }
                        title={
                          isArabic
                            ? impactCounters[2].title_ar
                            : impactCounters[2].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[2].text_ar
                            : impactCounters[2].text
                        }
                        deviceType={"tablet"}
                      />
                    </Col>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={
                          isArabic
                            ? impactCounters[3].value
                            : impactCounters[3].value
                        }
                        title={
                          isArabic
                            ? impactCounters[3].title_ar
                            : impactCounters[3].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[3].text_ar
                            : impactCounters[3].text
                        }
                        deviceType={"tablet"}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>

              {/* Section 3 */}
              <section
                className={classes.impactSection3}
                style={{ paddingBottom: "15px" }}
              >
                <Container className={classes.circleContainer}>
                  <Row>
                    <Col lg={6} md={6}>
                      <ImpactCircle
                        percentage={
                          isArabic
                            ? impactCounters[4].value
                            : impactCounters[4].value
                        }
                        desc={
                          isArabic
                            ? impactCounters[4].text_ar
                            : impactCounters[4].text
                        }
                        color={"blue"}
                      />
                    </Col>
                    <Col lg={6} md={6}>
                      <ImpactCircle
                        percentage={
                          isArabic
                            ? impactCounters[5].value
                            : impactCounters[5].value
                        }
                        desc={
                          isArabic
                            ? impactCounters[5].text_ar
                            : impactCounters[5].text
                        }
                        color={"yellow"}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>

              {/* Section 3 */}
              <section className={classes.impactSection3}>
                <Container>
                  <Row>
                    <Col lg={3} md={6} style={{ marginTop: "15px" }}>
                      <ImpactBox
                        icon={storageUrl + impactImages[0].image}
                        number={impactCounters[6].value}
                        title={
                          isArabic
                            ? impactCounters[6].title_ar
                            : impactCounters[6].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[6].text_ar
                            : impactCounters[6].text
                        }
                      />
                    </Col>
                    <Col lg={3} md={6} style={{ marginTop: "15px" }}>
                      <ImpactBox
                        icon={storageUrl + impactImages[1].image}
                        number={impactCounters[7].value}
                        title={
                          isArabic
                            ? impactCounters[7].title_ar
                            : impactCounters[7].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[7].text_ar
                            : impactCounters[7].text
                        }
                      />
                    </Col>
                    <Col lg={3} md={6} style={{ marginTop: "15px" }}>
                      <ImpactBox
                        icon={storageUrl + impactImages[2].image}
                        number={impactCounters[8].value}
                        title={
                          isArabic
                            ? impactCounters[8].title_ar
                            : impactCounters[8].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[8].text_ar
                            : impactCounters[8].text
                        }
                      />
                    </Col>
                    <Col lg={3} md={6} style={{ marginTop: "15px" }}>
                      <ImpactBox
                        icon={storageUrl + impactImages[3].image}
                        number={impactCounters[9].value}
                        title={
                          isArabic
                            ? impactCounters[9].title_ar
                            : impactCounters[9].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[9].text_ar
                            : impactCounters[9].text
                        }
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
              {/* Section 4 Title */}
              <section className={classes.impactSection4}>
                <div className={classes.impactSection4Title}>
                  <p style={{ fontSize: "38px" }}>
                    {isArabic ? impactData[2].value_ar : impactData[2].value}
                  </p>
                </div>
              </section>

              {/* Section 5 */}
              <section className={classes.impactSection5}>
                <Container>
                  <Row>
                    <Col lg={4} md={6}>
                      <img src={storageUrl + impactImages[4].image} alt="UN" />
                    </Col>
                    <Col lg={8} md={6}>
                    <Row className={classes.rowTitleHolder}>
                      <div>
                        <h1>{t("Direct")}</h1>
                      </div>
                        {directData.map((item, index) => {
                          return (
                            <Col key={item.id} lg={6} md={6}>
                              <SupportBox
                                key={item.id + 2}
                                icon={storageUrl + item.image}
                                title={isArabic ? item.title_ar : item.title}
                                desc={isArabic ? item.text_ar : item.text}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                      <Row>
                      <div className={classes.rowTitleHolder}>
                        <h1>{t("Indirect")}</h1>
                      </div>
                        {indeirectData.map((item, index) => {
                          return (
                            <Col key={item.id} lg={6} md={6}>
                              <SupportBox
                                key={item.id + 2}
                                icon={storageUrl + item.image}
                                title={isArabic ? item.title_ar : item.title}
                                desc={isArabic ? item.text_ar : item.text}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </section>
            </MainLayout>
          </Tablet>

          {/* Mobile */}
          <Mobile>
            <MainLayout addPadding={false}>
              {/* Section 1 */}
              <section
                className={classes.impactSection1}
                style={{ backgroundSize: "cover" }}
              >
                <Container>
                  <div className={classes.section1Header}>
                    <h1 style={{ fontSize: "72px" }}>
                      {isArabic
                        ? impactCounters[0].value + impactCounters[0].title_ar
                        : impactCounters[0].value + impactCounters[0].title}
                    </h1>
                    <p style={{ fontSize: "29px" }}>
                      {isArabic
                        ? impactCounters[0].text_ar
                        : impactCounters[0].text}
                    </p>
                  </div>
                  <div className={classes.section1Title}>
                    <h1 style={{ fontSize: "28px", lineHeight: "32px" }}>
                      {isArabic ? impactData[0].value_ar : impactData[0].value}
                    </h1>
                  </div>
                  <div
                    className={classes.section1Desc}
                    style={{ width: "auto" }}
                  >
                    <small>
                      {isArabic ? impactData[1].value_ar : impactData[1].value}
                    </small>
                  </div>
                </Container>
              </section>

              {/* Section2 */}
              <section
                className={`${classes.impactSection2} d-flex align-items-center w-100 mb-5}`}
                style={{ background: "#2A7DE1" }}
              >
                <Container className={classes.impactMainContainer}>
                  <Row>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={
                          isArabic
                            ? impactCounters[1].value
                            : impactCounters[1].value
                        }
                        title={
                          isArabic
                            ? impactCounters[1].title_ar
                            : impactCounters[1].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[1].text_ar
                            : impactCounters[1].text
                        }
                      />
                    </Col>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={
                          isArabic
                            ? impactCounters[2].value
                            : impactCounters[2].value
                        }
                        title={
                          isArabic
                            ? impactCounters[2].title_ar
                            : impactCounters[2].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[2].text_ar
                            : impactCounters[2].text
                        }
                      />
                    </Col>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={
                          isArabic
                            ? impactCounters[3].value
                            : impactCounters[3].value
                        }
                        title={
                          isArabic
                            ? impactCounters[3].title_ar
                            : impactCounters[3].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[3].text_ar
                            : impactCounters[3].text
                        }
                      />
                    </Col>
                  </Row>
                </Container>
              </section>

              {/* Section 3 */}
              <section className={classes.impactSection3}>
                <Container className={classes.circleContainer}>
                  <Row>
                    <Col lg={6} md={6}>
                      <ImpactCircle
                        percentage={
                          isArabic
                            ? impactCounters[4].value
                            : impactCounters[4].value
                        }
                        desc={
                          isArabic
                            ? impactCounters[4].text_ar
                            : impactCounters[4].text
                        }
                        color={"blue"}
                      />
                    </Col>
                    <Col lg={6} md={6}>
                      <ImpactCircle
                        percentage={
                          isArabic
                            ? impactCounters[5].value
                            : impactCounters[5].value
                        }
                        desc={
                          isArabic
                            ? impactCounters[5].text_ar
                            : impactCounters[5].text
                        }
                        color={"yellow"}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>

              {/* Section 3 */}
              <section
                className={classes.impactSection3}
                style={{ paddingBottom: 0 }}
              >
                <Container>
                  <Row>
                    <Col lg={3} md={6} style={{ marginBottom: "15px" }}>
                      <ImpactBox
                        icon={storageUrl + impactImages[0].image}
                        number={impactCounters[6].value}
                        title={
                          isArabic
                            ? impactCounters[6].title_ar
                            : impactCounters[6].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[6].text_ar
                            : impactCounters[6].text
                        }
                      />
                    </Col>
                    <Col lg={3} md={6} style={{ marginBottom: "15px" }}>
                      <ImpactBox
                        icon={storageUrl + impactImages[1].image}
                        number={impactCounters[7].value}
                        title={
                          isArabic
                            ? impactCounters[7].title_ar
                            : impactCounters[7].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[7].text_ar
                            : impactCounters[7].text
                        }
                      />
                    </Col>
                    <Col lg={3} md={6} style={{ marginBottom: "15px" }}>
                      <ImpactBox
                        icon={storageUrl + impactImages[2].image}
                        number={impactCounters[8].value}
                        title={
                          isArabic
                            ? impactCounters[8].title_ar
                            : impactCounters[8].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[8].text_ar
                            : impactCounters[8].text
                        }
                      />
                    </Col>
                    <Col lg={3} md={6} style={{ marginBottom: "15px" }}>
                      <ImpactBox
                        icon={storageUrl + impactImages[3].image}
                        number={impactCounters[9].value}
                        title={
                          isArabic
                            ? impactCounters[9].title_ar
                            : impactCounters[9].title
                        }
                        desc={
                          isArabic
                            ? impactCounters[9].text_ar
                            : impactCounters[9].text
                        }
                      />
                    </Col>
                  </Row>
                </Container>
              </section>
              {/* Section 4 Title */}
              <section
                className={classes.impactSection4}
                style={{ marginBottom: "23px", marginTop: "41px" }}
              >
                <div className={classes.impactSection4Title}>
                  <p style={{ fontSize: "30px" }}>
                    {isArabic ? impactData[2].value_ar : impactData[2].value}
                  </p>
                </div>
              </section>

              {/* Section 5 */}
              <section className={classes.impactSection5}>
                <Container>
                  <Row>
                    <Col lg={4} md={6} style={{ marginBottom: "36px" }}>
                      <img
                        className="img-fluid d-block"
                        style={{ margin: "0 auto" }}
                        src={storageUrl + impactImages[4].image}
                        alt="UN"
                      />
                    </Col>
                    <Col lg={8} md={6}>
                    <Row>
                      <div className={classes.rowTitleHolder}>
                        <h1>{t("Direct")}</h1>
                      </div>
                        {directData.map((item, index) => {
                          return (
                            <Col key={item.id} lg={6} md={6} style={{marginBottom:"25px"}}>
                              <SupportBox
                                key={item.id + 2}
                                icon={storageUrl + item.image}
                                title={isArabic ? item.title_ar : item.title}
                                desc={isArabic ? item.text_ar : item.text}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                      <Row>
                      <div className={classes.rowTitleHolder}>
                        <h1>{t("Indirect")}</h1>
                      </div>
                        {indeirectData.map((item, index) => {
                          return (
                            <Col key={item.id} lg={6} md={6} style={{marginBottom:"25px"}}>
                              <SupportBox
                                key={item.id + 2}
                                icon={storageUrl + item.image}
                                title={isArabic ? item.title_ar : item.title}
                                desc={isArabic ? item.text_ar : item.text}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </section>
            </MainLayout>
          </Mobile>
        </>
      )}
    </>
  );
};

export default withTranslation()(OurImpact);
