import React, {useState, useEffect} from "react";
import MainLayout from "../../layouts/MainLayout";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./StoryDetails.module.scss";
import Flex from "@react-css/flex";
import nouraThumb from "../../assets/images/story-details-media/noura-thumb.png";
import yellowDotBehind from "../../assets/images/index-media/blured-yellow-dot.svg";
import Loader from "../../components/loader/Loader";
import { isArabic, baseUrl, storageUrl } from "../../helpers/helpersFunctions";
import { Desktop, Mobile, Tablet } from "../../helpers/BreakPoints";

const StoryDetails = ({ t }) => {
  let { storyId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [currentStory, setCurrentStory] = useState([]);
  const [title, setTitle] = useState("Story");

  const fetchCurrentStory = async () => {
    await fetch(`${baseUrl}/GetSuccessStories/${storyId}`)
      .then((res) => res.json())
      .then(
        (data) => {
          setCurrentStory(data.Result);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          console.log(
            "something went wrong fetching fetchCurrentStory =>",
            error
          );
        }
      );

    return;
  };



  const storyDetail = `
Despite my tough past, I am an outgoing girl with the passion and the desire to make a difference in the world,

and I believe that I have developed an inner strength that only comes from having to fight to survive.



I would like to share with you my story because I believe that I stand as a true testament to
the fact that orphaned young girls can and must be saved.



I never met my parents. I was left at the doorstep of a mosque when I was 2 days old and Orphan Care Centers were my only refuge.
However, moving from one center to the other made it very difficult for me to call any of them home, or to consider any of the caregivers
there a mother figure to me.  I didn’t allow myself to get emotionally attached to anyone until I was 18, when I met a man that I believed
would rescue me and help me forget my loneliness. He was an orphan as well, living with his siblings; this is where my quest to build a
better life for myself began.



It’s true what they say, a broken heart is hard to fix. Within a year, my marriage began to fall apart because of my inability to truly
trust and love.



A friend of mine who was studying nursing introduced me to Al-Aman fund, which I readily joined. Shortly after I was enrolled at a university.
With the support of the social advisor at the Fund, I was able to open up and talk about my experiences; I also joined a clinic that offers
psychological support. I have come a very long way and now, with one more year of university ahead of me,
I am only one small step away from a whole new beginning.



I am currently working to achieve my dream to help children in distress. I am studying towards receiving a BSc in Special Education
for Autism at one of the leading Universities in Jordan, and I couldn’t be happier.

    `;


  useEffect(() => {
    document.title = title;
    fetchCurrentStory();
    setTitle(isArabic ? currentStory.title_ar : currentStory.title )
  }, []);


  return (
    <>
    {isLoading ? <Loader/> :
      <>
      {/* Desktop */}
      <Desktop>
      <MainLayout addPadding={false}>
        <Container>
          <Row>
            <Col lg={6} md={6}>
              <Flex column
                alignItemsCenter
                className={classes.storyCoverHolder}
                justifyContent={"center"}
                style={{ position: "relative" }}
              >
                <div className={classes.storyThumbHolder}>
                  <img className={classes.yellowBluredStory} src={yellowDotBehind} alt="yellow fot" />
                  <img className={classes.storyAvatar} src={storageUrl + currentStory.image} alt="person avatar" />
                </div>
                <div className={classes.storyTitleHolder}>
                    <p>{isArabic ? currentStory.title_ar : currentStory.title}</p>
                  </div>
              </Flex>
            </Col>
            <Col lg={6} md={6}>
              <Flex className={classes.storyDetailsTextHolder}>
              <pre dangerouslySetInnerHTML={{__html:  isArabic ? currentStory.text_ar : currentStory.text }}></pre>
              </Flex>
            </Col>
          </Row>
        </Container>
      </MainLayout>
      </Desktop>

      {/* Tablet */}
      <Tablet>
      <MainLayout addPadding={false}>
        <Container>
          <Row>
            <Col lg={6} md={6}>
              <Flex column
                alignItemsCenter
                className={classes.storyCoverHolder}
                justifyContent={"center"}
                style={{ position: "relative" }}
              >
                <div className={classes.storyThumbHolder}>
                  <img className={classes.yellowBluredStory} src={yellowDotBehind} alt="yellow fot" />
                  <img className={classes.storyAvatar} src={storageUrl + currentStory.image} alt="person avatar" />
                </div>
                <div className={classes.storyTitleHolder}
                style={{height:"auto", width:"100%", bottom:"60px"}}
                >
                    <p style={{fontSize:"34px", lineHeight:"34px"}}>
                      {isArabic ? currentStory.title_ar : currentStory.title}
                      </p>
                  </div>
              </Flex>
            </Col>
            <Col lg={6} md={6}>
              <Flex className={classes.storyDetailsTextHolder}>
                <pre style={{fontSize:"16px"}} >
                <pre dangerouslySetInnerHTML={{__html:  isArabic ? currentStory.text_ar : currentStory.text }}></pre>
                </pre>
              </Flex>
            </Col>
          </Row>
        </Container>
      </MainLayout>
      </Tablet>

      {/* Mobile */}
      <Mobile>
      <MainLayout addPadding={false}>
        <Container>
          <Row>
            <Col lg={6} md={6}>
              <Flex column
                alignItemsCenter
                className={classes.storyCoverHolder}
                justifyContent={"center"}
                style={{ position: "relative" }}
              >
                <div className={classes.storyThumbHolder}>
                  <img className={classes.yellowBluredStory}
                  src={yellowDotBehind} alt="yellow fot"
                  style={{right: isArabic && "-20px"}} />
                  <img className={classes.storyAvatar} src={storageUrl + currentStory.image} alt="person avatar" />
                </div>
                <div className={classes.storyTitleHolder}
                style={{height:"auto", width:"100%"}}
                >
                    <p style={{fontSize:"28px", lineHeight:"22px"}}>
                      {isArabic ? currentStory.title_ar : currentStory.title}
                    </p>
                  </div>
              </Flex>
            </Col>
            <Col lg={6} md={6}>
              <Flex className={classes.storyDetailsTextHolder}>
              <pre dangerouslySetInnerHTML={{__html:  isArabic ? currentStory.text_ar : currentStory.text }}></pre>
              </Flex>
            </Col>
          </Row>
        </Container>
      </MainLayout>
      </Mobile>
    </>
    }
    </>
  );
};

export default withTranslation()(StoryDetails);
