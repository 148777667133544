import Flex from '@react-css/flex';
import React from 'react';
import { Link } from 'react-router-dom';
import classes from "./ProgramCard.module.scss";


const ProgramCard = ({thumb,title,desc, deviceType, t}) => {

  const tabletStyle={
    marginTop:"15px"
  }
  return (
    <>
    <Flex column className={classes.mainProgramHolder} style={deviceType === "tablet" ? tabletStyle : {} }>
        <div className={classes.thumbnailHolder}>
            <img src={thumb} alt="Program thumbnail" />
        </div>
        <div className={classes.titleHolder}>
            <p>{title}</p>
        </div>
        <div className={classes.descHolder}>
            <p>{desc}</p>
        </div>
    </Flex>
    </>
  )
}

export default ProgramCard