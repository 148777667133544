import React from "react";
import { Routes, Route } from "react-router-dom";
import About from "../pages/about/About";
import Contact from "../pages/contact/Contact";
import Donate from "../pages/donate/Donate";
import Eligibility from "../pages/eligibility/Eligibility";
import EmployAnOrphan from "../pages/employ-an-orphan/EmployAnOrphan";
import Index from "../pages/index/Index";
import NewsDetails from "../pages/news-details-page/NewsDetails";
import News from "../pages/news/News";
import OurImpact from "../pages/our-impact/OurImpact";
import OurPrograms from "../pages/our-programs/OurPrograms";
import Invlovement from "../pages/queen-involvement/Invlovement";
import StoryDetails from "../pages/story-details/StoryDetails";
import Volunteer from "../pages/volunteer/Volunteer";
import SuccessStories from "../pages/success-story/SuccessStories";
import SuccessPayment from "../pages/SuccessPayment/SuccessPayment";
import FailurePaymnet from "../pages/FailurePayment/FailurePaymnet";
import WorkWithUs from "../pages/WorkWithUs/WorkWithUs";

const MainRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Index />}></Route>
      <Route exact path="/queen-invlovment" element={<Invlovement />}></Route>
      <Route
        exact
        path="/story-details/:storyId"
        element={<StoryDetails />}
      ></Route>
      <Route exact path="/eligibility" element={<Eligibility />}></Route>
      <Route exact path="/news" element={<News />}></Route>
      <Route exact path="/news/:newsId" element={<NewsDetails />}></Route>
      <Route exact path="/our-programs" element={<OurPrograms />}></Route>
      <Route exact path="/our-impact" element={<OurImpact />}></Route>
      <Route exact path="/contact" element={<Contact />}></Route>
      <Route exact path="/about" element={<About />}></Route>
      <Route
        exact
        path="/employ-an-orphan"
        element={<EmployAnOrphan />}
      ></Route>
      <Route exact path="/work-with-us" element={<WorkWithUs />}></Route>
      <Route exact path="/donate" element={<Donate />}></Route>
      <Route exact path="/volunteer" element={<Volunteer />}></Route>
      <Route exact path="/our-youth" element={<SuccessStories />}></Route>
      <Route path="/success" element={<SuccessPayment />}></Route>
      <Route path="/failure" element={<FailurePaymnet />}></Route>
    </Routes>
  );
};

export default MainRoutes;
