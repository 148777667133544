export default class VolunteerServices {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    /**
     *
     * @param {*} setLeftSideData
     * @returns
     */
    async getLeftSideData(setLeftSideData) {
        await fetch(`${this.baseUrl}/GetTexts/volunteer_now`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setLeftSideData(data.Result);
                },
                (error) => {
                    console.log("something went wrong fetching Volunteer Left Side Data =>", error);
                }
            );

        return;
    }


    async getleftSideImage(setLeftSideData) {
        await fetch(`${this.baseUrl}/GetImages/volunteer_now`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setLeftSideData(data.Result);
                },
                (error) => {
                    console.log("something went wrong fetching Volunteer Left Side Data =>", error);
                }
            );

        return;
    }



    /**
     *
     * @param {*} setIsLoading
     * @param {*} setLeftSideData
     */

    async getVolunteerFormData(setIsLoading, setLeftSideData, setLeftSideImage) {
        try {
            const response = await Promise.allSettled([
                this.getLeftSideData(setLeftSideData),
                this.getleftSideImage(setLeftSideImage)
            ]);
            setIsLoading(false);
        } catch {
            console.log("something went wrong in getVolunteerFormData");
            setIsLoading(false);
        }
    }
}