import Flex from '@react-css/flex';
import React from 'react';
import YouTube from 'react-youtube';
import { CloseButton } from 'react-bootstrap';

const CustomPlayer = ({isMobile=false, link, showVideo, setShowVideo}) => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };


      const mobileOpts = {
        height: '390',
        width: '340',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };

    console.log("link => ", link);
    const youTubeIdFromLink = () =>{
    return link?.match(/(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/)[1];
    }

    console.log("ID => ", youTubeIdFromLink());

    const handleCloseBtn = () => {
        setShowVideo(false);
    }


    const _onPlay = (e) => {
        e.target.playVideo();
    }


  return (
    <>
    <Flex alignItemsCenter justifyContent={"center"} style={{background:"black",
    height:"100vh", position:"fixed", width:"100%", zIndex:"999"}}>
    <CloseButton variant="white" style={{position:"absolute",
    top:"20px",
    right: isMobile ? "20px" : "50px"}}
    onClick={handleCloseBtn}/>
    <YouTube videoId={youTubeIdFromLink()}
    className='main-player'
    opts={isMobile ? mobileOpts : opts} onReady={_onPlay} />
    </Flex>
    </>
  )
}

export default CustomPlayer;