import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import englishTrans from "./assets/translation/en/translation.json";
import arTranslation from "./assets/translation/ar/translation.json";

const availableLanguages = ["en", "ar"];
const fallbackLng = ["en"];

const resources = {
    // en: {
    //     translation: englishTrans,
    // },
    ar: {
        translation: arTranslation,
    },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng,
        detection: {
            checkWhitelist: true,
        },

        debug: false,
        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false,
        },
    });

if (localStorage.getItem('userLanguage')) {
    i18n.changeLanguage(localStorage.getItem('userLanguage'));
    const isRtl = localStorage.getItem('userLanguage') === 'ar';
    if (isRtl) {
        const direction = localStorage.getItem('userLanguage') === 'ar' ? 'rtl' : '';
        document.documentElement.setAttribute('lang', localStorage.getItem('userLanguage'));
        document.documentElement.setAttribute('dir', direction);
    } else {
        localStorage.setItem('userLanguage', localStorage.getItem('userLanguage'));
        document.documentElement.setAttribute('dir', 'ltr');
        i18n.changeLanguage('en');
    }
}


export default i18n;