import React from 'react';
import { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import {isArabic}  from '../../helpers/helpersFunctions';
import "../form-input/FormInput.module.scss";

const VolunteeringSelectList = ({label, id, required=false, formRef, t}) => {

    const [volunteeringReasons, setVolunteeringReasons] = useState([]);

    useEffect(() => {
        fetch( `https://alaman.bitsblend.org/api/V1/GetVolunteerReasons`)
          .then(res => res.json())
          .then(
            (data) => {
              setVolunteeringReasons(data.Result);
            },
            (error) => {
              console.log("error", error);
            }
          )
      }, [])


  return (
    <>
      <div className="input-form-holder mb-3">
  <label htmlFor={id} className="form-label">{label}{required && t(" (required)")}</label>
  <select ref={formRef} className="form-select" aria-label="City" id={id} style={{height:"60px", borderRadius:"16px"}}>
  <option defaultValue={t("Select")}>{t("Select")}</option>
    {
        volunteeringReasons.map((reason, index) => {
            return <option key={reason.id} value={reason.id}>{isArabic ? reason.name_ar : reason.name}</option>
        })
    }
</select>
</div>
    </>
  )
}

export default withTranslation()(VolunteeringSelectList);