import React from "react";
import classes from "./Donate.module.scss";
import MainLayout from "../../layouts/MainLayout";
import { Col, Container, Form, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import LeftSide from "../../components/form-left-side/LeftSide";
import Flex from "@react-css/flex";
import { Link } from "react-router-dom";
import FormInput from "../../components/form-input/FormInput";
import ReadOnlyFormInput from "../../components/form-input-readonly/ReadOnlyFormInput";
import CustomRadio from "../../components/custom-radio-button/CustomRadio";
import { useState, useEffect } from "react";
import FormHeader from "../../components/form-header-text/FormHeader";
import { baseUrl, isArabic } from "../../helpers/helpersFunctions";
import axios from "axios";
import Loader from "../../components/loader/Loader";
import { Desktop, Mobile, Tablet } from "../../helpers/BreakPoints";
import ReCAPTCHA from "react-google-recaptcha";

const initialFormState = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  amount: "",
  enteredValue: "",
  method: "MEPS",
  "g-recaptcha-response": "",
};

const Donate = ({ t }) => {
  const [form, setForm] = useState(initialFormState);
  const [isLoading, setIsLoading] = useState(true);
  const [leftSideImage, setLeftSideImage] = useState([]);
  const [donateFormData, setDonateFormData] = useState([]);
  const [amounValue, setAmountValue] = useState("");
  const [verified, setVerified] = useState(false);
  const getleftSideImage = () => {
    fetch(`${baseUrl}/GetImages/donate`)
      .then((res) => res.json())
      .then(
        (data) => {
          setLeftSideImage(data.Result);
        },
        (error) => {
          setIsLoading(false);
          console.log(
            "something went wrong fetching Volunteer Left Side Data =>",
            error
          );
        }
      );

    return;
  };

  const fetchDonateFormData = () => {
    fetch(`${baseUrl}/GetTexts/donate`)
      .then((res) => res.json())
      .then(
        (data) => {
          setDonateFormData(data.Result);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);

          console.log(
            "Something went weong fething fetchDonateFormData  => ",
            error
          );
        }
      );
  };

  useEffect(() => {
    document.title = t("Donate");
    const getDonateData = () => {
      fetchDonateFormData();
      getleftSideImage();
    };
    getDonateData();
  }, []);
  const handleFormChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };
  const handleOnSubmit = async (e) => {
    if (verified) {
      e.preventDefault();

      let params = "";
      for (const [key, value] of Object.entries(form)) {
        if (key !== "enteredValue") {
          params = params + `${key}=${value}&`;
        }
      }
      window.location.replace(`${baseUrl}/InitDonation?${params}`);
    }
  };
  const handleRecaptchaChange = (value) => {
    if (value) {
      setVerified(true);
      setForm({ ...form, "g-recaptcha-response": value });
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        leftSideImage.length && (
          <>
            {/* Desktop */}
            <Desktop>
              <MainLayout addPadding={false}>
                <Container fluid className={classes.formContainer}>
                  <Row>
                    <Col lg={4} md={6} style={{ paddingLeft: "50px" }}>
                      <LeftSide
                        image={leftSideImage[0].image}
                        bodyTitle={
                          isArabic
                            ? donateFormData[0].value_ar
                            : donateFormData[0].value
                        }
                        bodyDesc={
                          isArabic
                            ? donateFormData[1].value_ar
                            : donateFormData[1].value
                        }
                      />
                    </Col>

                    <Col lg={8} md={6}>
                      <Container>
                        <Row>
                          <Flex
                            row
                            className={classes.formFieldsHeaderHolder}
                            alignItemsStart
                            justifySpaceBetween
                          >
                            <div className={classes.azTitleSubTitleHolder}>
                              <a
                                href={
                                  isArabic
                                    ? donateFormData[4].value_ar
                                    : donateFormData[4].value
                                }
                              >
                                <p
                                  style={{
                                    textDecoration: "underline",
                                    fontStyle: "italic",
                                    fontFamily: "GilorySemiBold",
                                    fontSize: "36px",
                                    color: "#2762ba",
                                    lineHeight: "48px",
                                  }}
                                >
                                  {isArabic
                                    ? donateFormData[7].value_ar
                                    : donateFormData[7].value}
                                </p>
                              </a>

                              <p>
                                {isArabic
                                  ? donateFormData[8].value_ar
                                  : donateFormData[8].value}
                              </p>
                            </div>
                          </Flex>
                        </Row>
                        <Flex column className={classes.formFieldsHolder}>
                          <FormHeader
                            mainTitle={
                              isArabic
                                ? donateFormData[2].value_ar
                                : donateFormData[2].value
                            }
                            subTitle={
                              isArabic
                                ? donateFormData[3].value_ar
                                : donateFormData[3].value
                            }
                          />
                          <Form
                            method="POST"
                            className={classes.mainForm}
                            onSubmit={handleOnSubmit}
                          >
                            <Row className={classes.formFieldsRowContainer}>
                              <div className={classes.formToptext}>
                                <p>{t("Personal Info")}</p>
                              </div>
                              <Col lg={5} md={5}>
                                <FormInput
                                  type={"text"}
                                  label={t("First Name")}
                                  required={true}
                                  id={"first-name"}
                                  value={form?.first_name}
                                  onChange={(e) => {
                                    handleFormChange(
                                      "first_name",
                                      e.target.value
                                    );
                                  }}
                                />
                              </Col>
                              <Col lg={5} md={5}>
                                <FormInput
                                  type={"text"}
                                  label={t("Last Name")}
                                  required={true}
                                  id={"last-name"}
                                  value={form?.last_name}
                                  onChange={(e) => {
                                    handleFormChange(
                                      "last_name",
                                      e.target.value
                                    );
                                  }}
                                />
                              </Col>
                              <Col lg={5} md={5}>
                                <FormInput
                                  type={"text"}
                                  label={t("Contact Number")}
                                  required={true}
                                  id={"contact-number"}
                                  value={form?.phone}
                                  onChange={(e) => {
                                    handleFormChange("phone", e.target.value);
                                  }}
                                  maxLength={14}
                                />
                              </Col>
                              <Col lg={5} md={5}>
                                <FormInput
                                  type={"email"}
                                  label={t("Email")}
                                  required={true}
                                  id={"email"}
                                  value={form?.email}
                                  onChange={(e) => {
                                    handleFormChange("email", e.target.value);
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row className={classes.formFieldsRowContainer}>
                              <div className={classes.formToptext}>
                                <p>{t("Amount")}</p>
                              </div>
                              <Col lg={5} md={5}>
                                <CustomRadio
                                  id={"first-5"}
                                  value={5}
                                  isChecked={form?.amount === 5}
                                  onClick={() => handleFormChange("amount", 5)}
                                />
                              </Col>
                              <Col lg={5} md={5}>
                                <CustomRadio
                                  id={"first-20"}
                                  value={20}
                                  isChecked={form?.amount === 20}
                                  onClick={() => handleFormChange("amount", 20)}
                                />
                              </Col>
                              <Col lg={5} md={5}>
                                <CustomRadio
                                  id={"first-50"}
                                  value={50}
                                  isChecked={form?.amount === 50}
                                  onClick={() => handleFormChange("amount", 50)}
                                />
                              </Col>
                              <Col
                                lg={5}
                                md={5}
                                className={classes.customDonationInput}
                              >
                                <input
                                  className="form-control"
                                  maxLength={4}
                                  onChange={(e) => {
                                    const value = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    console.log("Value " + value);
                                    setAmountValue(value);
                                    handleFormChange("enteredValue", value);
                                    handleFormChange("amount", value);
                                    // console.log('New amount : ' + amounValue);
                                  }}
                                  value={amounValue}
                                  style={{
                                    marginTop: "25px",
                                    textAlign: "center",
                                  }}
                                  placeholder={t("Enter Amount")}
                                />
                              </Col>
                              <Row className="my-3">
                                <ReCAPTCHA
                                  sitekey={process.env.REACT_APP_SITE_KEY}
                                  onChange={handleRecaptchaChange}
                                />
                              </Row>

                              <div className={classes.btnSubmitHolder}>
                                <button
                                  disabled={!verified}
                                  className={`btn ${classes.btnSend}`}
                                  type="submit"
                                >
                                  {t("Send")}
                                </button>
                              </div>
                            </Row>
                          </Form>
                        </Flex>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </MainLayout>
            </Desktop>

            {/* Tablet */}
            <Tablet>
              <MainLayout addPadding={false}>
                <Container fluid className={classes.formContainer}>
                  <Row>
                    <Col lg={4} md={6}>
                      <LeftSide
                        image={leftSideImage[0].image}
                        bodyTitle={
                          isArabic
                            ? donateFormData[0].value_ar
                            : donateFormData[0].value
                        }
                        bodyDesc={
                          isArabic
                            ? donateFormData[3].value_ar
                            : donateFormData[3].value
                        }
                      />
                    </Col>
                    <Col lg={8} md={6}>
                      <Container>
                        <Row>
                          <Flex
                            row
                            className={classes.formFieldsHeaderHolder}
                            alignItemsStart
                            justifySpaceBetween
                          >
                            <div className={classes.azTitleSubTitleHolder}>
                              <a
                                href={
                                  isArabic
                                    ? donateFormData[4].value_ar
                                    : donateFormData[4].value
                                }
                              >
                                <p
                                  style={{
                                    textDecoration: "underline",
                                    fontStyle: "italic",
                                    fontFamily: "GilorySemiBold",
                                    fontSize: "36px",
                                    color: "#2762ba",
                                    lineHeight: "48px",
                                  }}
                                >
                                  {isArabic
                                    ? donateFormData[7].value_ar
                                    : donateFormData[7].value}
                                </p>
                              </a>
                              <p>
                                {isArabic
                                  ? donateFormData[8].value_ar
                                  : donateFormData[8].value}
                              </p>
                            </div>
                          </Flex>
                        </Row>
                        <Flex column className={classes.formFieldsHolder}>
                          <FormHeader
                            mainTitle={
                              isArabic
                                ? donateFormData[2].value_ar
                                : donateFormData[2].value
                            }
                            subTitle={
                              isArabic
                                ? donateFormData[1].value_ar
                                : donateFormData[1].value
                            }
                          />
                          <Form
                            method="POST"
                            className={classes.mainForm}
                            onSubmit={handleOnSubmit}
                          >
                            <Row className={classes.formFieldsRowContainer}>
                              <div className={classes.formToptext}>
                                <p>{t("Personal Info")}</p>
                              </div>
                              <Col lg={5} md={5}>
                                <FormInput
                                  type={"text"}
                                  label={t("First Name")}
                                  required={true}
                                  id={"first-name"}
                                  value={form?.first_name}
                                  onChange={(e) => {
                                    handleFormChange(
                                      "first_name",
                                      e.target.value
                                    );
                                  }}
                                />
                              </Col>
                              <Col lg={5} md={5}>
                                <FormInput
                                  type={"text"}
                                  label={t("Last Name")}
                                  required={true}
                                  id={"last-name"}
                                  value={form?.last_name}
                                  onChange={(e) => {
                                    handleFormChange(
                                      "last_name",
                                      e.target.value
                                    );
                                  }}
                                />
                              </Col>
                              <Col lg={5} md={5}>
                                <FormInput
                                  type={"text"}
                                  label={t("Contact Number")}
                                  required={true}
                                  id={"contact-number"}
                                  value={form?.phone}
                                  onChange={(e) => {
                                    handleFormChange("phone", e.target.value);
                                  }}
                                  maxLength={14}
                                />
                              </Col>
                              <Col lg={5} md={5}>
                                <FormInput
                                  type={"email"}
                                  label={t("Email")}
                                  required={true}
                                  id={"email"}
                                  value={form?.email}
                                  onChange={(e) => {
                                    handleFormChange("email", e.target.value);
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row className={classes.formFieldsRowContainer}>
                              <div className={classes.formToptext}>
                                <p>{t("Amount")}</p>
                              </div>
                              <Col lg={5} md={5}>
                                <CustomRadio
                                  id={"first-5"}
                                  value={5}
                                  isChecked={form?.amount === 5}
                                  onClick={() => handleFormChange("amount", 5)}
                                />
                              </Col>
                              <Col lg={5} md={5}>
                                <CustomRadio
                                  id={"first-20"}
                                  value={20}
                                  isChecked={form?.amount === 20}
                                  onClick={() => handleFormChange("amount", 20)}
                                />
                              </Col>
                              <Col lg={5} md={5}>
                                <CustomRadio
                                  id={"first-50"}
                                  value={50}
                                  isChecked={form?.amount === 50}
                                  onClick={() => handleFormChange("amount", 50)}
                                />
                              </Col>
                              <Col
                                lg={5}
                                md={5}
                                className={classes.customDonationInput}
                              >
                                <input
                                  className="form-control"
                                  maxLength={4}
                                  onChange={(e) => {
                                    const value = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    setAmountValue(value);
                                    handleFormChange("enteredValue", value);
                                    handleFormChange("amount", value);
                                  }}
                                  value={amounValue}
                                  style={{
                                    marginTop: "25px",
                                    textAlign: "center",
                                  }}
                                  placeholder={t("Enter Amount")}
                                />
                              </Col>
                              <Row className="my-3">
                                <ReCAPTCHA
                                  sitekey={process.env.REACT_APP_SITE_KEY}
                                  onChange={handleRecaptchaChange}
                                />
                              </Row>
                              <div className={classes.btnSubmitHolder}>
                                <button
                                  disabled={!verified}
                                  className={`btn ${classes.btnSend}`}
                                  type="submit"
                                >
                                  {t("Send")}
                                </button>
                              </div>
                            </Row>
                          </Form>
                        </Flex>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </MainLayout>
            </Tablet>

            {/* Mobile */}
            <Mobile>
              <MainLayout addPadding={false}>
                <Container fluid className={classes.formContainer}>
                  <Row>
                    <Col lg={4} md={6} style={{ marginBottom: "30px" }}>
                      <LeftSide
                        image={leftSideImage[0].image}
                        bodyTitle={
                          isArabic
                            ? donateFormData[0].value_ar
                            : donateFormData[0].value
                        }
                        bodyDesc={
                          isArabic
                            ? donateFormData[1].value_ar
                            : donateFormData[1].value
                        }
                        removeHeightWidth={true}
                      />
                    </Col>
                    <Col lg={8} md={6}>
                      <Container>
                        <Row>
                          <Flex
                            row
                            className={classes.formFieldsHeaderHolder}
                            alignItemsStart
                            justifySpaceBetween
                          >
                            <div className={classes.azTitleSubTitleHolder}>
                              <a
                                href={
                                  isArabic
                                    ? donateFormData[4].value_ar
                                    : donateFormData[4].value
                                }
                              >
                                <p
                                  style={{
                                    textDecoration: "underline",
                                    fontStyle: "italic",
                                    fontFamily: "GilorySemiBold",
                                    fontSize: "36px",
                                    color: "#2762ba",
                                    lineHeight: "48px",
                                  }}
                                >
                                  {isArabic
                                    ? donateFormData[7].value_ar
                                    : donateFormData[7].value}
                                </p>
                              </a>
                              <p>
                                {isArabic
                                  ? donateFormData[8].value_ar
                                  : donateFormData[8].value}{" "}
                              </p>
                            </div>
                          </Flex>
                        </Row>
                        <Flex column className={classes.formFieldsHolder}>
                          <FormHeader
                            mainTitle={
                              isArabic
                                ? donateFormData[2].value_ar
                                : donateFormData[2].value
                            }
                            subTitle={
                              isArabic
                                ? donateFormData[3].value_ar
                                : donateFormData[3].value
                            }
                          />
                          <Form
                            method="POST"
                            className={classes.mainForm}
                            onSubmit={handleOnSubmit}
                          >
                            <Row className={classes.formFieldsRowContainer}>
                              <div className={classes.formToptext}>
                                <p>{t("Personal Info")}</p>
                              </div>
                              <Col lg={5} md={5}>
                                <FormInput
                                  type={"text"}
                                  label={t("First Name")}
                                  required={true}
                                  id={"first-name"}
                                  value={form?.first_name}
                                  onChange={(e) => {
                                    handleFormChange(
                                      "first_name",
                                      e.target.value
                                    );
                                  }}
                                />
                              </Col>
                              <Col lg={5} md={5}>
                                <FormInput
                                  type={"text"}
                                  label={t("Last Name")}
                                  required={true}
                                  id={"last-name"}
                                  value={form?.last_name}
                                  onChange={(e) => {
                                    handleFormChange(
                                      "last_name",
                                      e.target.value
                                    );
                                  }}
                                />
                              </Col>
                              <Col lg={5} md={5}>
                                <FormInput
                                  type={"text"}
                                  label={t("Contact Number")}
                                  required={true}
                                  id={"contact-number"}
                                  value={form?.phone}
                                  onChange={(e) => {
                                    handleFormChange("phone", e.target.value);
                                  }}
                                  maxLength={14}
                                />
                              </Col>
                              <Col lg={5} md={5}>
                                <FormInput
                                  type={"email"}
                                  label={t("Email")}
                                  required={true}
                                  id={"email"}
                                  value={form?.email}
                                  onChange={(e) => {
                                    handleFormChange("email", e.target.value);
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row className={classes.formFieldsRowContainer}>
                              <div className={classes.formToptext}>
                                <p>{t("Amount")}</p>
                              </div>
                              <Col lg={5} md={5}>
                                <CustomRadio
                                  id={"first-5"}
                                  value={5}
                                  isChecked={form?.amount === 5}
                                  onClick={() => handleFormChange("amount", 5)}
                                />
                              </Col>
                              <Col lg={5} md={5}>
                                <CustomRadio
                                  id={"first-20"}
                                  value={20}
                                  isChecked={form?.amount === 20}
                                  onClick={() => handleFormChange("amount", 20)}
                                />
                              </Col>
                              <Col lg={5} md={5}>
                                <CustomRadio
                                  id={"first-50"}
                                  value={50}
                                  isChecked={form?.amount === 50}
                                  onClick={() => handleFormChange("amount", 50)}
                                />
                              </Col>
                              <Col
                                lg={5}
                                md={5}
                                className={classes.customDonationInput}
                              >
                                <input
                                  className="form-control"
                                  maxLength={4}
                                  onChange={(e) => {
                                    const value = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    setAmountValue(value);
                                    handleFormChange("enteredValue", value);
                                    handleFormChange("amount", value);
                                  }}
                                  value={amounValue}
                                  style={{
                                    marginTop: "25px",
                                    textAlign: "center",
                                  }}
                                  placeholder={t("Enter Amount")}
                                />
                              </Col>
                              <Row className="my-3">
                                <ReCAPTCHA
                                  sitekey={process.env.REACT_APP_SITE_KEY}
                                  onChange={handleRecaptchaChange}
                                />
                              </Row>
                              <div className={classes.btnSubmitHolder}>
                                <button
                                  disabled={!verified}
                                  className={`btn ${classes.btnSend}`}
                                  type="submit"
                                >
                                  {t("Send")}
                                </button>
                              </div>
                            </Row>
                          </Form>
                        </Flex>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </MainLayout>
            </Mobile>
          </>
        )
      )}
    </>
  );
};

export default withTranslation()(Donate);
