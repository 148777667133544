import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import Loader from "../../components/loader/Loader";
import { getPublishedJobs } from "./WorkService";
import { Desktop, Mobile, Tablet } from "../../helpers/BreakPoints";
import WorkList from "./WorkList";

function WorkWithUs() {
  const [isLoading, setIsLoading] = useState(false);
  const [jobsList, setJobsList] = useState([]);
  useEffect(() => {
    document.title = "Work With Us";
    getPublishedJobs(setIsLoading, setJobsList);
  }, []);
  return isLoading ? (
    <Loader />
  ) : (
    <MainLayout>
      <Desktop>
        <WorkList data={jobsList} />
      </Desktop>
      <Tablet>
        <WorkList data={jobsList} />
      </Tablet>
      <Mobile>
        <WorkList data={jobsList} />
      </Mobile>
    </MainLayout>
  );
}

export default WorkWithUs;
