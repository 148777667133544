import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { withTranslation } from "react-i18next";
import { Desktop, Tablet, Mobile } from "../../helpers/BreakPoints";

// eslint-disable-next-line
import "swiper/css/bundle";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
// import "swiper/css/autoplay";

import "./sponsor-styles.scss";

import { Pagination, Autoplay } from "swiper";
import { isArabic, storageUrl, baseUrl } from "../../helpers/helpersFunctions";

const SponsorSwiper = ({ t }) => {
  const [sponsorsList, setSponsorsList] = useState([]);

const fetchSliderNews = async () => {
  await fetch(`${baseUrl}/GetSponsors`)
      .then((res) => res.json())
      .then(
          (data) => {
            setSponsorsList(data.Result);
            console.log(sponsorsList.length);
          },
          (error) => {
              console.log("something went wrong fetching slider News => ", error);
          }
      );

  return;
}

  useEffect(() => {
    fetchSliderNews();
  },[])


  return (
    <>
    {/* For Desktop */}
    <Desktop>
      <Swiper
        slidesPerView={4}
        dir={isArabic ? "rtl" : "ltr"}
        autoplay={{
          delay: 1000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        spaceBetween={40}
        className="sponsor-swiper"
        modules={[
          Pagination,
          Autoplay
        ]}
        loop={true}
      >
        { sponsorsList.map((sponsor, index) => {
        return <SwiperSlide key={sponsor.id} className="sponsor-swiper-slide">
            <a href={sponsor.link}>
              <img src={storageUrl + sponsor.image} alt="sponsor"/>
            </a>
        </SwiperSlide>
      })}
      </Swiper>
      </Desktop>

    {/* for Tablet */}
    <Tablet>
      <Swiper
        slidesPerView={4}
        dir={isArabic ? "rtl" : "ltr"}
        autoplay={{
          delay: 1000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        spaceBetween={40}
        className="sponsor-swiper"
        modules={[
          Pagination,
          Autoplay
        ]}
        loop={true}
      >
        { sponsorsList.map((sponsor, index) => {
        return <SwiperSlide key={sponsor.id} className="sponsor-swiper-slide">
            <a href={sponsor.link}>
              <img src={storageUrl + sponsor.image} alt="sponsor"/>
            </a>
        </SwiperSlide>
      })}
      </Swiper>
      </Tablet>

      {/* Mobile */}
      <Mobile>
      <Swiper
        slidesPerView={1}
        dir={isArabic ? "rtl" : "ltr"}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        loop={true}
        className="sponsor-swiper-mobile"
        modules={[
          Pagination,
          Autoplay
        ]}
        // key={isArabic}
      >
        { sponsorsList.map((sponsor, index) => {
        return <SwiperSlide key={sponsor.id} className="sponsor-swiper-slide" style={{width:"83%"}}>
            <a href={sponsor.link}>
              <img style={{width:"200px", height:"200px"}} src={storageUrl + sponsor.image} alt="sponsor"/>
            </a>
        </SwiperSlide>
      })}
      </Swiper>
      </Mobile>
    </>
  );
};

export default withTranslation()(SponsorSwiper);
