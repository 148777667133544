const apiLink = process.env.REACT_APP_BASE_URL + "/Careers";
const requestOptions = {
  method: "GET",
  headers: {
    clientID: process.env.REACT_APP_CLIENTID,
    clientSecret: process.env.REACT_APP_CLIENT_SECRET,
  },
};
export const getPublishedJobs = async (setIsLoading, setJobsList) => {
  setIsLoading(true);
  await fetch(apiLink, requestOptions)
    .then((res) => res.json())
    .then(
      (data) => {
        setJobsList(data.Data.lstJobPosts);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);

        console.log("something went wrong fetching published jobs =>", error);
      }
    );
};
