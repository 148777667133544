import React from "react";
import classes from "./SupportBox.module.scss";
import { withTranslation } from "react-i18next";
import Flex from "@react-css/flex";

const SupportBox = ({ icon, title, desc }) => {
  return (
    <>
      <Flex
        column
        className={classes.supportBox}
      >
        <div className={classes.supportIconHolder}>
          <img src={icon} alt="Poverty icon" style={{ width: "100px", height:"100px", borderRadius:"25px" }}/>
        </div>
        <div className={classes.supportTitleHolder}>
          <h3>{title}</h3>
        </div>
        <div className={classes.supportDescHolder}>
          <p>{desc}</p>
        </div>
      </Flex>
    </>
  );
};

export default withTranslation()(SupportBox);
