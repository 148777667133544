/**
 *
 * Developed By : @WaleedSaleh
 * date: 24/10/2022
 * Index page
 */
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import MainLayout from "../../layouts/MainLayout";
import Flex from "@react-css/flex";
import { Container, Row, Col, Button } from "react-bootstrap";
import classes from "./Index.module.scss";
import { Link, useNavigate } from "react-router-dom";
import playerIcon from "../../assets/images/index-media/video-player-icon.svg";
import polygon from "../../assets/images/index-media/ploygon.svg";
import BannerText from "../../components/banner-text/BannerText";
import OrphanStats from "../../components/orphan-stats/OrphanStats";
import readMoreArrow from "../../assets/images/index-media/read-more-arrow.svg";
import grayArrow from "../../assets/images/index-media/gray-arrow.svg";
import ProgramCard from "../../components/program-cards/ProgramCard";
import balckArrow from "../../assets/images/index-media/black-arrow.svg";
import whiteArrow from "../../assets/images/shared/white-arrow.svg";
import yellowArrow from "../../assets/images/shared/yellow-arrow.svg";
import NewsSwiper from "../../components/news-slider/NewsSwiper";
import blueArrow from "../../assets/images/index-media/blue-arrow.svg";
import SponsorSwiper from "../../components/sponsors-slider/SponsorSwiper";
import { isArabic, storageUrl, baseUrl } from "../../helpers/helpersFunctions";
import IndexServices from "./IndexService";
import StoryCards from "../../components/story-card/StoryCards";
import { Desktop, Mobile, Tablet } from "../../helpers/BreakPoints";
import Loader from "../../components/loader/Loader";
// import Typewriter from 'typewriter-effect';
import { Typewriter } from 'react-simple-typewriter'
import CustomPlayer from "../../components/youtube-player/CustomPlayer";

const Index = ({ t }) => {
  // @TODO add video handler

  const naviagte = useNavigate();
  const [changeBlue, setChangeBlue] = useState(false);
  const [changeYellow, setChangeYellow] = useState(false);
  const [queenNews, setQueenNews] = useState([]);
  const [homeText, setHomeTexts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [homeCounters, setHomeCounters] = useState([]);
  const [homeSuccessStories, setHomeSuccessStories] = useState([]);
  const [homePrograms, setHomePrograms] = useState([]);
  const [homeImages, setHomeImages] = useState([]);
  const indexServices = new IndexServices(baseUrl);
  const [mainQueenInv, setMainQueenInv] = useState({});
  const [showVideo, setShowVideo] = useState(false);

  const handleNavigation = () => {
    naviagte("/donate");
  };

  const handleMouseUp = () => {
    setChangeBlue(true);
  };

  const handleVideoClick = () => {
    setShowVideo(true);
    console.log("Clicked");
  }



  useEffect(() => {
    document.title = t("Home Page");
    indexServices.getHomeData(
      setIsLoading,
      setHomeTexts,
      setHomeCounters,
      setHomeImages,
      setHomeSuccessStories,
      setHomePrograms,
      setQueenNews,
      setMainQueenInv
    );
  }, []);

  return (
    <>
      {/* Desktop */}
      <Desktop>

      {isLoading && <Loader />}
      {showVideo && <CustomPlayer
      isMobile={false}
      link={homeText[11].value}
      showVideo={showVideo}
      setShowVideo={setShowVideo}/>}
        <MainLayout addPadding={false}>
          {!isLoading && (
            <>
              {/* Section 1 */}
              <section
                className={classes.indexSection1}
                style={{ background: "#f7feff" }}
              >
                <Flex>
                  <Container>
                    <Row className="mb-5">
                      <div
                        className={classes.Section1ContentHolder}
                        style={{ position: isArabic && "unset" }}
                      >
                      </div>
                      <div
                        className={`${classes.section1TextHolder} d-flex flex-column align-items-center justify-content-center mt-5`}
                      >
                        <div
                          className={`${classes.titleHolder} text-center`}
                          style={{ width: "555px" }}
                        >
                          <h1 className={classes.title}>
                            {isArabic
                              ? homeText[0].value_ar
                              : homeText[0].value}
                            <span className={classes.bluetext}>
                              {/* {t("Better|")} */}
                              {/* <Typewriter
                                component='span'
                                options={{
                                  strings: ,
                                  autoStart: true,
                                  loop: true,
                                }}
                              /> */}
                              <Typewriter
            words={isArabic ? homeText[9].value_ar.split(",") : homeText[9].value.split(",")}
            loop={true}
            cursor
            cursorStyle='|'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          />
                            </span>{" "}
                            <br></br>
                            {isArabic
                              ? homeText[8].value_ar
                              : homeText[8].value}
                          </h1>
                        </div>
                        <div className={`${classes.descHolder} text-center`}>
                          <p>
                            {isArabic
                              ? homeText[1].value_ar
                              : homeText[1].value}
                          </p>
                        </div>
                        <div className="btnLinkHolder d-flex align-items-center">
                          <div
                            className={classes.linkHolder}
                            style={{
                              marginLeft: isArabic && "33px",
                              marginRight: isArabic && 0,
                            }}
                          >
                            <Link to="/volunteer">{t("Get Involved")}</Link>
                          </div>
                          <div className="donateBtnHolder">
                            <Button
                              className={`${classes.btnDonate}`}
                              onClick={handleNavigation}
                              variant="link"
                            >
                              {t("Donate")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row
                      className={classes.imagesRow}
                      style={{ position: "relative" }}
                    >
                      <Container>
                        <div className={classes.section1imagesMainHolder}>
                          <div className={classes.mainCover}>
                            <div
                              className={`${classes.playerIconHolder} d-flex align-items-center`}
                              onClick={handleVideoClick}
                            >
                              <img
                                className={classes.playIcon}
                                src={playerIcon}
                                alt="Video Player"
                              />
                              <img
                                className={classes.startIcon}
                                src={polygon}
                                alt="Start"
                              />
                            </div>
                            <img
                              className={classes.mainCoverImage}
                              src={`${storageUrl + homeImages[0].image}`}
                              alt="Main cover"
                              style={{ zIndex: "5" }}
                            />
                          </div>
                          <div
                            className={classes.secondCover}
                            style={{ position: "relative" }}
                          >
                            <img
                              className={`${classes.secondImage} img-fluid`}
                              src={storageUrl + homeImages[1].image}
                              style={{
                                left: isArabic && 0,
                                right: isArabic && "30px",
                              }}
                              alt="Second cover"
                            />
                          </div>
                        </div>
                      </Container>
                    </Row>
                  </Container>
                </Flex>
              </section>

              {/* Section 2 */}
              <section
                className={`${classes.indexSection2} d-flex align-items-center w-100 mb-5`}
                style={{ background: "#2A7DE1", height: "156px" }}
              >
                <Container className={classes.mainContainer}>
                  <Row>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={homeCounters[0].value}
                        title={
                          isArabic
                            ? homeCounters[0].title_ar
                            : homeCounters[0].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[0].text_ar
                            : homeCounters[0].text
                        }
                      />
                    </Col>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={homeCounters[1].value}
                        title={
                          isArabic
                            ? homeCounters[1].title_ar
                            : homeCounters[1].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[1].text_ar
                            : homeCounters[1].text
                        }
                      />
                    </Col>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={homeCounters[2].value}
                        title={
                          isArabic
                            ? homeCounters[2].title_ar
                            : homeCounters[2].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[2].text_ar
                            : homeCounters[2].text
                        }
                      />
                    </Col>
                  </Row>
                </Container>
              </section>

              {/* Section 3 */}
              <section className={classes.indexSection3}>
                <Container>
                  <Row className="mb-5">
                    <Col lg={4} md={4}>
                      <OrphanStats
                        number={homeCounters[3].value}
                        title={
                          isArabic
                            ? homeCounters[3].title_ar
                            : homeCounters[3].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[3].text_ar
                            : homeCounters[3].text
                        }
                      />
                    </Col>
                    <Col lg={4} md={4}>
                      <OrphanStats
                        number={homeCounters[4].value}
                        title={
                          isArabic
                            ? homeCounters[4].title_ar
                            : homeCounters[4].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[4].text_ar
                            : homeCounters[4].text
                        }
                      />
                    </Col>
                    <Col lg={4} md={4}>
                      <OrphanStats
                        number={homeCounters[5].value}
                        title={
                          isArabic
                            ? homeCounters[5].title_ar
                            : homeCounters[5].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[5].text_ar
                            : homeCounters[5].text
                        }
                      />
                    </Col>
                  </Row>
                  <Row className={classes.section3ImagesRow}>
                    <Container className="d-flex">
                      <Col
                        lg={4}
                        md={4}
                        className="d-flex align-items-center justify-content-end"
                      >
                        <div
                          className={classes.imageTextDivHolder}
                          style={{ position: "relative" }}
                        >
                          <div className={classes.firstDivTitle}>
                            <h1>
                              {isArabic
                                ? homeText[6].value_ar
                                : homeText[6].value}
                            </h1>
                          </div>
                          <div className={classes.firstDivDesc}>
                            <p>{isArabic ? homeText[10].value_ar : homeText[10].value }</p>
                          </div>
                          <div className={classes.firstDivLink}>
                            <Link to="/our-youth">{t("Read more")}</Link>
                            <img src={readMoreArrow} alt="Blue arrow" />
                          </div>
                        </div>
                      </Col>
                      {homeSuccessStories.slice(0, 2).map((story, index) => {
                        return (
                          <Col key={story.id} lg={4} md={6}>
                            <StoryCards
                              id={story.id}
                              avatar={storageUrl + story.image}
                              title={isArabic ? story.title_ar : story.title}
                              desc={isArabic ? story.text_ar : story.text}
                            />
                          </Col>
                        );
                      })}
                    </Container>
                  </Row>
                </Container>
              </section>

              {/* Section 4 */}

              <section className={classes.indexBanner2}>
                <Container>
                  <Row>
                    <Col lg={12} md={6} style={{ padding: "50px" }}>
                      <div className={classes.bannerContentHolder}>
                        <div className={classes.bannerTitleHolder}>
                          <h1 className="text-white">
                            {isArabic
                              ? homeText[5].value_ar
                              : homeText[5].value}
                          </h1>
                        </div>
                        <Flex
                          row
                          className="banner-btns-holder align-items-center justify-content-center"
                        >
                          <div
                            className={classes.bannerLinkHolder}
                            style={{
                              marginRight: isArabic && 0,
                              marginLeft: isArabic && "30px",
                            }}
                          >
                            <Link to="/volunteer">{t("Get Involved")}</Link>
                          </div>
                          <div className="yellow-btn-holder">
                            <Button
                              className={`${classes.btnDonateYellow}`}
                              onClick={handleNavigation}
                              variant="link"
                            >
                              {t("Donate")}
                            </Button>
                          </div>
                        </Flex>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>

              {/* Section 5 */}
              <section className={classes.programsSection}>
                <Container className={classes.mainContainer}>
                  <Flex row justifySpaceBetween>
                    <div className={classes.programsSectionTitle}>
                      <p className={classes.ourProgramTitle}>
                        {isArabic ? homeText[4].value_ar : homeText[4].value}
                      </p>
                    </div>
                  </Flex>
                  <Container>
                    <Row
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      {homePrograms.map((program, index) => {
                        return (
                          <Col lg={4} md={6} key={program.id}>
                            <ProgramCard
                              thumb={storageUrl + program.thumb}
                              title={
                                isArabic ? program.title_ar : program.title
                              }
                              desc={
                                isArabic
                                  ? program.text_ar.slice(0, 250) + "..."
                                  : program.text.slice(0, 250) + "..."
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Container>
                  <Flex row justifyEnd>
                  <div
                      className={classes.programsSectionRoute}
                      onMouseOver={handleMouseUp}
                      onMouseLeave={() => {
                        setChangeBlue(false);
                      }}
                      style={{
                        cursor: changeBlue && "pointer",
                        height: "20px",
                      }}
                    >
                      <Link
                        className={classes.readMoreGray}
                        to="/our-programs"
                        style={{
                          color: changeBlue && "#0a58ca",
                          position: isArabic && "relative",
                          zIndex: isArabic && 3,
                        }}
                      >
                        {t("Read more")}
                      </Link>
                      {changeBlue ? (
                        <img src={blueArrow} alt="Blue arrow" />
                      ) : (
                        <img src={grayArrow} alt="Gray arrow" />
                      )}
                    </div>
                  </Flex>
                </Container>
              </section>

              {/* Section 6 */}
              <section
                className={`${classes.section6} container`}
                style={{ position: "relative" }}
              >
                <Flex>
                  <Container className={classes.invContainer}>
                    <div className={classes.section6Header}>
                      <p>{isArabic ? homeText[3].value_ar : homeText[3].value}</p>
                    </div>
                    <Row className="main-row">
                      <Col lg={4} md={12}>
                        <Link to={`news/${mainQueenInv.id}`}>
                          <div className={classes.mainCoverQueen}>
                            <img
                              src={storageUrl + mainQueenInv.thumb}
                              className={classes.mainQueenImage}
                              alt="queen Main"
                            />
                           <div
                            className={classes.mainFloatText}>
                            <p style={{padding:isArabic && "7px"}}>
                              {isArabic
                                ? mainQueenInv.title_ar.slice(0, 120) + "..."
                                : mainQueenInv.title.slice(0, 120) + "..."}
                            </p>
                          </div>
                          </div>
                        </Link>
                      </Col>
                      <Col lg={8} md={6}>
                        <Row>
                          {queenNews.map((item, index) => {
                            return (
                              <Col lg={4} md={12} className={classes.qNewsCol}>
                                <Link to={`news/${item.id}`} className={classes.qNewsLink}>
                                  <div className={classes.invThumb}>
                                    <img
                                      src={storageUrl + item.thumb}
                                      alt="inv Thumb"
                                    />
                                    <div
                                    className={classes.floatText}>
                                    <p style={{padding: isArabic && "7px"}}>
                                      {isArabic ? item.title_ar.slice(0, 120) + "..." : item.title.slice(0, 120) + "..."}
                                    </p>
                                  </div>
                                  </div>
                                </Link>
                              </Col>
                            );
                          })}
                          <Col lg={4} md={12} className={classes.readMeYellow}>
                            <Link to="/queen-invlovment">
                              <Flex
                                column
                                alignItemsCenter
                                justifyCenter
                                className={classes.readMorefloatText}
                              >
                                <img src={balckArrow} alt="black Arrow" />
                                <p>{t("Read more")}</p>
                              </Flex>
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </Flex>
              </section>
              {/* Section 8 News Swiper */}
              <section className={`${classes.section8} container-fluid`}>
                <Container>
                  <Flex justifySpaceBetween alignItemsCenter>
                    <div className={classes.ssection8TitleHolder}>
                      <p>
                        {isArabic ? homeText[2].value_ar : homeText[2].value}
                      </p>
                    </div>
                    <div
                      className={classes.section8LinkHolder}
                      onMouseOver={() => {
                        setChangeYellow(true);
                      }}
                      onMouseLeave={() => {
                        setChangeYellow(false);
                      }}
                    >
                      <Link to="/news">
                        <Flex
                          alignItemsCenter
                          className={classes.section8ReadMoreHolder}
                        >
                          <p
                            className="mb-1"
                            style={{ color: changeYellow && "#FFC629" }}
                          >
                            {t("Read more")}
                          </p>
                          {changeYellow ? (
                            <img src={yellowArrow} alt="White Arrow" />
                          ) : (
                            <img src={whiteArrow} alt="White Arrow" />
                          )}
                        </Flex>
                      </Link>
                    </div>
                  </Flex>
                  <div
                    className={classes.swiperHolder}
                    style={{ paddingBottom: "61px" }}
                  >
                    <NewsSwiper />
                  </div>
                </Container>
              </section>

              {/* Section 9 */}
              <section className={classes.section9}>
                <Container>
                  <Flex className={classes.section9TitleHolder}>
                    <p>{isArabic ? homeText[7].value_ar : homeText[7].value}</p>
                  </Flex>
                  <div className={classes.sponorsSwiperHolder}>
                    <SponsorSwiper />
                  </div>
                </Container>
              </section>
            </>
          )}
        </MainLayout>
      </Desktop>

      {/* Tablet */}
      <Tablet>
      {showVideo && <CustomPlayer
      link={homeText[11].value}
      showVideo={showVideo}
      setShowVideo={setShowVideo}/>}
      {isLoading && <Loader />}
        <MainLayout addPadding={false}>
          {!isLoading && (
            <>
              {/* Section 1 */}
              <section
                className={classes.indexSection1}
                style={{ background: "#f7feff" }}
              >
                <Flex>
                  <Container>
                    <Row className="mb-5">
                      <div
                        className={`${classes.section1TextHolder} d-flex flex-column align-items-center justify-content-center mt-5`}
                      >
                        <div
                          className={`${classes.titleHolder} text-center`}
                          style={{ width: "555px" }}
                        >
                          <h1 className={classes.title}>
                            {isArabic
                              ? homeText[0].value_ar
                              : homeText[0].value}
                            <span
                              className={classes.bluetext}
                              id="changing-words"
                            >
<Typewriter
            words={isArabic ? homeText[9].value_ar.split(",") : homeText[9].value.split(",")}
            loop={true}
            cursor
            cursorStyle='|'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          />
                            </span>{" "}
                            <br></br>
                            {isArabic
                              ? homeText[8].value_ar
                              : homeText[8].value}
                          </h1>
                        </div>
                        <div className={`${classes.descHolder} text-center`}>
                          <p>
                            {isArabic
                              ? homeText[1].value_ar
                              : homeText[1].value}
                          </p>
                        </div>
                        <div className="btnLinkHolder d-flex align-items-center">
                          <div
                            className={classes.linkHolder}
                            style={{
                              marginLeft: isArabic && "33px",
                              marginRight: isArabic && 0,
                            }}
                          >
                            <Link to="/volunteer">{t("Get Involved")}</Link>
                          </div>
                          <div className="donateBtnHolder">
                            <Button
                              className={`${classes.btnDonate}`}
                              onClick={handleNavigation}
                              variant="link"
                            >
                              {t("Donate")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row
                      className={classes.imagesRow}
                      style={{ position: "relative" }}
                    >
                      <Container>
                        <div className={classes.section1imagesMainHolder}>
                          <div className={classes.mainCover}>
                            <div
                              className={`${classes.playerIconHolder} d-flex align-items-center`}
                              onClick={handleVideoClick}
                            >
                              <img
                                className={classes.playIcon}
                                src={playerIcon}
                                alt="Video Player"
                              />
                              <img
                                className={classes.startIcon}
                                src={polygon}
                                alt="Start"
                              />
                            </div>
                            <img
                              className="img-fluid"
                              src={`${storageUrl + homeImages[0].image}`}
                              alt="Main cover"
                              style={{ zIndex: "5" }}
                            />
                          </div>
                          <div
                            className={classes.secondCover}
                            style={{ position: "relative" }}
                          >
                            <img
                              className={`${classes.secondImage} img-fluid`}
                              src={storageUrl + homeImages[1].image}
                              style={{
                                left: isArabic && 0,
                                right: isArabic && "30px",
                              }}
                              alt="Second cover"
                            />
                          </div>
                        </div>
                      </Container>
                    </Row>
                  </Container>
                </Flex>
              </section>

              {/* Section 2 */}
              <section
                className={`${classes.indexSection2} d-flex align-items-center w-100 mb-5`}
                style={{ background: "#2A7DE1", height: "156px" }}
              >
                <Container className={classes.mainContainer}>
                  <Row>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={homeCounters[0].value}
                        title={
                          isArabic
                            ? homeCounters[0].title_ar
                            : homeCounters[0].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[0].text_ar
                            : homeCounters[0].text
                        }
                        deviceType={"tablet"}
                      />
                    </Col>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={homeCounters[1].value}
                        title={
                          isArabic
                            ? homeCounters[1].title_ar
                            : homeCounters[1].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[1].text_ar
                            : homeCounters[1].text
                        }
                        deviceType={"tablet"}
                      />
                    </Col>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={homeCounters[2].value}
                        title={
                          isArabic
                            ? homeCounters[2].title_ar
                            : homeCounters[2].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[2].text_ar
                            : homeCounters[2].text
                        }
                        deviceType={"tablet"}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>

              {/* Section 3 */}
              <section className={classes.indexSection3}>
                <Container>
                  <Row className="mb-5">
                    <Col lg={4} md={4}>
                      <OrphanStats
                        number={homeCounters[3].value}
                        title={
                          isArabic
                            ? homeCounters[3].title_ar
                            : homeCounters[3].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[3].text_ar
                            : homeCounters[3].text
                        }
                        deviceType="tablet"
                      />
                    </Col>
                    <Col lg={4} md={4}>
                      <OrphanStats
                        number={homeCounters[4].value}
                        title={
                          isArabic
                            ? homeCounters[4].title_ar
                            : homeCounters[4].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[4].text_ar
                            : homeCounters[4].text
                        }
                        deviceType="tablet"
                      />
                    </Col>
                    <Col lg={4} md={4}>
                      <OrphanStats
                        number={homeCounters[5].value}
                        title={
                          isArabic
                            ? homeCounters[5].title_ar
                            : homeCounters[5].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[5].text_ar
                            : homeCounters[5].text
                        }
                        deviceType="tablet"
                      />
                    </Col>
                  </Row>
                  <Row className={classes.section3ImagesRow}>
                    <Container className="d-flex">
                      <Col
                        lg={4}
                        md={2}
                        className="d-flex align-items-center justify-content-end"
                      >
                        <div
                          className={classes.imageTextDivHolder}
                          style={{ position: "relative" }}
                        >
                          <div
                            className={classes.firstDivTitle}
                            style={{ width: "unset" }}
                          >
                            <h1 style={{ fontSize: "43px" }}>
                              {isArabic
                                ? homeText[6].value_ar
                                : homeText[6].value}
                            </h1>
                          </div>
                          <div className={classes.firstDivDesc}>
                            <p>{isArabic ? homeText[10].value_ar : homeText[10].value }</p>
                          </div>
                          <div className={classes.firstDivLink}>
                            <Link to="/our-youth">{t("Read more")}</Link>
                            <img src={readMoreArrow} alt="Blue arrow" />
                          </div>
                        </div>
                      </Col>
                      {homeSuccessStories.slice(0, 2).map((story, index) => {
                        return (
                          <Col key={story.id} lg={4}>
                            <StoryCards
                              id={story.id}
                              avatar={storageUrl + story.image}
                              title={isArabic ? story.title_ar : story.title}
                              desc={isArabic ? story.text_ar : story.text}
                              deviceType={"tablet"}
                            />
                          </Col>
                        );
                      })}
                    </Container>
                  </Row>
                </Container>
              </section>

              {/* Section 4 */}

              <section className={classes.indexBanner2}>
                <Container>
                  <Row>
                    <Col lg={12} md={12} style={{ padding: "50px" }}>
                      <div className={classes.bannerContentHolder}>
                        <div className={classes.bannerTitleHolder}>
                          <h1 className="text-white">
                            {isArabic
                              ? homeText[5].value_ar
                              : homeText[5].value}
                          </h1>
                        </div>
                        <Flex
                          row
                          className="banner-btns-holder align-items-center justify-content-center"
                        >
                          <div
                            className={classes.bannerLinkHolder}
                            style={{
                              marginRight: isArabic && 0,
                              marginLeft: isArabic && "30px",
                            }}
                          >
                            <Link to="/volunteer">{t("Get Involved")}</Link>
                          </div>
                          <div className="yellow-btn-holder">
                            <Button
                              className={`${classes.btnDonateYellow}`}
                              onClick={handleNavigation}
                              variant="link"
                            >
                              {t("Donate")}
                            </Button>
                          </div>
                        </Flex>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>

              {/* Section 5 */}
              <section className={classes.programsSection}>
                <Container className={classes.mainContainer}>
                  <Flex row justifySpaceBetween>
                    <div
                      className={classes.programsSectionTitle}
                      style={{ marginTop: "10px" }}
                    >
                      <p className={classes.ourProgramTitle}>
                        {isArabic ? homeText[4].value_ar : homeText[4].value}
                      </p>
                    </div>
                  </Flex>
                  <Container>
                    <Row
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      {homePrograms.map((program, index) => {
                        return (
                          <Col lg={3} md={6} key={program.id}>
                            <ProgramCard
                              thumb={storageUrl + program.thumb}
                              title={
                                isArabic ? program.title_ar : program.title
                              }
                              desc={
                                isArabic
                                  ? program.text_ar.slice(0, 250) + "..."
                                  : program.text.slice(0, 250) + "..."
                              }
                              deviceType={"tablet"}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Container>
                  <Flex row justifyEnd>
                  <div
                      className={classes.programsSectionRoute}
                      onMouseOver={handleMouseUp}
                      onMouseLeave={() => {
                        setChangeBlue(false);
                      }}
                      style={{
                        cursor: changeBlue && "pointer",
                        height: "20px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Link
                        className={classes.readMoreGray}
                        to="/our-programs"
                        style={{
                          color: changeBlue && "#0a58ca",
                          position: isArabic && "relative",
                          zIndex: isArabic && 3,
                        }}
                      >
                        {t("Read more")}
                      </Link>
                      {changeBlue ? (
                        <img src={blueArrow} alt="Blue arrow" />
                      ) : (
                        <img src={grayArrow} alt="Gray arrow" />
                      )}
                    </div>
                  </Flex>
                </Container>
              </section>

              {/* Section 6 */}
              <section
                className={`${classes.section6} container`}
                style={{ position: "relative" }}
              >
                <Flex>
                  <Container className={classes.invContainer}>
                    <div className={classes.section6Header}>
                    <p>{isArabic ? homeText[3].value_ar : homeText[3].value}</p>
                    </div>
                    <Row className="main-row">
                      <Col lg={4} md={12}>
                        <Link to={`news/${mainQueenInv.id}`}>
                          <div className={classes.mainCoverQueen}>
                            <img
                              src={storageUrl + mainQueenInv.thumb}
                              className={classes.mainQueenImage}
                              alt="queen Main"
                            />
                          </div>
                          <div
                            className={classes.mainFloatText}
                            style={{ margin: "0 auto", height:"70px" }}
                          >
                            <p>
                              {isArabic
                                ? mainQueenInv.title_ar.slice(0, 120) + "..."
                                : mainQueenInv.title.slice(0, 120) + "..."}
                            </p>
                          </div>
                        </Link>
                      </Col>
                      <Col lg={8} md={12}>
                        <Row>
                          {queenNews.map((item, index) => {
                            return (
                              <Col lg={4} md={6} className={classes.qNewsCol}>
                                <Link to={`news/${item.id}`}>
                                  <div className={classes.invThumb}>
                                    <img
                                      src={storageUrl + item.thumb}
                                      style={{
                                        margin: "0 auto",
                                        display: "block",
                                        height: "100%",
                                        minWidth: "100%",
                                      }}
                                      alt="inv Thumb"
                                    />
                                    <div
                                    className={classes.floatText}
                                    style={{height:"unset"}}
                                  >
                                    <p style={{padding:"7px"}}>
                                      {isArabic ? item.title_ar.slice(0, 120) + "..." : item.title.slice(0, 120) + "..."}
                                    </p>
                                  </div>
                                  </div>
                                </Link>
                              </Col>
                            );
                          })}
                          <Col
                            lg={4}
                            md={6}
                            className={classes.readMeYellow}
                            style={{ height: "100%", width: "50%" }}
                          >
                            <Link to="/queen-invlovment">
                              <Flex
                                column
                                alignItemsCenter
                                justifyCenter
                                className={classes.readMorefloatText}
                                style={{ height: "31vh" }}
                              >
                                <img src={balckArrow} alt="black Arrow" />
                                <p>{t("Read more")}</p>
                              </Flex>
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </Flex>
              </section>
              {/* Section 8 News Swiper */}
              <section className={`${classes.section8} container-fluid`}>
                <Container>
                  <Flex justifySpaceBetween alignItemsCenter>
                    <div className={classes.ssection8TitleHolder}>
                      <p>
                        {isArabic ? homeText[2].value_ar : homeText[2].value}
                      </p>
                    </div>
                    <div
                      className={classes.section8LinkHolder}
                      onMouseOver={() => {
                        setChangeYellow(true);
                      }}
                      onMouseLeave={() => {
                        setChangeYellow(false);
                      }}
                    >
                      <Link to="/news">
                        <Flex
                          alignItemsCenter
                          className={classes.section8ReadMoreHolder}
                        >
                          <p
                            className="mb-1"
                            style={{ color: changeYellow && "#FFC629" }}
                          >
                            {t("Read more")}
                          </p>
                          {changeYellow ? (
                            <img src={yellowArrow} alt="White Arrow" />
                          ) : (
                            <img src={whiteArrow} alt="White Arrow" />
                          )}
                        </Flex>
                      </Link>
                    </div>
                  </Flex>
                  <div
                    className={classes.swiperHolder}
                    style={{ paddingBottom: "61px" }}
                  >
                    <NewsSwiper />
                  </div>
                </Container>
              </section>

              {/* Section 9 */}
              <section className={classes.section9}>
                <Container>
                  <Flex className={classes.section9TitleHolder}>
                    <p>{isArabic ? homeText[7].value_ar : homeText[7].value}</p>
                  </Flex>
                  <div className={classes.sponorsSwiperHolder}>
                    <SponsorSwiper />
                  </div>
                </Container>
              </section>
            </>
          )}
        </MainLayout>
      </Tablet>

      {/* Mobile */}
      <Mobile>
      {showVideo && <CustomPlayer
      isMobile={true}
      link={homeText[11].value}
      showVideo={showVideo}
      setShowVideo={setShowVideo}/>}
      {isLoading && <Loader />}
        <MainLayout addPadding={false}>
          {!isLoading && (
            <>
              {/* Section 1 */}
              <section
                className={classes.indexSection1}
                style={{ background: "#f7feff" }}
              >
                <Flex>
                  <Container>
                    <Row className="mb-5">
                      <div
                        className={`${classes.section1TextHolder} d-flex flex-column align-items-center justify-content-center mt-5`}
                      >
                        <div className={`${classes.titleHolder} text-center`}>
                          <h1
                            className={classes.title}
                            style={{ fontSize: "36px" }}
                          >
                            {isArabic
                              ? homeText[0].value_ar
                              : homeText[0].value}
                            <span
                              className={classes.bluetext}
                              id="changing-words"
                            >
                              {/* <Typewriter
                                component='span'
                                options={{
                                  strings: isArabic
                                    ? homeText[9].value_ar.split(",")
                                    : homeText[9].value.split(","),
                                  autoStart: true,
                                  loop: true,
                                  delay:75,
                                  cursor:""
                                }}
                              /> */}
                              <Typewriter
            words={isArabic ? homeText[9].value_ar.split(",") : homeText[9].value.split(",")}
            loop={true}
            cursor
            cursorStyle='|'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          />
                            </span>{" "}
                            <br></br>
                            {isArabic
                              ? homeText[8].value_ar
                              : homeText[8].value}
                          </h1>
                        </div>
                        <div className={`${classes.descHolder} text-center`}>
                          <p>
                            {isArabic
                              ? homeText[1].value_ar
                              : homeText[1].value}
                          </p>
                        </div>
                        <div className="btnLinkHolder d-flex align-items-center">
                          <div
                            className={classes.linkHolder}
                            style={{
                              marginLeft: isArabic && "33px",
                              marginRight: isArabic && 0,
                            }}
                          >
                            <Link to="/volunteer">{t("Get Involved")}</Link>
                          </div>
                          <div className="donateBtnHolder">
                            <Button
                              className={`${classes.btnDonate}`}
                              onClick={handleNavigation}
                              variant="link"
                            >
                              {t("Donate")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row
                      className={classes.imagesRow}
                      style={{ position: "relative" }}
                    >
                      <Container>
                        <div
                          className={classes.section1imagesMainHolder}
                          style={{ paddingBottom: "130px" }}
                        >
                          <div className={classes.mainCover}>
                            <div
                              className={`${classes.playerIconHolder} d-flex align-items-center`}
                              onClick={handleVideoClick}
                            >
                              <img
                                className={classes.playIcon}
                                src={playerIcon}
                                alt="Video Player"
                              />
                              <img
                                className={classes.startIcon}
                                src={polygon}
                                alt="Start"
                              />
                            </div>
                            <img
                              className="img-fluid"
                              src={`${storageUrl + homeImages[0].image}`}
                              alt="Main cover"
                              style={{ zIndex: "5" }}
                            />
                          </div>
                          <div
                            className={classes.secondCover}
                            style={{ position: "relative" }}
                          >
                            <img
                              className={`${classes.secondImageRes} img-fluid`}
                              src={storageUrl + homeImages[1].image}
                              style={{
                                left: isArabic && 0,
                                right: isArabic && "30px",
                              }}
                              alt="Second cover"
                            />
                          </div>
                        </div>
                      </Container>
                    </Row>
                  </Container>
                </Flex>
              </section>

              {/* Section 2 */}
              <section
                className={`${classes.indexSection2} d-flex align-items-center w-100 mb-5`}
                style={{ background: "#2A7DE1" }}
              >
                <Container className={classes.mainContainer}>
                  <Row>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={homeCounters[0].value}
                        title={
                          isArabic
                            ? homeCounters[0].title_ar
                            : homeCounters[0].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[0].text_ar
                            : homeCounters[0].text
                        }
                      />
                    </Col>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={homeCounters[1].value}
                        title={
                          isArabic
                            ? homeCounters[1].title_ar
                            : homeCounters[1].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[1].text_ar
                            : homeCounters[1].text
                        }
                      />
                    </Col>
                    <Col lg={4} md={4}>
                      <BannerText
                        number={homeCounters[2].value}
                        title={
                          isArabic
                            ? homeCounters[2].title_ar
                            : homeCounters[2].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[2].text_ar
                            : homeCounters[2].text
                        }
                      />
                    </Col>
                  </Row>
                </Container>
              </section>

              {/* Section 3 */}
              <section className={classes.indexSection3}>
                <Container>
                  <Row className="mb-5">
                    <Col lg={4} md={4} style={{ marginTop: "20px" }}>
                      <OrphanStats
                        number={homeCounters[3].value}
                        title={
                          isArabic
                            ? homeCounters[3].title_ar
                            : homeCounters[3].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[3].text_ar
                            : homeCounters[3].text
                        }
                      />
                    </Col>
                    <Col lg={4} md={12} style={{ marginTop: "20px" }}>
                      <OrphanStats
                        number={homeCounters[4].value}
                        title={
                          isArabic
                            ? homeCounters[4].title_ar
                            : homeCounters[4].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[4].text_ar
                            : homeCounters[4].text
                        }
                      />
                    </Col>
                    <Col lg={4} md={4} style={{ marginTop: "20px" }}>
                      <OrphanStats
                        number={homeCounters[5].value}
                        title={
                          isArabic
                            ? homeCounters[5].title_ar
                            : homeCounters[5].title
                        }
                        desc={
                          isArabic
                            ? homeCounters[5].text_ar
                            : homeCounters[5].text
                        }
                      />
                    </Col>
                  </Row>
                  <Row className={classes.section3ImagesRow}>
                    <Container
                      fluid
                      className="d-flex"
                      style={{ flexDirection: "column" }}
                    >
                      <Col
                        lg={4}
                        md={12}
                        className="d-flex align-items-center justify-content-end"
                        style={{ flexDirection: "column" }}
                      >
                        <div
                          className={classes.imageTextDivHolder}
                          style={{ position: "relative" }}
                        >
                          <div
                            className={classes.firstDivTitle}
                            style={{ width: "auto" }}
                          >
                            <h1 style={{ fontSize: "28px" }}>
                              {isArabic
                                ? homeText[6].value_ar
                                : homeText[6].value}
                            </h1>
                          </div>
                          <div className={classes.firstDivDesc}>
                            <p>{isArabic ? homeText[10].value_ar : homeText[10].value }</p>
                          </div>
                          <div className={classes.firstDivLink}>
                            <Link to="/our-youth">{t("Read more")}</Link>
                            <img src={readMoreArrow} alt="Blue arrow" />
                          </div>
                        </div>
                      </Col>
                      {homeSuccessStories.slice(0, 2).map((story, index) => {
                        return (
                          <Col key={story.id} lg={4} md={12}>
                            <StoryCards
                              id={story.id}
                              avatar={storageUrl + story.image}
                              title={isArabic ? story.title_ar : story.title}
                              desc={isArabic ? story.text_ar : story.text}
                            />
                          </Col>
                        );
                      })}
                    </Container>
                  </Row>
                </Container>
              </section>

              {/* Section 4 */}

              <section
                className={classes.indexBanner2}
              >
                <Container>
                  <Row>
                    <Col lg={12} md={12} style={{ padding: "30px" }}>
                      <div className={classes.bannerContentHolder}>
                        <div className={classes.bannerTitleHolder}>
                          <h1
                            className="text-white"
                            style={{ fontSize: "28px" }}
                          >
                            {isArabic
                              ? homeText[5].value_ar
                              : homeText[5].value}
                          </h1>
                        </div>
                        <Flex
                          row
                          className="banner-btns-holder align-items-center banner-btns-holder justify-content-center"
                        >
                          <div
                            className={classes.bannerLinkHolder}
                            style={{
                              marginRight: isArabic && 0,
                              marginLeft: isArabic && "30px",
                            }}
                          >
                            <Link to="/volunteer">{t("Get Involved")}</Link>
                          </div>
                          <div className="yellow-btn-holder">
                            <Button
                              className={`${classes.btnDonateYellow}`}
                              onClick={handleNavigation}
                              variant="link"
                            >
                              {t("Donate")}
                            </Button>
                          </div>
                        </Flex>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>

              {/* Section 5 */}
              <section
                className={classes.programsSection}
                style={{ paddingTop: "37px" }}
              >
                <Container className={classes.mainContainer}>
                  <Flex row justifySpaceBetween alignItems={"first baseline"}>
                    <div className={classes.programsSectionTitle}>
                      <p
                        className={classes.ourProgramTitle}
                        style={{ fontSize: "28px" }}
                      >
                        {isArabic ? homeText[4].value_ar : homeText[4].value}
                      </p>
                    </div>
                    <div
                      className={classes.programsSectionRoute}
                      onMouseOver={handleMouseUp}
                      onMouseLeave={() => {
                        setChangeBlue(false);
                      }}
                      style={{
                        cursor: changeBlue && "pointer",
                        height: "20px",
                      }}
                    >
                      <Link
                        className={classes.readMoreGray}
                        to="/our-programs"
                        style={{
                          color: changeBlue && "#0a58ca",
                          position: isArabic && "relative",
                          zIndex: isArabic && 3,
                        }}
                      >
                        {t("Read more")}
                      </Link>
                      {changeBlue ? (
                        <img src={blueArrow} alt="Blue arrow" />
                      ) : (
                        <img src={grayArrow} alt="Gray arrow" />
                      )}
                    </div>
                  </Flex>
                  <Container>
                    <Row
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      {homePrograms.map((program, index) => {
                        return (
                          <Col
                            lg={3}
                            md={6}
                            key={program.id}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "15px",
                            }}
                          >
                            <ProgramCard
                              thumb={storageUrl + program.thumb}
                              title={
                                isArabic ? program.title_ar : program.title
                              }
                              desc={
                                isArabic
                                  ? program.text_ar.slice(0, 250) + "..."
                                  : program.text.slice(0, 250) + "..."
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Container>
                </Container>
              </section>

              {/* Section 6 */}
              <section
                className={`${classes.section6} container`}
                style={{ position: "relative", paddingBottom: "25px" }}
              >
                <Flex>
                  <Container className={classes.invContainer}>
                    <div className={classes.section6Header}>
                    <p>{isArabic ? homeText[3].value_ar : homeText[3].value}</p>
                    </div>
                    <Row className="main-row">
                      <Col lg={4} md={12}>
                        <Link to={`/news/${mainQueenInv.id}`}>
                          <div className={classes.mainCoverQueen}>
                            <img
                              src={storageUrl + mainQueenInv.thumb}
                              className={classes.mainQueenImage}
                              alt="queen Main"
                            />
                          </div>
                          <div
                            className={classes.mainFloatText}
                            // style={{
                            // }}
                          >
                            <p style={{padding: isArabic && "7px"}}>
                              {isArabic
                                ? mainQueenInv.title_ar.slice(0, 120) + "..."
                                : mainQueenInv.title.slice(0, 120) + "..."}
                            </p>
                          </div>
                        </Link>
                      </Col>
                      <Col lg={8} md={6}>
                        <Row>
                          {queenNews.map((item, index) => {
                            return (
                              <Col lg={4} md={12} style={{ height: "280px", display:"flex" ,
                              alignItems:"center", justifyContent:"center"}}>
                                <Link to={`news/${item.id}`}>
                                  <div
                                    className={classes.invThumb}
                                  >
                                    <img
                                      src={storageUrl + item.thumb}
                                      alt="inv Thumb"
                                    />
                                                                      <div
                                    className={classes.floatText}
                                    style={{
                                      position:"absolute",
                                      height:"38%",
                                      bottom:"0"
                                    }}
                                  >
                                    <p style={{fontSize:"13px", padding:"5px", display:"block"}}>
                                      {isArabic ? item.title_ar.slice(0, 120) + "..." : item.title.slice(0, 120) + "..."}
                                    </p>
                                  </div>
                                  </div>
                                </Link>
                              </Col>
                            );
                          })}
                          <Col
                            lg={4}
                            md={12}
                            className={classes.readMeYellow}
                            style={{ margin: "0 auto", marginBottom: "25px" }}
                          >
                            <Link to="/queen-invlovment">
                              <Flex
                                column
                                alignItemsCenter
                                justifyCenter
                                className={classes.readMorefloatText}
                              >
                                <img src={balckArrow} alt="black Arrow" />
                                <p>{t("Read more")}</p>
                              </Flex>
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </Flex>
              </section>
              {/* Section 8 News Swiper */}
              <section
                className={`${classes.section8} container-fluid`}
                style={{ paddingTop: "35px" }}
              >
                <Container>
                  <Flex justifySpaceBetween alignItemsCenter>
                    <div className={classes.ssection8TitleHolder}>
                      <p>
                        {isArabic ? homeText[2].value_ar : homeText[2].value}
                      </p>
                    </div>
                    <div
                      className={classes.section8LinkHolder}
                      onMouseOver={() => {
                        setChangeYellow(true);
                      }}
                      onMouseLeave={() => {
                        setChangeYellow(false);
                      }}
                    >
                      <Link to="/news">
                        <Flex
                          alignItemsCenter
                          className={classes.section8ReadMoreHolder}
                        >
                          <p
                            className="mb-1"
                            style={{ color: changeYellow && "#FFC629" }}
                          >
                            {t("Read more")}
                          </p>
                          {changeYellow ? (
                            <img src={yellowArrow} alt="White Arrow" />
                          ) : (
                            <img src={whiteArrow} alt="White Arrow" />
                          )}
                        </Flex>
                      </Link>
                    </div>
                  </Flex>
                  <div
                    className={classes.swiperHolder}
                    style={{ paddingBottom: "61px" }}
                  >
                    <NewsSwiper />
                  </div>
                </Container>
              </section>

              {/* Section 9 */}
              <section
                className={classes.section9}
                style={{ padding: "41px 15px" }}
              >
                <Container>
                  <Flex className={classes.section9TitleHolder}>
                    <p style={{ fontSize: "28px" }}>
                      {isArabic ? homeText[7].value_ar : homeText[7].value}
                    </p>
                  </Flex>
                  <div className={classes.sponorsSwiperHolder}>
                    <SponsorSwiper />
                  </div>
                </Container>
              </section>
            </>
          )}
        </MainLayout>
      </Mobile>
    </>
  );
};

export default withTranslation()(Index);
