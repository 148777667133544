import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import MainLayout from "../../layouts/MainLayout";
import invCard1 from "../../assets/images/involvement-media/inv-card1.png";
import invCard2 from "../../assets/images/involvement-media/inv-card2.png";
import invCard3 from "../../assets/images/involvement-media/inv-card3.png";
import invCard4 from "../../assets/images/involvement-media/inv-card4.png";
import invCard5 from "../../assets/images/involvement-media/inv-card5.png";
import invCard6 from "../../assets/images/involvement-media/inv-card6.png";
import classes from "./Invlolvement.module.scss";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { isArabic, storageUrl, baseUrl } from "../../helpers/helpersFunctions";
import { Desktop, Mobile, Tablet } from "../../helpers/BreakPoints";
import Loader from "../../components/loader/Loader";

const Invlovement = ({ t }) => {
  const [queenInvs, setQueenInvs] = useState([]);
  const [pageTitle, setPageTitle] = useState([]);
  const [isLoading, setIsLoading] = useState(true);



  const fetchTitle = async () => {
    await fetch(`${baseUrl}/GetTexts/queen-ranias-involvement`)
      .then((res) => res.json())
      .then(
        (data) => {
          setPageTitle(data.Result);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          console.log("something went wrong fetching fetchTitle =>", error);
        }
      );

    return;
  };




  const fetchQueenInvs = async () => {
    await fetch(`${baseUrl}/GetNews/3`)
      .then((res) => res.json())
      .then(
        (news) => {
          setQueenInvs(news.Result.data);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          console.log("something went wrong fetching fetchQueenInvs =>", error);
        }
      );

    return;
  };

  useEffect(() => {
    document.title = t("Queen's Involvements");
    fetchQueenInvs();
    fetchTitle();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Desktop>
            <MainLayout addPadding={false}>
              <Container>
                <div className={classes.InvlovementTitleHolder}>
                  <h1 className={`${classes.mainTitle} text-center`}>
                    {isArabic ? pageTitle[0].value_ar : pageTitle[0].value }
                  </h1>
                </div>
                <Row>
                  {queenInvs.map((inv, index) => {
                    return (
                      <Col
                        lg={4}
                        md={4}
                        className={classes.invCol}
                        key={inv.id}
                      >
                        <Link
                          className={classes.mainLink}
                          to={`/news/${inv.id}`}
                        >
                          <div className={classes.imageCoverHolder}>
                            <img
                              src={storageUrl + inv.thumb}
                              className="img-fluid w-100"
                              alt="cover inv 1"
                            />
                            <div
                              key={inv.id * Math.random()}
                              className={classes.invCoverTitle}
                            >
                              <p>{isArabic ? inv.title_ar.slice(0, 120) + "..." : inv.title.slice(0, 120 + "...")}</p>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </MainLayout>
          </Desktop>

          {/* Tablet */}
          <Tablet>
            <MainLayout addPadding={false}>
              <Container>
                <div className={classes.InvlovementTitleHolder}>
                  <h1
                    style={{ margin: 0, padding: "45px 0" }}
                    className={`${classes.mainTitle} text-center`}
                  >
                    {isArabic ? pageTitle[0].value_ar : pageTitle[0].value }
                  </h1>
                </div>
                <Row>
                  {queenInvs.map((inv, index) => {
                    return (
                      <Col
                        lg={4}
                        md={4}
                        className={classes.invCol}
                        key={inv.id}
                      >
                        <Link
                          className={classes.mainLink}
                          to={`/news/${inv.id}`}
                        >
                          <div className={classes.imageCoverHolder}>
                            <img
                              src={storageUrl + inv.thumb}
                              className="img-fluid w-100"
                              alt="cover inv 1"
                              style={{height:"20vh"}}
                            />
                            <div
                              key={inv.id * Math.random()}
                              className={classes.invCoverTitle}
                              style={{ width: "auto" }}
                            >
                              <p style={{ fontSize: "14px" }}>
                                {isArabic ? inv.title_ar.slice(0, 120) + "..." : inv.title.slice(0, 120) + "..."}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </MainLayout>
          </Tablet>

          {/* Mobile */}
          <Mobile>
            <MainLayout addPadding={false}>
              <Container>
                <div className={classes.InvlovementTitleHolder}>
                  <h1 className={`${classes.mainTitle} text-center`}>
                  {isArabic ? pageTitle[0].value_ar : pageTitle[0].value }
                  </h1>
                </div>
                <Row>
                  {queenInvs.map((inv, index) => {
                    return (
                      <Col
                        lg={4}
                        md={4}
                        className={classes.invCol}
                        key={inv.id}
                      >
                        <Link
                          className={classes.mainLink}
                          to={`/news/${inv.id}`}
                        >
                          <div className={classes.imageCoverHolder}>
                            <img
                              src={storageUrl + inv.thumb}
                              className="img-fluid w-100"
                              alt="cover inv 1"
                              // style={{height:"20vh"}}
                            />
                            <div
                              key={inv.id * Math.random()}
                              className={classes.invCoverTitle}
                            >
                              <p>{isArabic ? inv.title_ar.slice(0, 120) + "..." : inv.title.slice(0, 120) + "..." }</p>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </MainLayout>
          </Mobile>
        </>
      )}
    </>
  );
};

export default withTranslation()(Invlovement);
