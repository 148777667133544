export default class ContactServices {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    /**
     *
     * @param {*} setLeftSideData
     * @returns
     */
    async fetchContactData(setContactData) {
        await fetch(`${this.baseUrl}/GetTexts/contact`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setContactData(data.Result);
                },
                (error) => {
                    console.log("something went wrong fetching Volunteer Left Side Data =>", error);
                }
            );

        return;
    }


    /**
     *
     * @param {*} setIsLoading
     * @param {*} setLeftSideData
     */

    async getContactFormData(setIsLoading, setContactData) {
        try {
            const response = await Promise.allSettled([
                this.fetchContactData(setContactData),
            ]);
            setIsLoading(false);
        } catch {
            console.log("something went wrong in getLeftSideFormData");
            setIsLoading(false);
        }
    }
}