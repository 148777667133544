import React from "react";
import "./FormInput.module.scss";
import { withTranslation } from "react-i18next";

const FormInput = ({
  type,
  label,
  required,
  id,
  formRef,
  t,
  value,
  onChange,
  maxLength=null
}) => {
  return (
    <>
      <div className="input-form-holder mb-3">
        <label htmlFor={id} className="form-label">
          {label}
          {required && t(" (required)")}
        </label>
        {type === "textarea" ? (
          <textarea ref={formRef} className="form-control" id={id} rows="7" />
        ) : (
          <input
            type={type}
            id={id}
            ref={formRef}
            value={value}
            onChange={onChange}
            name={label.replace(" ", "-").toLowerCase()}
            className="form-control alaman-input"
            required={required && true}
            maxLength={maxLength && maxLength }
          />
        )}
      </div>
    </>
  );
};

export default withTranslation()(FormInput);
