import React from 'react';
import classes from  "./CustomRadio.module.scss";
import { withTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useState } from 'react';

// @TODO check the radio buttons.


const CustomRadio = ({id, value, isChecked, onClick, t}) => {
  const [amount, setAmount] = useState(0);

  const handleOnClick = (e) => {
    let btnValue = e.currentTarget.getAttribute("data-value");
    setAmount(btnValue);
  }

  return (
    <>
    <Button variant='none'
     className={`btn ${isChecked ? classes.btnPaymentChecked : classes.btnPayment}`}
     onClick={onClick}
     data-value={value}
     value={amount}
     id={id}>{value} {t("JOD")} </Button>
    </>
  )
}

export default withTranslation()(CustomRadio);