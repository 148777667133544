import React, {useState,useEffect, forwardRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import MainLayout from "../../layouts/MainLayout";
import classes from "./About.module.scss";
import Box from "../../components/about-boxes/Box";
import Flex from "@react-css/flex";
import AboutServices from "./AboutService";
import { baseUrl, storageUrl, isArabic } from "../../helpers/helpersFunctions";
import { Desktop, Mobile, Tablet } from "../../helpers/BreakPoints";
import Loader from "../../components/loader/Loader";

const About = ({ t }) => {

const [aboutData, setAboutData] = useState([]);
const [aboutImages, setAboutImages] = useState([]);
const [isLoading, setIsLoading] = useState(true);
const aboutServices = new AboutServices(baseUrl);

  useEffect(() => {
    document.title= t("About");
    aboutServices.getAllData(setIsLoading, setAboutData, setAboutImages);
  }, []);

  function nl2br (str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
          return '';
      }
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  }



  return (
    <>
    { isLoading ? <Loader/> :
    <>
    {/* Desktop */}
    <Desktop>
      <MainLayout addPadding={false} whiteBg={true}>
        <section className={classes.section1} id="overview">
          <Container>
            <Row>
              <Col lg={6} md={6} className={classes.aboutSection1LeftCol}>
                <div className={classes.aboutTitleHolder}>
                  <h1>{isArabic ? aboutData[0].value_ar : aboutData[0].value }</h1>
                </div>
                <div className={classes.aboutSection1Desc}>
                  <pre>{isArabic ? aboutData[1].value_ar : aboutData[1].value }</pre>
                </div>
              </Col>
              <Col lg={6} md={6} className={classes.aboutSection1RigtCol}>
                <div className={classes.aboutRightSideImageHolder}>
                  <img
                    className="img-fluid"
                    src={storageUrl + aboutImages[5].image}
                    alt="Queen Eating"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Section 2 */}
        <section className={classes.aboutSection2} style={{paddingTop:"90px"}} id="getToKnowUs">
          <Container>
            <div className={classes.aboutSection2TitleHolder}>
                  <h1>{t("Get to know us")}</h1>
            </div>
            <Row className={classes.aboutSection2Row}>
                <Col lg={3} md={6}>
                      <Box
                        title={isArabic ? aboutData[2].value_ar : aboutData[2].value }
                        icon={storageUrl + aboutImages[0].image}
                        desc={isArabic ? aboutData[3].value_ar : aboutData[3].value }
                        color={"yellow"}
                        className={classes.yellowBox}
                      />
                  </Col>
                  <Col lg={5} md={6}>
                    <Box
                        title={isArabic ? aboutData[4].value_ar : aboutData[4].value }
                        icon={storageUrl + aboutImages[1].image}
                        desc={isArabic ? aboutData[5].value_ar : aboutData[5].value }
                        color={"blue"}
                      />
                  </Col>
                    <Col lg={4} md={6}>
                    <Box
                      title={isArabic ? aboutData[6].value_ar : aboutData[6].value }
                      icon={storageUrl + aboutImages[2].image}
                      desc={isArabic ? aboutData[7].value_ar.split(",") : aboutData[7].value.split(",") }
                      color={"red"}
                      isList={true}
                    />
                    </Col>
                  </Row>
          </Container>
        </section>

        {/* Section 3 */}
        <section className={classes.aboutSection3} id="governance">
          <div className={classes.section3TitleHolder}>
              <h1>{isArabic ? aboutData[8].value_ar : aboutData[8].value }</h1>
          </div>
          <Container>
            <Row>
              <Col lg={6} md={6}>
                <div className={classes.colTitleHolder}>
                    <h1>{isArabic ? aboutData[9].value_ar : aboutData[9].value }</h1>
                </div>
                <div className={classes.colDescHolder}>
                  <p>
                  {isArabic ? aboutData[10].value_ar : aboutData[10].value }
                  </p>
                </div>
              </Col>
              <Col lg={6} md={6} className={classes.section3RightSideCol}>
              <div className={classes.colTitleHolder}>
                    <h1>{isArabic ? aboutData[11].value_ar : aboutData[11].value }</h1>
                </div>
                <div className={classes.colDescHolder}>
                  <p>
                  {isArabic ? aboutData[12].value_ar : aboutData[12].value }
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Section */}
        <section className={classes.aboutSection4} id="stratigicPartners">
          <div className={classes.section4TitleHolder}>
              <h1>{isArabic ? aboutData[13].value_ar : aboutData[13].value }</h1>
          </div>
          <Container>
            <Row>
            <Col lg={6} md={6}>
              <div className={classes.section4DescHolder}>
                  <p>{isArabic ? aboutData[14].value_ar : aboutData[14].value }</p>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className={classes.section4DescHolder}>
                  <p>{isArabic ? aboutData[15].value_ar : aboutData[15].value }</p>
              </div>
            </Col>
            </Row>
          </Container>
        </section>

        {/* Section 5 */}
        <section className={classes.aboutSection5}>
          <Container>
          <Row>
            <Col lg={3} md={6}>
              <div className={classes.section5TitleHolder}>
                    <h1>{isArabic ? aboutData[16].value_ar : aboutData[16].value }</h1>
              </div>
            </Col>
            <Col lg={4} md={6}>
            <Flex column className={classes.aboutContentHolder}>
                  <div className={classes.iconHolder}>
                    <img src={storageUrl + aboutImages[3].image} alt="Home"/>
                  </div>
                  <div className={classes.aboutContentTitleHolder}>
                        <h1>{isArabic ? aboutData[17].value_ar : aboutData[17].value }</h1>
                  </div>
                  <div className={classes.aboutContentDescHolder}>
                      <p>{isArabic ? aboutData[18].value_ar : aboutData[18].value }</p>
                  </div>
              </Flex>
            </Col>
            <Col lg={5} md={6}>
            <Flex column className={classes.aboutContentHolder}>
                  <div className={classes.iconHolder}>
                    <img src={storageUrl + aboutImages[4].image} alt="Hand with heart"/>
                  </div>
                  <div className={classes.aboutContentTitleHolder}>
                        <h1>{isArabic ? aboutData[19].value_ar : aboutData[19].value }</h1>
                  </div>
                  <div className={classes.aboutContentDescHolder} style={{width:"470px"}}>
                      <p>{isArabic ? aboutData[20].value_ar : aboutData[20].value }</p>
                  </div>
              </Flex>
            </Col>
          </Row>
          </Container>
        </section>
      </MainLayout>
      </Desktop>

    {/* Tablet */}
    <Tablet>
    <MainLayout addPadding={false} whiteBg={true}>
        <section className={classes.section1} id="overview">
          <Container>
            <Row>
              <Col lg={6} md={6} className={classes.aboutSection1LeftCol}>
                <div className={classes.aboutTitleHolder}>
                  <h1>{isArabic ? aboutData[0].value_ar : aboutData[0].value }</h1>
                </div>
                <div className={classes.aboutSection1Desc} style={{width:"auto"}}>
                  <pre>{isArabic ? aboutData[1].value_ar : aboutData[1].value }</pre>
                </div>
              </Col>
              <Col lg={6} md={6} className={classes.aboutSection1RigtCol}>
                <div className={classes.aboutRightSideImageHolder}>
                  <img
                    className="img-fluid"
                    src={storageUrl + aboutImages[5].image}
                    alt="Queen Eating"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Section 2 */}
        <section className={classes.aboutSection2} style={{paddingTop:"90px"}} id="getToKnowUs">
        <Container>
            <div className={classes.aboutSection2TitleHolder}>
                  <h1>{t("Get to know us")}</h1>
            </div>
            <Row className={classes.aboutSection2Row}>
                <Col lg={3} md={4}>
                      <Box
                        title={isArabic ? aboutData[2].value_ar : aboutData[2].value }
                        icon={storageUrl + aboutImages[0].image}
                        desc={isArabic ? aboutData[3].value_ar : aboutData[3].value }
                        color={"yellow"}
                        className={classes.yellowBox}
                      />
                  </Col>
                  <Col lg={5} md={4}>
                    <Box
                        title={isArabic ? aboutData[4].value_ar : aboutData[4].value }
                        icon={storageUrl + aboutImages[1].image}
                        desc={isArabic ? aboutData[5].value_ar : aboutData[5].value }
                        color={"blue"}
                      />
                  </Col>
                    <Col lg={4} md={4}>
                    <Box
                      title={isArabic ? aboutData[6].value_ar : aboutData[6].value }
                      icon={storageUrl + aboutImages[2].image}
                      desc={isArabic ? aboutData[7].value_ar.split(",") : aboutData[7].value.split(",") }
                      color={"red"}
                      isList={true}
                    />
                    </Col>
                  </Row>
        </Container>
        </section>

        {/* Section 3 */}
        <section className={classes.aboutSection3} id="governance">
          <div className={classes.section3TitleHolder}>
              <h1>{isArabic ? aboutData[8].value_ar : aboutData[8].value }</h1>
          </div>
          <Container>
            <Row>
              <Col lg={6} md={6}>
                <div className={classes.colTitleHolder}>
                    <h1 style={{fontSize:"19px"}}>{isArabic ? aboutData[9].value_ar : aboutData[9].value }</h1>
                </div>
                <div className={classes.colDescHolder}>
                  <p>
                  {isArabic ? aboutData[10].value_ar : aboutData[10].value }
                  </p>
                </div>
              </Col>
              <Col lg={6} md={6} className={classes.section3RightSideCol}>
              <div className={classes.colTitleHolder}>
                    <h1>{isArabic ? aboutData[11].value_ar : aboutData[11].value }</h1>
                </div>
                <div className={classes.colDescHolder}>
                  <p>
                  {isArabic ? aboutData[12].value_ar : aboutData[12].value }
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Section */}
        <section className={classes.aboutSection4} id="stratigicPartners">
          <div className={classes.section4TitleHolder}>
              <h1 style={{fontSize:"36px"}}>{isArabic ? aboutData[13].value_ar : aboutData[13].value }</h1>
          </div>
          <Container>
            <Row>
            <Col lg={6} md={6}>
              <div className={classes.section4DescHolder}>
                  <p>{isArabic ? aboutData[14].value_ar : aboutData[14].value }</p>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className={classes.section4DescHolder}>
                  <p>{isArabic ? aboutData[15].value_ar : aboutData[15].value }</p>
              </div>
            </Col>
            </Row>
          </Container>
        </section>

        {/* Section 5 */}
        <section className={classes.aboutSection5}>
          <Container fluid>
          <Row>
            <Col lg={3} md={12}>
              <div className={classes.section5TitleHolder}>
                    <h1>{isArabic ? aboutData[16].value_ar : aboutData[16].value }</h1>
              </div>
            </Col>
            <Col lg={4} md={12}>
            <Flex column className={classes.aboutContentHolder} alignItems={'center'} style={{textAlign:"center"}}>
                  <div className={classes.iconHolder}>
                    <img src={storageUrl + aboutImages[3].image} alt="Home"/>
                  </div>
                  <div className={classes.aboutContentTitleHolder}>
                        <h1>{isArabic ? aboutData[17].value_ar : aboutData[17].value }</h1>
                  </div>
                  <div className={classes.aboutContentDescHolder}>
                      <p>{isArabic ? aboutData[18].value_ar : aboutData[18].value }</p>
                  </div>
              </Flex>
            </Col>
            <Col lg={5} md={12}>
            <Flex column className={classes.aboutContentHolder} alignItems={'center'} style={{textAlign:"center"}}>
                  <div className={classes.iconHolder}>
                    <img src={storageUrl + aboutImages[4].image} alt="Hand with heart"/>
                  </div>
                  <div className={classes.aboutContentTitleHolder}>
                        <h1>{isArabic ? aboutData[19].value_ar : aboutData[19].value }</h1>
                  </div>
                  <div className={classes.aboutContentDescHolder}>
                      <p>{isArabic ? aboutData[20].value_ar : aboutData[20].value }</p>
                  </div>
              </Flex>
            </Col>
          </Row>
          </Container>
        </section>
    </MainLayout>
    </Tablet>

    {/* Mobile */}
    <Mobile>
    <MainLayout addPadding={false} whiteBg={true}>
        <section className={classes.section1} id="overview">
          <Container>
            <Row>
              <Col lg={6} md={6} className={classes.aboutSection1LeftCol}>
                <div className={classes.aboutTitleHolder}>
                  <h1>{isArabic ? aboutData[0].value_ar : aboutData[0].value }</h1>
                </div>
                <div className={classes.aboutSection1Desc} style={{width:"auto"}}>
                  <pre>{isArabic ? aboutData[1].value_ar : aboutData[1].value }</pre>
                </div>
              </Col>
              <Col lg={6} md={6} className={classes.aboutSection1RigtCol}>
                <div className={classes.aboutRightSideImageHolder}>
                  <img
                    className="img-fluid"
                    src={storageUrl + aboutImages[5].image}
                    alt="Queen Eating"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Section 2 */}
        <section className={classes.aboutSection2} style={{paddingTop:"90px"}} id="getToKnowUs">
        <Container>
            <div className={classes.aboutSection2TitleHolder}>
                  <h1 style={{fontSize:"49px"}}>{t("Get to know us")}</h1>
            </div>
            <Row className={classes.aboutSection2Row}>
                <Col lg={3} md={6} className="mb-3">
                      <Box
                        title={isArabic ? aboutData[2].value_ar : aboutData[2].value }
                        icon={storageUrl + aboutImages[0].image}
                        desc={isArabic ? aboutData[3].value_ar : aboutData[3].value }
                        color={"yellow"}
                        className={classes.yellowBox}
                      />
                  </Col>
                  <Col lg={5} md={6} className="mb-3">
                    <Box
                        title={isArabic ? aboutData[4].value_ar : aboutData[4].value }
                        icon={storageUrl + aboutImages[1].image}
                        desc={isArabic ? aboutData[5].value_ar : aboutData[5].value }
                        color={"blue"}
                      />
                  </Col>
                    <Col lg={4} md={6} className="mb-3">
                    <Box
                      title={isArabic ? aboutData[6].value_ar : aboutData[6].value }
                      icon={storageUrl + aboutImages[2].image}
                      desc={isArabic ? aboutData[7].value_ar.split(",") : aboutData[7].value.split(",") }
                      color={"red"}
                      isList={true}
                    />
                    </Col>
                  </Row>
          </Container>
        </section>

        {/* Section 3 */}
        <section className={classes.aboutSection3} id="governance">
          <div className={classes.section3TitleHolder}>
              <h1>{isArabic ? aboutData[8].value_ar : aboutData[8].value }</h1>
          </div>
          <Container>
            <Row>
              <Col lg={6} md={6} style={{textAlign:"center", marginBottom:"75px"}}>
                <div className={classes.colTitleHolder}>
                    <h1 style={{fontSize:"22px"}}>{isArabic ? aboutData[9].value_ar : aboutData[9].value }</h1>
                </div>
                <div className={classes.colDescHolder}>
                  <p>
                  {isArabic ? aboutData[10].value_ar : aboutData[10].value }
                  </p>
                </div>
              </Col>
              <Col lg={6} md={6} className={classes.section3RightSideCol}  style={{textAlign:"center"}}>
              <div className={classes.colTitleHolder}>
                    <h1>{isArabic ? aboutData[11].value_ar : aboutData[11].value }</h1>
                </div>
                <div className={classes.colDescHolder}>
                  <p>
                  {isArabic ? aboutData[12].value_ar : aboutData[12].value }
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Section */}
        <section className={classes.aboutSection4} id="stratigicPartners">
          <div className={classes.section4TitleHolder}>
              <h1 style={{fontSize:"22px"}}>{isArabic ? aboutData[13].value_ar : aboutData[13].value }</h1>
          </div>
          <Container>
            <Row>
            <Col lg={6} md={6} style={{textAlign:"center"}}>
              <div className={classes.section4DescHolder}>
                  <p>{isArabic ? aboutData[14].value_ar : aboutData[14].value }</p>
              </div>
            </Col>
            <Col lg={6} md={6} style={{textAlign:"center"}}>
              <div className={classes.section4DescHolder}>
                  <p>{isArabic ? aboutData[15].value_ar : aboutData[15].value }</p>
              </div>
            </Col>
            </Row>
          </Container>
        </section>

        {/* Section 5 */}
        <section className={classes.aboutSection5} style={{marginBottom:0}}>
          <Container >
          <Row>
            <Col lg={3} md={6}>
              <div className={classes.section5TitleHolder} >
                    <h1 style={{fontSize:"28px"}}>{isArabic ? aboutData[16].value_ar : aboutData[16].value }</h1>
              </div>
            </Col>
            <Col lg={4} md={6}>
            <Flex column className={classes.aboutContentHolder} alignItems={"center"} style={{textAlign:"center"}}>
                  <div className={classes.iconHolder}>
                    <img src={storageUrl + aboutImages[3].image} alt="Home"/>
                  </div>
                  <div className={classes.aboutContentTitleHolder}>
                        <h1>{isArabic ? aboutData[17].value_ar : aboutData[17].value }</h1>
                  </div>
                  <div className={classes.aboutContentDescHolder}>
                      <p>{isArabic ? aboutData[18].value_ar : aboutData[18].value }</p>
                  </div>
              </Flex>
            </Col>
            <Col lg={5} md={6}>
            <Flex column className={classes.aboutContentHolder} alignItems={"center"} style={{textAlign:"center"}}>
                  <div className={classes.iconHolder}>
                    <img src={storageUrl + aboutImages[4].image} alt="Hand with heart"/>
                  </div>
                  <div className={classes.aboutContentTitleHolder}>
                        <h1>{isArabic ? aboutData[19].value_ar : aboutData[19].value }</h1>
                  </div>
                  <div className={classes.aboutContentDescHolder}>
                      <p>{isArabic ? aboutData[20].value_ar : aboutData[20].value }</p>
                  </div>
              </Flex>
            </Col>
          </Row>
          </Container>
        </section>
    </MainLayout>
    </Mobile>

    </>
  }
  </>
  );
};

export default withTranslation()(About);
