import React from "react";
import classes from "./EmployAnOrphan.module.scss";
import { withTranslation } from "react-i18next";
import MainLayout from "../../layouts/MainLayout";
import { Col, Container, Form, Row } from "react-bootstrap";
import LeftSide from "../../components/form-left-side/LeftSide";
import FormHeader from "../../components/form-header-text/FormHeader";
import Flex from "@react-css/flex";
import FormInput from "../../components/form-input/FormInput";
import { useEffect, useState } from "react";
import OrphanServices from "./OrphanServices";
import { baseUrl, isArabic } from "../../helpers/helpersFunctions";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Desktop, Mobile, Tablet } from "../../helpers/BreakPoints";
import Loader from "../../components/loader/Loader";

const EmployAnOrphan = ({ t }) => {
  const [orphanLeftSideData, setOrphanLeftSideData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [leftSideImage, setLeftSideImage] = useState([]);
  const orphServices = new OrphanServices(baseUrl);
  const [showAlert, setShowAlert] = useState(false);
  const [passed, setPassed] = useState(null);
  const navigate = useNavigate();

  // ref data
  const companyName = React.createRef();
  const mobile = React.createRef();
  const email = React.createRef();
  const vacancy = React.createRef();
  const jobRequirements = React.createRef();

  useEffect(() => {
    document.title = t("Employ An Orphan");
    orphServices.getLeftSideFormData(setIsLoading, setOrphanLeftSideData, setLeftSideImage);
  }, []);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      // Adding data to formData
      formData.append("company_name", companyName.current.value);
      formData.append("phone", mobile.current.value);
      formData.append("email", email.current.value);
      formData.append("vacancy", vacancy.current.value);
      formData.append("job_requirements", jobRequirements.current.value);

      const response = await axios({
        method: "post",
        url: `${baseUrl}/EmployAnOrphan`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        setPassed(true);
        setShowAlert(true);
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setPassed(false);
        showAlert(true);
      }
    } catch (err) {
      setPassed(false);
      setShowAlert(true);
      throw err;
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* Desktop */}
          <Desktop>
            <MainLayout addPadding={false}>
              <Container fluid className={classes.formContainer}>
                <Row>
                  <Col lg={4} md={6} style={{ paddingLeft: "50px" }}>
                    <LeftSide
                    image={leftSideImage[0].image}
                      bodyTitle={
                        isArabic
                          ? orphanLeftSideData[0].value_ar
                          : orphanLeftSideData[0].value
                      }
                      bodyDesc={
                        isArabic
                          ? orphanLeftSideData[1].value_ar
                          : orphanLeftSideData[1].value
                      }
                    />
                  </Col>
                  <Col lg={8} md={6}>
                    <Container>
                      <Flex column className={classes.formFieldsHolder}>
                        <FormHeader
                          mainTitle={
                            isArabic
                              ? orphanLeftSideData[4].value_ar
                              : orphanLeftSideData[4].value
                          }
                          subTitle={
                            isArabic
                              ? orphanLeftSideData[3].value_ar
                              : orphanLeftSideData[3].value
                          }
                        />
                        <Form
                          method="POST"
                          className={classes.mainForm}
                          encType="multipart/form-data"
                          onSubmit={handleOnSubmit}
                        >
                          <Row className={classes.formFieldsRowContainer}>
                            <div className={classes.formToptext}>
                              <p>{t("Personal Info")}</p>
                            </div>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("Compay Name")}
                                required={true}
                                id={"company-name"}
                                formRef={companyName}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("Mobile")}
                                required={true}
                                id={"mobile"}
                                formRef={mobile}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"email"}
                                label={t("Email")}
                                required={true}
                                id={"email"}
                                formRef={email}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("Vacancy")}
                                id={"vacancy"}
                                formRef={vacancy}
                              />
                            </Col>
                            <Col lg={10} md={12}>
                              <FormInput
                                type={"textarea"}
                                label={t("Job Requirements")}
                                id={"job-requirements"}
                                formRef={jobRequirements}
                              />
                            </Col>
                            <div className={classes.btnSubmitHolder}>
                              <button
                                className={`btn ${classes.btnSend}`}
                                type="submit"
                              >
                                {t("Send")}
                              </button>
                            </div>
                          </Row>
                        </Form>
                      </Flex>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </MainLayout>
          </Desktop>

          {/* Tablet */}
          <Tablet>
            <MainLayout addPadding={false}>
              <Container fluid className={classes.formContainer}>
                <Row>
                  <Col lg={4} md={6}>
                    <LeftSide
                    image={leftSideImage[0].image}
                      bodyTitle={
                        isArabic
                          ? orphanLeftSideData[0].value_ar
                          : orphanLeftSideData[0].value
                      }
                      bodyDesc={
                        isArabic
                          ? orphanLeftSideData[1].value_ar
                          : orphanLeftSideData[1].value
                      }
                    />
                  </Col>
                  <Col lg={8} md={6}>
                    <Container>
                      <Flex column className={classes.formFieldsHolder}>
                        <FormHeader
                          mainTitle={
                            isArabic
                              ? orphanLeftSideData[4].value_ar
                              : orphanLeftSideData[4].value
                          }
                          subTitle={
                            isArabic
                              ? orphanLeftSideData[3].value_ar
                              : orphanLeftSideData[3].value
                          }
                        />
                        <Form
                          method="POST"
                          className={classes.mainForm}
                          encType="multipart/form-data"
                          onSubmit={handleOnSubmit}
                        >
                          <Row className={classes.formFieldsRowContainer}>
                            <div className={classes.formToptext}>
                              <p>{t("Personal Info")}</p>
                            </div>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("Compay Name")}
                                required={true}
                                id={"company-name"}
                                formRef={companyName}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("Mobile")}
                                required={true}
                                id={"mobile"}
                                formRef={mobile}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"email"}
                                label={t("Email")}
                                required={true}
                                id={"email"}
                                formRef={email}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("Vacancy")}
                                id={"vacancy"}
                                formRef={vacancy}
                              />
                            </Col>
                            <Col lg={10} md={12}>
                              <FormInput
                                type={"textarea"}
                                label={t("Job Requirements")}
                                id={"job-requirements"}
                                formRef={jobRequirements}
                              />
                            </Col>
                            <div className={classes.btnSubmitHolder}>
                              <button
                                className={`btn ${classes.btnSend}`}
                                type="submit"
                              >
                                {t("Send")}
                              </button>
                            </div>
                          </Row>
                        </Form>
                      </Flex>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </MainLayout>
          </Tablet>

          {/* Mobile */}
          <Mobile>
            <MainLayout addPadding={false}>
              <Container fluid className={classes.formContainer}>
                <Row>
                  <Col lg={4} md={6} style={{ marginTop: "30px" }}>
                    <LeftSide
                    image={leftSideImage[0].image}
                      bodyTitle={
                        isArabic
                          ? orphanLeftSideData[0].value_ar
                          : orphanLeftSideData[0].value
                      }
                      bodyDesc={
                        isArabic
                          ? orphanLeftSideData[1].value_ar
                          : orphanLeftSideData[1].value
                      }
                      removeHeightWidth={true}
                    />
                  </Col>
                  <Col lg={8} md={6}>
                    <Container style={{ marginTop: "30px" }}>
                      <Flex column className={classes.formFieldsHolder}>
                        <FormHeader
                          mainTitle={
                            isArabic
                              ? orphanLeftSideData[4].value_ar
                              : orphanLeftSideData[4].value
                          }
                          subTitle={
                            isArabic
                              ? orphanLeftSideData[3].value_ar
                              : orphanLeftSideData[3].value
                          }
                        />
                        <Form
                          method="POST"
                          className={classes.mainForm}
                          encType="multipart/form-data"
                          onSubmit={handleOnSubmit}
                        >
                          <Row className={classes.formFieldsRowContainer}>
                            <div className={classes.formToptext}>
                              <p>{t("Personal Info")}</p>
                            </div>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("Compay Name")}
                                required={true}
                                id={"company-name"}
                                formRef={companyName}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("Mobile")}
                                required={true}
                                id={"mobile"}
                                formRef={mobile}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"email"}
                                label={t("Email")}
                                required={true}
                                id={"email"}
                                formRef={email}
                              />
                            </Col>
                            <Col lg={5} md={5}>
                              <FormInput
                                type={"text"}
                                label={t("Vacancy")}
                                id={"vacancy"}
                                formRef={vacancy}
                              />
                            </Col>
                            <Col lg={10} md={12}>
                              <FormInput
                                type={"textarea"}
                                label={t("Job Requirements")}
                                id={"job-requirements"}
                                formRef={jobRequirements}
                              />
                            </Col>
                            <div className={classes.btnSubmitHolder}>
                              <button
                                className={`btn ${classes.btnSend}`}
                                type="submit"
                              >
                                {t("Send")}
                              </button>
                            </div>
                          </Row>
                        </Form>
                      </Flex>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </MainLayout>
          </Mobile>
        </>
      )}
    </>
  );
};

export default withTranslation()(EmployAnOrphan);
