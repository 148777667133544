import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import {isArabic, baseUrl}  from '../../helpers/helpersFunctions';
import "../form-input/FormInput.module.scss";

const ContactSelectList = ({label, id, required=false, formRef, t}) => {

    const [contactsList, setContacstList] = useState([]);

    useEffect(() => {
        fetch( `${baseUrl}/GetContactCategories`)
          .then(res => res.json())
          .then(
            (data) => {
                setContacstList(data.Result);
            },
            (error) => {
              console.log("error", error);
            }
          )
      }, [])


  return (
    <div className="input-form-holder mb-3">
    <label htmlFor={id} className="form-label">{label}{required && t(" (required)")}</label>
    <select className="form-select" aria-label="Conatact" id={id}
    style={{height:"60px", borderRadius:"16px", paddingRight: isArabic && "37px"}}>
    <option>{t("Select")}</option>
      {
          contactsList.map((contact, index) => {
              return <option
               key={contact.id}
               value={contact.id}
               ref={formRef}
               >{isArabic ? contact.name_ar : contact.name}</option>
          })
      }
  </select>
  </div>
  )
}

export default withTranslation()(ContactSelectList);