import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import MainLayout from "../../layouts/MainLayout";
import classes from "./OurPrograms.module.scss";
import { withTranslation } from "react-i18next";
import { baseUrl, storageUrl, isArabic } from "../../helpers/helpersFunctions";
import { useEffect } from "react";
import { Desktop, Mobile, Tablet } from "../../helpers/BreakPoints";
import Loader from "../../components/loader/Loader";

const OurPrograms = ({ t }) => {
  const [ourProgramsData, setOurProgramsData] = useState([]);
  const [isLoading, SetIsLoading] = useState(true);

  const fetchProgramsData = async () => {
    await fetch(`${baseUrl}/GetPrograms`)
      .then((res) => res.json())
      .then(
        (data) => {
          setOurProgramsData(data.Result);
          SetIsLoading(false);
        },
        (error) => {
          console.log("something went wrong fetching Home Counters => ", error);
          SetIsLoading(false);
        }
      );
  };

  useEffect(() => {
    document.title = t("Our Programs");
    fetchProgramsData();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* Desktop */}
          <Desktop>
            <MainLayout addPadding={false}>
              <Container className={classes.programsContainer}>
                <div className={classes.OurProgramsTitleHolder}>
                  <h1>{t("Our Programs")}</h1>
                </div>
                <Row>
                  {ourProgramsData.map((program, index) => {
                    return (
                      <Col lg={4} md={4} key={program.id} className="mb-3">
                        <Card
                          key={program.id + 1}
                          className={classes.programMainCard}
                        >
                          <Card.Img
                            variant="top"
                            className={classes.programThumbHolder}
                            src={storageUrl + program.image}
                          />
                          <Card.Body className={classes.programCardBody}>
                            <Card.Title className={classes.programTitleCard}>
                              {isArabic ? program.title_ar : program.title}
                            </Card.Title>
                            <Card.Text className={classes.programCardDesc}>
                              {isArabic ? program.text_ar : program.text}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </MainLayout>
          </Desktop>

          {/* Tablet */}
          <Tablet>
            <MainLayout addPadding={false}>
              <Container
                className={classes.programsContainer}
                style={{ paddingTop: "30px" }}
              >
                <div className={classes.OurProgramsTitleHolder}>
                  <h1>{t("Our Programs")}</h1>
                </div>
                <Row>
                  {ourProgramsData.map((program, index) => {
                    return (
                      <Col lg={4} md={4} key={program.id} className="mb-3">
                        <Card
                          key={program.id + 1}
                          className={classes.programMainCard}
                        >
                          <Card.Img
                            variant="top"
                            className={classes.programThumbHolder}
                            src={storageUrl + program.image}
                          />
                          <Card.Body className={classes.programCardBody}>
                            <Card.Title className={classes.programTitleCard}>
                              {isArabic ? program.title_ar : program.title}
                            </Card.Title>
                            <Card.Text
                              className={classes.programCardDesc}
                              style={{ width: "auto" }}
                            >
                              {isArabic ? program.text_ar : program.text}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </MainLayout>
          </Tablet>

          {/* Mobile */}
          <Mobile>
            <MainLayout addPadding={false}>
              <Container
                className={classes.programsContainer}
                style={{ paddingTop: "30px" }}
              >
                <div className={classes.OurProgramsTitleHolder}>
                  <h1>{t("Our Programs")}</h1>
                </div>
                <Row>
                  {ourProgramsData.map((program, index) => {
                    return (
                      <Col lg={6} md={6} key={program.id} className="mb-3">
                        <Card
                          key={program.id + 1}
                          className={classes.programMainCard}
                        >
                          <Card.Img
                            variant="top"
                            className={classes.programThumbHolder}
                            src={storageUrl + program.image}
                          />
                          <Card.Body className={classes.programCardBody}>
                            <Card.Title className={classes.programTitleCard}>
                              {isArabic ? program.title_ar : program.title}
                            </Card.Title>
                            <Card.Text
                              className={classes.programCardDesc}
                              style={{ width: "auto" }}
                            >
                              {isArabic ? program.text_ar : program.text}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </MainLayout>
          </Mobile>
        </>
      )}
    </>
  );
};

export default withTranslation()(OurPrograms);
