import React, { useEffect, useRef } from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import { withTranslation } from "react-i18next";
import classes from "./MainLayout.module.scss";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { isArabic } from "../helpers/helpersFunctions";
import logo from "../assets/images/shared/logo.svg";

const MainLayout = ({
  children,
  addPadding = true,
  whiteBg = false,
  t,
  i18n,
}) => {
  const handleOnClick = (e) => {
    e.preventDefault();
    window.location.replace("https://wa.me/message/3D6Q5VVVHZAUB1");
  };

  return (
    <>
      <Header />
      <main
        className={`${
          addPadding ? classes.layoutMain : classes.layoutMainNoPadding
        } container-fluid`}
        style={{ background: whiteBg && "white" }}
      >
        {children}
        {/* <FloatingWhatsApp
          accountName={isArabic ? "صندوق الأمان" : "Al-Aman Fund"}
          avatar={logo}
          statusMessage=""
          onClick={handleOnClick}
        /> */}
      </main>
      <Footer />
    </>
  );
};

export default withTranslation()(MainLayout);
