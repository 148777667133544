export default class HomeService {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    /**
     *
     * @param {*} setHomeTexts
     * @returns
     */
    async fetchHomeTexts(setHomeTexts) {
        await fetch(`${this.baseUrl}/GetTexts/Home`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setHomeTexts(data.Result);
                    console.log("data => ", data.Result);
                },
                (error) => {
                    console.log("something went wrong fetching Home Texts => ", error);
                }
            );

        return;
    }

    /**
     *
     * @param {*} setHomeCounters
     * @returns
     */
    async fetchHomeCounters(setHomeCounters) {
        await fetch(`${this.baseUrl}/GetCounters/home`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setHomeCounters(data.Result);
                },
                (error) => {
                    console.log("something went wrong fetching Home Counters => ", error);
                }
            );

        return;
    }

    /**
     *
     * @param {*} setHomeImages
     * @returns
     */
    async fetchHomeImages(setHomeImages) {
        await fetch(`${this.baseUrl}/GetImages/home`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setHomeImages(data.Result);
                },
                (error) => {
                    console.log("something went wrong fetching Home Images => ", error);
                }
            );

        return;
    }

    /**
     *
     * @param {*} setHomeSuccessStories
     * @returns
     */
    async fetchHomeSucessStories(setHomeSuccessStories) {
        await fetch(`${this.baseUrl}/GetSuccessStories`)
            .then((res) => res.json())
            .then(
                (data) => {
                    setHomeSuccessStories(data.Result);
                },
                (error) => {
                    console.log(
                        "something went wrong fetching Home Success Stories => ",
                        error
                    );
                }
            );

        return;
    }

    /**
     *
     * @param {*} setHomePrograms
     * @returns
     */
    async fetchHomePrograms(setHomePrograms) {
        await fetch(`${this.baseUrl}/GetPrograms`)
            .then((res) => res.json())
            .then(
                (data) => {
                    if (data.Result.length <= 4) {
                        setHomePrograms(data.Result);
                    } else {
                        setHomePrograms(data.Result.splice(-1, -5));
                    }
                },
                (error) => {
                    console.log("something went wrong fetching Home Programs => ", error);
                }
            );

        return;
    }


    async fetchQueenInvlovements(setQueenNews, setMainQueenInv) {
        await fetch(`${this.baseUrl}/GetNews/3`)
            .then((res) => res.json())
            .then(
                (news) => {
                    setMainQueenInv(news.Result.data.slice(-1)[0]);
                    setQueenNews(news.Result.data.slice(0, news.Result.data.length - 1));
                },
                (error) => {
                    console.log("something went wrong fetching Queen Invlovements => ", error);
                }
            );

        return;
    }

    /**
     *
     * @param {*} setIsLoading
     * @param {*} setHomeTexts
     * @param {*} setHomeCounters
     * @param {*} setHomeImages
     * @param {*} setHomeSuccessStories
     * @param {*} setHomePrograms
     * @param {*} setQueenNews
     * @param {*} setMainQueenInv
     */
    async getHomeData(
        setIsLoading,
        setHomeTexts,
        setHomeCounters,
        setHomeImages,
        setHomeSuccessStories,
        setHomePrograms,
        setQueenNews,
        setMainQueenInv
    ) {
        try {
            const response = await Promise.allSettled([
                this.fetchHomeTexts(setHomeTexts),
                this.fetchHomeCounters(setHomeCounters),
                this.fetchHomeImages(setHomeImages),
                this.fetchHomeSucessStories(setHomeSuccessStories),
                this.fetchHomePrograms(setHomePrograms),
                this.fetchQueenInvlovements(setQueenNews, setMainQueenInv)
            ]);
            setIsLoading(false);
        } catch {
            console.log("something went wrong fetching all homeData");
            setIsLoading(false);
        }
    }
}