import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import MainLayout from "../../layouts/MainLayout";
import classes from "./Contact.module.scss";
import Flex from "@react-css/flex";
import { withTranslation } from "react-i18next";
import facebookIcon from "../../assets/images/contact-media/facebook-icon.svg";
import instagramIcon from "../../assets/images/contact-media/instagram-icon.svg";
import twitterIcon from "../../assets/images/contact-media/twitter-icon.svg";
import linkedInIcon from "../../assets/images/contact-media/linkedin-icon.svg";
import youtubeIcone from "../../assets/images/contact-media/youtube-icon.svg";
import FormInput from "../../components/form-input/FormInput";
import ContactServices from "./ContactServices";
import { baseUrl, isArabic } from "../../helpers/helpersFunctions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ContactSelectList from "../../components/form-select-lists/ContactSelectList";
import { Desktop, Mobile, Tablet } from "../../helpers/BreakPoints";
import Loader from "../../components/loader/Loader";

const Contact = ({ t }) => {
  const [contactData, setContactData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const contServices = new ContactServices(baseUrl);
  const [showAlert, setShowAlert] = useState(false);
  const [passed, setPassed] = useState(null);
  const navigate = useNavigate();

  // Ref Part
  const name = React.createRef();
  const email = React.createRef();
  const phone = React.createRef();
  const subject = React.createRef();
  const contact_category_id = React.createRef();
  const message = React.createRef();

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      // Adding data to formData
      formData.append("name", name.current.value);
      formData.append("email", email.current.value);
      formData.append("phone", phone.current.value);
      formData.append("subject", subject.current.value);
      formData.append("contact_category_id", contact_category_id.current.value);
      formData.append("message", message.current.value);

      const response = await axios({
        method: "post",
        url: `${baseUrl}/SendMessage`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        setPassed(true);
        setShowAlert(true);
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setPassed(false);
        showAlert(true);
      }
    } catch (err) {
      setPassed(false);
      setShowAlert(true);
      throw err;
    }
  };

  useEffect(() => {
    document.title = t("contact");
    contServices.getContactFormData(setIsLoading, setContactData);
  }, []);


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* Desktop */}
          <Desktop>
            <MainLayout addPadding={false}>
              <Container className="mb-4">
                <Row className={classes.contactFormRow}>
                  <Col lg={8} md={6}>
                    <div className={classes.contactHolder}>
                      <div className={classes.titleHolder}>
                        <h1>
                          {isArabic
                            ? contactData[0].value_ar
                            : contactData[0].value}
                        </h1>
                      </div>
                      <div className={classes.descHolder}>
                        <p>
                          {isArabic
                            ? contactData[1].value_ar
                            : contactData[1].value}
                        </p>
                      </div>
                      <Flex
                        row
                        className={classes.socialMediaHolder}
                        alignItemsCenter
                      >
                        <img src={facebookIcon} alt="Facebook" />
                        <img src={instagramIcon} alt="Instagram" />
                        <img src={twitterIcon} alt="Twitter" />
                        <img src={linkedInIcon} alt="Linkedin" />
                        <img src={youtubeIcone} alt="Youtube" />
                      </Flex>
                    </div>
                    {/* Forms section */}
                    <Flex column className={classes.formHolder}>
                      <div className={classes.formTitle}>
                        <p>{t("Personal Info")}</p>
                      </div>
                      <form
                        className={classes.contactFormHolder}
                        method="POST"
                        encType="multipart/form-data"
                        onSubmit={handleOnSubmit}
                      >
                        <FormInput
                          type={"text"}
                          label={t("Name")}
                          required={true}
                          id={"name"}
                          formRef={name}
                        />
                        <FormInput
                          type={"text"}
                          label={t("Contact number")}
                          required={true}
                          id={"contact"}
                          formRef={phone}
                        />
                        <FormInput
                          type={"text"}
                          label={t("Email")}
                          required={true}
                          id={"email"}
                          formRef={email}
                        />
                        <ContactSelectList
                          label={t("Category")}
                          required={true}
                          id={"category"}
                          formRef={contact_category_id}
                        />
                        <FormInput
                          type={"text"}
                          label={t("Subject")}
                          required={true}
                          id={"subject"}
                          formRef={subject}
                        />
                        <FormInput
                          type={"textarea"}
                          label={t("Message")}
                          required={true}
                          id={"message"}
                          formRef={message}
                        />
                        <button
                          className={`btn ${classes.btnSend}`}
                          type="submit"
                        >
                          {t("Send")}
                        </button>
                      </form>
                    </Flex>
                  </Col>
                </Row>
              </Container>
            </MainLayout>
          </Desktop>

          {/* Tablet */}
          <Tablet>
            <MainLayout>
              <Container className="mb-4">
                <Row className={classes.contactFormRow}>
                  <Col lg={8} md={6}>
                    <div className={classes.contactHolder}>
                      <div className={classes.titleHolder}>
                        <h1>
                          {isArabic
                            ? contactData[0].value_ar
                            : contactData[0].value}
                        </h1>
                      </div>
                      <div
                        className={classes.descHolder}
                        style={{ width: "auto" }}
                      >
                        <p>
                          {isArabic
                            ? contactData[1].value_ar
                            : contactData[1].value}
                        </p>
                      </div>
                      <Flex
                        row
                        className={classes.socialMediaHolder}
                        alignItemsCenter
                      >
                        <img src={facebookIcon} alt="Facebook" />
                        <img src={instagramIcon} alt="Instagram" />
                        <img src={twitterIcon} alt="Twitter" />
                        <img src={linkedInIcon} alt="Linkedin" />
                        <img src={youtubeIcone} alt="Youtube" />
                      </Flex>
                    </div>
                    {/* Forms section */}
                    <Flex column className={classes.formHolder}>
                      <div className={classes.formTitle}>
                        <p>{t("Personal Info")}</p>
                      </div>
                      <form
                        className={classes.contactFormHolder}
                        method="POST"
                        encType="multipart/form-data"
                        onSubmit={handleOnSubmit}
                      >
                        <FormInput
                          type={"text"}
                          label={t("Name")}
                          required={true}
                          id={"name"}
                          formRef={name}
                        />
                        <FormInput
                          type={"text"}
                          label={t("Contact number")}
                          required={true}
                          id={"contact"}
                          formRef={phone}
                        />
                        <FormInput
                          type={"text"}
                          label={t("Email")}
                          required={true}
                          id={"email"}
                          formRef={email}
                        />
                        <ContactSelectList
                          label={t("Category")}
                          required={true}
                          id={"category"}
                          formRef={contact_category_id}
                        />
                        <FormInput
                          type={"text"}
                          label={t("Subject")}
                          required={true}
                          id={"subject"}
                          formRef={subject}
                        />
                        <FormInput
                          type={"textarea"}
                          label={t("Message")}
                          required={true}
                          id={"message"}
                          formRef={message}
                        />
                        <button
                          className={`btn ${classes.btnSend}`}
                          type="submit"
                        >
                          {t("Send")}
                        </button>
                      </form>
                    </Flex>
                  </Col>
                </Row>
              </Container>
            </MainLayout>
          </Tablet>

          {/* Mobile */}
          <Mobile>
            <MainLayout addPadding={false}>
              <Container className="mb-4">
                <Row className={classes.contactFormRow}>
                  <Col lg={8} md={6}>
                    <div className={classes.contactHolder}>
                      <div className={classes.titleHolder}>
                        <h1>
                          {isArabic
                            ? contactData[0].value_ar
                            : contactData[0].value}
                        </h1>
                      </div>
                      <div
                        className={classes.descHolder}
                        style={{ width: "auto" }}
                      >
                        <p>
                          {isArabic
                            ? contactData[1].value_ar
                            : contactData[1].value}
                        </p>
                      </div>
                      <Flex
                        row
                        className={classes.socialMediaHolder}
                        alignItemsCenter
                      >
                        <img src={facebookIcon} alt="Facebook" />
                        <img src={instagramIcon} alt="Instagram" />
                        <img src={twitterIcon} alt="Twitter" />
                        <img src={linkedInIcon} alt="Linkedin" />
                        <img src={youtubeIcone} alt="Youtube" />
                      </Flex>
                    </div>
                    {/* Forms section */}
                    <Flex column className={classes.formHolder}>
                      <div className={classes.formTitle}>
                        <p>{t("Personal Info")}</p>
                      </div>
                      <form
                        className={classes.contactFormHolder}
                        method="POST"
                        encType="multipart/form-data"
                        onSubmit={handleOnSubmit}
                      >
                        <FormInput
                          type={"text"}
                          label={t("Name")}
                          required={true}
                          id={"name"}
                          formRef={name}
                        />
                        <FormInput
                          type={"text"}
                          label={t("Contact number")}
                          required={true}
                          id={"contact"}
                          formRef={phone}
                        />
                        <FormInput
                          type={"text"}
                          label={t("Email")}
                          required={true}
                          id={"email"}
                          formRef={email}
                        />
                        <ContactSelectList
                          label={t("Category")}
                          required={true}
                          id={"category"}
                          formRef={contact_category_id}
                        />
                        <FormInput
                          type={"text"}
                          label={t("Subject")}
                          required={true}
                          id={"subject"}
                          formRef={subject}
                        />
                        <FormInput
                          type={"textarea"}
                          label={t("Message")}
                          required={true}
                          id={"message"}
                          formRef={message}
                        />
                        <button
                          className={`btn ${classes.btnSend}`}
                          type="submit"
                        >
                          {t("Send")}
                        </button>
                      </form>
                    </Flex>
                  </Col>
                </Row>
              </Container>
            </MainLayout>
          </Mobile>
        </>
      )}
    </>
  );
};

export default withTranslation()(Contact);
