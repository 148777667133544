import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import MainLayout from "../../layouts/MainLayout";
import classes from "./Eligibility.module.scss";
import { withTranslation } from "react-i18next";
import AdmissionRequirements from "../../components/admission-requirements/AdmissionRequirements";
import dotBehind from "../../assets/images/index-media/blured-dot-behind.svg";
import yellowDot from "../../assets/images/index-media/blured-yellow-dot.svg";
import EligibilityServices from "./EligibilityServices";
import { baseUrl, isArabic, storageUrl } from "../../helpers/helpersFunctions";
import { useEffect } from "react";
import { Desktop, Mobile, Tablet } from "../../helpers/BreakPoints";
import Loader from "../../components/loader/Loader";
import Flex from "@react-css/flex";
import { useRef } from "react";
import Linkify from 'react-linkify';

const Eligibility = ({ t }) => {
  const [eligibilityData, setEligibilityData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [eliIcons, setEliIcons] = useState([]);
  const [admissionRequirements, setAdmissionRequirements] = useState([]);
  const eliServices = new EligibilityServices(baseUrl);
  const [fileUrl, setFileUrl] = useState([]);
  const [isReadMore, setIsReadMore] = useState(true);
  const txtBoxRef = useRef(null);

  useEffect(() => {
    document.title = t("Eligibility");
    eliServices.getAllData(
      setIsLoading,
      setEligibilityData,
      setEliIcons,
      setAdmissionRequirements,
      setFileUrl
    );
  }, []);


  const scrollToBoxTop = () => {
      console.log("Started scrolling!");
      console.log(window.screenY);
      txtBoxRef?.current.scrollIntoView({ behavior: "smooth" });

  }

  /**
   * Toggle text
   */
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
    // !isReadMore && scrollToBoxTop();
  }


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* Desktop */}
          <Desktop>
            <MainLayout addPadding={false}>
              {/* Section 1 */}
              <section className={classes.eligSection1}>
                <Container>
                  <Row>
                    <Col lg={9} md={6}>
                      <div className={classes.eligibilityTitleHolder}>
                        <h1>
                          {isArabic
                            ? eligibilityData[0].value_ar
                            : eligibilityData[0].value}
                        </h1>
                      </div>
                      <div className={classes.eligibilityDescHolder}>
                        <pre>
                          {isArabic ? (
                            <Linkify>{eligibilityData[1].value_ar}</Linkify>
                          ) : (
                            <Linkify>{eligibilityData[1].value}</Linkify>
                          )}
                        </pre>
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className={classes.section1RightSideHolder}>
                        <div className={classes.ImageHolder}>
                          <img
                            src={storageUrl + eliIcons[0].image}
                            alt="Graduated girl"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
              {/* Section 2 */}
              <section className={classes.eligSection2} ref={txtBoxRef}>
                <Container>
                  <Row>
                    <Col lg={2} md={6}>
                      <div className={classes.LeftSideTitleHolder}>
                        <h1>
                          {isArabic
                            ? eligibilityData[2].value_ar
                            : eligibilityData[2].value}
                        </h1>
                      </div>
                    </Col>
                    <Col lg={5} md={6} style={{ paddingLeft: "60px" }}>
                      <div className={classes.section2MidTitleHolder}>
                        <h1>
                          {isArabic
                            ? eligibilityData[7].value_ar
                            : eligibilityData[7].value}
                        </h1>
                      </div>
                      <div className={classes.section2MidDescHolder}>
                        {isReadMore ?
                        <pre>
                          {isArabic
                            ? 
                            (
                              <Linkify>
                                {eligibilityData[3].value_ar.slice(0, 200) + (isReadMore ? "..." : "")}
                              </Linkify>
                            )
                            : 
                            (
                              <Linkify>
                                {eligibilityData[3].value.slice(0, 200) + (isReadMore ? "..." : "")}
                              </Linkify>
                            )
                            }
                            <span onClick={toggleReadMore}
                            className="read-or-hide"
                            style={{textDecoration:"underline" ,cursor:"pointer"}}>
                              {isReadMore ? t("read more") : t(" Show less")}
                            </span>
                        </pre>
                        :
                        <pre>
                          {isArabic
                            ? 
                            (
                              <Linkify>
                                {eligibilityData[3].value_ar+(isReadMore ? "..." :  "")}
                              </Linkify>
                            )
                            : 
                            (
                              <Linkify>
                                {eligibilityData[3].value+(isReadMore ? "..." :  "")}
                              </Linkify>
                            )
                             }
                            <span onClick={toggleReadMore}
                            className="read-or-hide"
                            style={{textDecoration:"underline", cursor:"pointer"}}>
                              {isReadMore ? t("read more") : t(" Show less")}
                            </span>
                        </pre>
  }
                      </div>
                    </Col>
                    <Col lg={5} md={6} style={{ paddingLeft: "47px" }}>
                      <div className={classes.section2RightSideTitleHolder}>
                        <h1>
                          {isArabic
                            ? eligibilityData[4].value_ar
                            : eligibilityData[4].value}
                        </h1>
                      </div>
                      <div className={classes.section2RightDescHolder}>
                      {isReadMore ?
                        <pre>
                          {isArabic
                            ? 
                            (
                              <Linkify>
                                {eligibilityData[5].value_ar.slice(0, 200)+(isReadMore ? "..." :  "")}
                              </Linkify>
                            )
                            : (
                              <Linkify>
                                {eligibilityData[5].value.slice(0,200)+(isReadMore ? "..." :  "")}
                              </Linkify>
                            )
                            }
                            <span onClick={toggleReadMore}
                            className="read-or-hide"
                            style={{textDecoration:"underline" ,cursor:"pointer"}}>
                              {isReadMore ? t("read more") : t(" Show less")}
                            </span>
                        </pre>
                        :
                        <pre>
                          {isArabic
                            ? 
                            (
                              <Linkify>
                                {eligibilityData[5].value_ar+(isReadMore ? "..." :  "")}
                              </Linkify>
                            )
                            : 
                            (
                              <Linkify>
                                {eligibilityData[5].value+(isReadMore ? "..." :  "")}
                              </Linkify>
                            )
                            }
                            <span onClick={toggleReadMore}
                            className="read-or-hide"
                            style={{textDecoration:"underline", cursor:"pointer"}}>
                              {isReadMore ? t("read more") : t(" Show less")}
                            </span>
                        </pre>
  }
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
              {/* Section 3 */}
              <section className={classes.eligSection3}>
                <Container>
                  <Row>
                    <div className={classes.section3TitleHolder}>
                      <h1>
                        {isArabic
                          ? eligibilityData[6].value_ar
                          : eligibilityData[6].value}
                      </h1>
                    </div>
                  </Row>
                  <Row style={{ position: "relative" }}>
                    <Col lg={3} md={6}>
                      <AdmissionRequirements
                        icon={storageUrl + admissionRequirements[0].image}
                        title={
                          isArabic
                            ? admissionRequirements[0].title_ar
                            : admissionRequirements[0].title
                        }
                        desc={
                          isArabic
                            ? admissionRequirements[0].text_ar
                            : admissionRequirements[0].text
                        }
                      />
                    </Col>
                    <Col lg={3} md={6} style={{ position: "relative" }}>
                      <AdmissionRequirements
                        icon={storageUrl + admissionRequirements[1].image}
                        title={
                          isArabic
                            ? admissionRequirements[1].title_ar
                            : admissionRequirements[1].title
                        }
                        desc={
                          isArabic
                            ? admissionRequirements[1].text_ar
                            : admissionRequirements[1].text
                        }
                      />
                    </Col>
                    <Col lg={3} md={6}>
                      <AdmissionRequirements
                        icon={storageUrl + admissionRequirements[2].image}
                        title={
                          isArabic
                            ? admissionRequirements[2].title_ar
                            : admissionRequirements[2].title
                        }
                        desc={
                          isArabic
                            ? admissionRequirements[2].text_ar
                            : admissionRequirements[2].text
                        }
                      />
                    </Col>
                    <Col lg={3} md={6}>
                      <AdmissionRequirements
                        icon={storageUrl + admissionRequirements[3].image}
                        title={
                          isArabic
                            ? admissionRequirements[3].title_ar
                            : admissionRequirements[3].title
                        }
                        desc={
                          isArabic
                            ? admissionRequirements[3].text_ar
                            : admissionRequirements[3].text
                        }
                      />
                    </Col>
                  </Row>
                </Container>
              </section>

              {/* Btns Section */}
              <section
                className={classes.eligSection4}
                style={{ paddingBottom: "30px" }}
              >
                <Flex
                  row
                  alignItemsCenter
                  justifySpaceAround
                  className={classes.externalLinkHolder}
                >
                  <div>
                    <li className={`${classes.donateBtn}`}>
                      <a target="blank" href={eligibilityData[8].value}>
                        {" "}
                        {isArabic
                          ? eligibilityData[9].value_ar
                          : eligibilityData[9].value}
                      </a>
                    </li>
                  </div>
                  <div>
                    <li className={`${classes.donateBtnRes}`}>
                      <a target="blank" href={storageUrl + fileUrl[0].file}>
                        {" "}
                        {isArabic
                          ? eligibilityData[10].value_ar
                          : eligibilityData[10].value}
                      </a>
                    </li>
                  </div>
                </Flex>
              </section>
            </MainLayout>
          </Desktop>

          {/* Tablet */}
          <Tablet>
            <MainLayout addPadding={false}>
              {/* Section 1 */}
              <section className={classes.eligSection1}>
                <Container>
                  <Row>
                    <Col lg={9} md={6}>
                      <div className={classes.eligibilityTitleHolder}>
                        <h1>
                          {isArabic
                            ? eligibilityData[0].value_ar
                            : eligibilityData[0].value}
                        </h1>
                      </div>
                      <div className={classes.eligibilityDescHolder}>
                        <pre>
                          {isArabic ? (
                            <Linkify>{eligibilityData[1].value_ar}</Linkify>
                          ) : (
                            <Linkify>{eligibilityData[1].value}</Linkify>
                          )}
                        </pre>
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className={classes.section1RightSideHolder}>
                        <div className={classes.ImageHolder}>
                          <img
                            src={storageUrl + eliIcons[0].image}
                            alt="Graduated girl"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
              {/* Section 2 */}
              <section className={classes.eligSection2}>
                <Container>
                  <Row>
                  <div className={classes.LeftSideTitleHolder}>
                        <h1 style={{fontSize:"45px", textAlign:"center", marginBottom:"40px"}}>
                          {isArabic
                            ? eligibilityData[2].value_ar
                            : eligibilityData[2].value}
                        </h1>
                      </div>
                    <Col lg={6} md={6}>
                      <div className={classes.section2MidTitleHolder}>
                        <h1 style={{fontSize:"22px"}}>
                          {isArabic
                            ? eligibilityData[7].value_ar
                            : eligibilityData[7].value}
                        </h1>
                      </div>
                      <div className={classes.section2MidDescHolder}>
                      {isReadMore ?
                        <pre>
                          {isArabic
                            ? eligibilityData[3].value_ar.slice(0, 200)+(isReadMore ? "..." :  "")
                            : eligibilityData[3].value.slice(0,200)+(isReadMore ? "..." :  "")
                            }
                            <span onClick={toggleReadMore}
                            className="read-or-hide"
                            style={{textDecoration:"underline" ,cursor:"pointer"}}>
                              {isReadMore ? t("read more") : t(" Show less")}
                            </span>
                        </pre>
                        :
                        <pre>
                          {isArabic
                            ? eligibilityData[3].value_ar+(isReadMore ? "..." :  "")
                            : eligibilityData[3].value+(isReadMore ? "..." :  "")}
                            <span onClick={toggleReadMore}
                            className="read-or-hide"
                            style={{textDecoration:"underline", cursor:"pointer"}}>
                              {isReadMore ? t("read more") : t(" Show less")}
                            </span>
                        </pre>
  }
                      </div>
                    </Col>
                    <Col lg={6} md={6} style={{ paddingLeft: "47px" }}>
                      <div className={classes.section2RightSideTitleHolder}>
                        <h1 style={{fontSize:"22px"}}>
                          {isArabic
                            ? eligibilityData[4].value_ar
                            : eligibilityData[4].value}
                        </h1>
                      </div>
                      <div className={classes.section2RightDescHolder}>
                      {isReadMore ?
                        <pre>
                          {isArabic
                            ? eligibilityData[5].value_ar.slice(0, 200)+(isReadMore && "...")
                            : eligibilityData[5].value.slice(0,200)+(isReadMore && "...")
                            }
                            <span onClick={toggleReadMore}
                            className="read-or-hide"
                            style={{textDecoration:"underline" ,cursor:"pointer"}}>
                              {isReadMore ? t("read more") : t(" Show less")}
                            </span>
                        </pre>
                        :
                        <pre>
                          {isArabic
                            ? eligibilityData[5].value_ar+(isReadMore && "...")
                            : eligibilityData[5].value +(isReadMore && "...") }
                            <span onClick={toggleReadMore}
                            className="read-or-hide"
                            style={{textDecoration:"underline", cursor:"pointer"}}>
                              {isReadMore ? t("read more") : t(" Show less")}
                            </span>
                        </pre>
  }
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
              {/* Section 3 */}
              <section className={classes.eligSection3}>
                <Container>
                  <Row>
                    <div className={classes.section3TitleHolder}>
                      <h1>
                        {isArabic
                          ? eligibilityData[6].value_ar
                          : eligibilityData[6].value}
                      </h1>
                    </div>
                  </Row>
                  <Row style={{ position: "relative" }}>
                    <Col lg={6} md={6} style={{ marginTop: "15px" }}>
                      <AdmissionRequirements
                        icon={storageUrl + admissionRequirements[0].image}
                        title={
                          isArabic
                            ? admissionRequirements[0].title_ar
                            : admissionRequirements[0].title
                        }
                        desc={
                          isArabic
                            ? admissionRequirements[0].text_ar
                            : admissionRequirements[0].text
                        }
                      />
                    </Col>
                    <Col
                      lg={6}
                      md={6}
                      style={{ position: "relative", marginTop: "15px" }}
                    >
                      <AdmissionRequirements
                        icon={storageUrl + admissionRequirements[1].image}
                        title={
                          isArabic
                            ? admissionRequirements[1].title_ar
                            : admissionRequirements[1].title
                        }
                        desc={
                          isArabic
                            ? admissionRequirements[1].text_ar
                            : admissionRequirements[1].text
                        }
                      />
                    </Col>
                    <Col lg={6} md={6} style={{ marginTop: "15px" }}>
                      <AdmissionRequirements
                        icon={storageUrl + admissionRequirements[2].image}
                        title={
                          isArabic
                            ? admissionRequirements[2].title_ar
                            : admissionRequirements[2].title
                        }
                        desc={
                          isArabic
                            ? admissionRequirements[2].text_ar
                            : admissionRequirements[2].text
                        }
                      />
                    </Col>
                    <Col lg={6} md={6} style={{ marginTop: "15px" }}>
                      <AdmissionRequirements
                        icon={storageUrl + admissionRequirements[3].image}
                        title={
                          isArabic
                            ? admissionRequirements[3].title_ar
                            : admissionRequirements[3].title
                        }
                        desc={
                          isArabic
                            ? admissionRequirements[3].text_ar
                            : admissionRequirements[3].text
                        }
                      />
                    </Col>
                  </Row>
                </Container>
              </section>

              {/* Section Btns */}
              <section
                className={classes.eligSection4}
                style={{ paddingBottom: "30px" }}
              >
                <Flex
                  row
                  alignItemsCenter
                  justifySpaceAround
                  className={classes.externalLinkHolder}
                  style={{ width: "50%" }}
                >
                  <div>
                    <li className={`${classes.donateBtn}`}>
                      <a target="blank" href={eligibilityData[8].value}>
                        {" "}
                        {isArabic
                          ? eligibilityData[9].value_ar
                          : eligibilityData[9].value}
                      </a>
                    </li>
                  </div>
                  <div>
                    <li className={`${classes.donateBtnRes}`}>
                      <a target="blank" href={storageUrl + fileUrl[0].file}>
                        {" "}
                        {isArabic
                          ? eligibilityData[10].value_ar
                          : eligibilityData[10].value}
                      </a>
                    </li>
                  </div>
                </Flex>
              </section>
            </MainLayout>
          </Tablet>

          {/* Mobile */}
          <Mobile>
            <MainLayout addPadding={false}>
              {/* Section 1 */}
              <section
                className={classes.eligSection1}
                style={{ marginBottom: "45px" }}
              >
                <Container>
                  <Row>
                    <Col lg={9} md={6}>
                      <div className={classes.eligibilityTitleHolder}>
                        <h1>
                          {isArabic
                            ? eligibilityData[0].value_ar
                            : eligibilityData[0].value}
                        </h1>
                      </div>
                      <div className={classes.eligibilityDescHolder}>
                        <pre>
                          {isArabic ? (
                            <Linkify>{eligibilityData[1].value_ar}</Linkify>
                          ) : (
                            <Linkify>{eligibilityData[1].value}</Linkify>
                          )}
                        </pre>
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <div className={classes.section1RightSideHolder}>
                        <div className={classes.ImageHolder}>
                          <img
                            src={storageUrl + eliIcons[0].image}
                            alt="Graduated girl"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
              {/* Section 2 */}
              <section className={classes.eligSection2}>
                <Container>
                  <Row>
                    <Col lg={3} md={6}>
                      <div className={classes.LeftSideTitleHolder}>
                        <h1 style={{ fontSize: "28px" }}>
                          {isArabic
                            ? eligibilityData[2].value_ar
                            : eligibilityData[2].value}
                        </h1>
                      </div>
                    </Col>
                    <Col lg={4} md={6}>
                      <div className={classes.section2MidTitleHolder}>
                        <h1 style={{ fontSize: "28px" }}>
                          {isArabic
                            ? eligibilityData[7].value_ar
                            : eligibilityData[7].value}
                        </h1>
                      </div>
                      <div className={classes.section2MidDescHolder}>
                      {isReadMore ?
                        <pre style={{width:"auto"}}>
                          {isArabic
                            ? eligibilityData[3].value_ar.slice(0, 200)+(isReadMore ? "..." :  "")
                            : eligibilityData[3].value.slice(0,200)+(isReadMore ? "..." :  "")
                            }
                            <span onClick={toggleReadMore}
                            className="read-or-hide"
                            style={{textDecoration:"underline" ,cursor:"pointer"}}>
                              {isReadMore ? t("read more") : t(" Show less")}
                            </span>
                        </pre>
                        :
                        <pre style={{width:"auto"}}>
                          {isArabic
                            ? eligibilityData[3].value_ar+(isReadMore ? "..." :  "")
                            : eligibilityData[3].value+(isReadMore ? "..." :  "") }
                            <span onClick={toggleReadMore}
                            className="read-or-hide"
                            style={{textDecoration:"underline", cursor:"pointer"}}>
                              {isReadMore ? t("read more") : t(" Show less")}
                            </span>
                        </pre>
  }
                      </div>
                    </Col>
                    <Col lg={5} md={6}>
                      <div className={classes.section2RightSideTitleHolder}>
                        <h1 style={{ fontSize: "28px" }}>
                          {isArabic
                            ? eligibilityData[4].value_ar
                            : eligibilityData[4].value}
                        </h1>
                      </div>
                      <div className={classes.section2RightDescHolder}>
                      {isReadMore ?
                        <pre style={{width:"auto"}}>
                          {isArabic
                            ? eligibilityData[5].value_ar.slice(0, 200)+(isReadMore && "...")
                            : eligibilityData[5].value.slice(0,200)+(isReadMore && "...")
                            }
                            <span onClick={toggleReadMore}
                            className="read-or-hide"
                            style={{textDecoration:"underline" ,cursor:"pointer"}}>
                              {isReadMore ? t("read more") : t(" Show less")}
                            </span>
                        </pre>
                        :
                        <pre style={{width:"auto"}}>
                          {isArabic
                            ? eligibilityData[5].value_ar+(isReadMore && "...")
                            : eligibilityData[5].value +(isReadMore && "...") }
                            <span onClick={toggleReadMore}
                            className="read-or-hide"
                            style={{textDecoration:"underline", cursor:"pointer"}}>
                              {isReadMore ? t("read more") : t(" Show less")}
                            </span>
                        </pre>
  }
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
              {/* Section 3 */}
              <section
                className={classes.eligSection3}
                style={{ paddingTop: "49px" }}
              >
                <Container fluid style={{ padding: "0 50px" }}>
                  <Row>
                    <div
                      className={classes.section3TitleHolder}
                      style={{ width: "auto" }}
                    >
                      <h1>
                        {isArabic
                          ? eligibilityData[6].value_ar
                          : eligibilityData[6].value}
                      </h1>
                    </div>
                  </Row>
                  <Row style={{ position: "relative" }}>
                    <Col lg={6} md={6} style={{ position: "relative", marginTop:"15px" }}>
                      <AdmissionRequirements
                        icon={storageUrl + admissionRequirements[0].image}
                        title={
                          isArabic
                            ? admissionRequirements[0].title_ar
                            : admissionRequirements[0].title
                        }
                        desc={
                          isArabic
                            ? admissionRequirements[0].text_ar
                            : admissionRequirements[0].text
                        }
                        isMobile={true}
                      />
                    </Col>
                    <Col lg={6} md={6} style={{ position: "relative", marginTop:"15px" }}>
                      <AdmissionRequirements
                        icon={storageUrl + admissionRequirements[1].image}
                        title={
                          isArabic
                            ? admissionRequirements[1].title_ar
                            : admissionRequirements[1].title
                        }
                        desc={
                          isArabic
                            ? admissionRequirements[1].text_ar
                            : admissionRequirements[1].text
                        }
                        isMobile={true}
                      />
                    </Col>
                    <Col lg={6} md={6} style={{marginTop:"15px"}}>
                      <AdmissionRequirements
                        icon={storageUrl + admissionRequirements[2].image}
                        title={
                          isArabic
                            ? admissionRequirements[2].title_ar
                            : admissionRequirements[2].title
                        }
                        desc={
                          isArabic
                            ? admissionRequirements[2].text_ar
                            : admissionRequirements[2].text
                        }
                        isMobile={true}
                      />
                    </Col>
                    <Col lg={6} md={6} style={{ position: "relative", marginTop:"15px" }}>
                      <AdmissionRequirements
                        icon={storageUrl + admissionRequirements[3].image}
                        title={
                          isArabic
                            ? admissionRequirements[3].title_ar
                            : admissionRequirements[3].title
                        }
                        desc={
                          isArabic
                            ? admissionRequirements[3].text_ar
                            : admissionRequirements[3].text
                        }
                        isMobile={true}
                      />
                    </Col>
                  </Row>
                </Container>
              </section>

              {/* Section Btns */}
              <section
                className={classes.eligSection4}
                style={{ paddingBottom: "30px" }}
              >
                <Flex
                  row
                  alignItemsCenter
                  justifySpaceAround
                  className={classes.externalLinkHolder}
                  style={{ width: "100%" }}
                >
                  <div>
                    <li className={`${classes.donateBtn}`}>
                      <a target="blank" href={eligibilityData[8].value}>
                        {" "}
                        {isArabic
                          ? eligibilityData[9].value_ar
                          : eligibilityData[9].value}
                      </a>
                    </li>
                  </div>
                  <div>
                    <li className={`${classes.donateBtnRes}`}>
                      <a target="blank" href={storageUrl + fileUrl[0].file}>
                        {" "}
                        {isArabic
                          ? eligibilityData[10].value_ar
                          : eligibilityData[10].value}
                      </a>
                    </li>
                  </div>
                </Flex>
              </section>
            </MainLayout>
          </Mobile>
        </>
      )}
    </>
  );
};

export default withTranslation()(Eligibility);
