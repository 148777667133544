import React from "react";
import { withTranslation } from "react-i18next";
import classes from "./Box.module.scss";
import Flex from "@react-css/flex";
import { Desktop, Tablet, Mobile } from "../../helpers/BreakPoints";
import { isArabic } from "../../helpers/helpersFunctions";

const Box = ({ title, icon, desc, color, isList = false, t }) => {
    return (
    <>

      <div>
        <Flex row alignItemsCenter className={classes.BoxHolder}>
          <p className={classes.boxText} style={{marginRight: isArabic && "10px"}}>{title}</p>
        </Flex>
        <div>
            {
                !isList ? <p className={classes.dataText}>{desc}</p> : desc.map((data, index) => {
                    return <p key={index} className={classes.dataText}>{data}</p>
                })
            }

        </div>
      </div>


      {/* Tablet */}
      {/* <Tablet>
      <div className={ (color === "yellow") ? classes.yellowBox : (color === "red") ? classes.redBoxTab : classes.blueBox} >
        <Flex row alignItemsCenter>
          <img src={icon} alt="Vision" />
          <p className="mb-0 w-100" style={{marginRight: isArabic && "10px"}}>{title}</p>
        </Flex>
        <div className={classes.boxDescHolder} style={{width:color === "red" ? "auto" :"80%"}}>
        {
                !isList ? <p className="mb-0">{desc}</p> : desc.map((data, index) => {
                    return <p key={index} className="mb-0">{data}</p>
                })
            }
        </div>
      </div>
      </Tablet> */}

      {/* Mobile */}
      <Mobile>
      {/* <div className={ (color === "yellow") ? classes.yellowBox : (color === "red") ? classes.redBox : classes.blueBox}
       style={{width:"auto", height: (color === "red") ? "auto" : (color === "blue") && "270px" }}
       >
        <Flex row alignItemsCenter className={classes.boxTitleHolderMob}>
          <img src={icon} alt="Vision" />
          <p className="mb-0" style={{marginRight: isArabic && "10px"}} >{title}</p>
        </Flex>
        <div style={{width:"74%", marginTop:"10px"}}>
        {
                !isList ? <p className="mb-0">{desc}</p> : desc.map((data, index) => {
                    return <p key={index} className="mb-0">{data}</p>
                })
            }
        </div>
      </div> */}
      </Mobile>
    </>
  );
};

export default withTranslation()(Box);
