import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { withTranslation } from "react-i18next";
import {
  getTodaysDate,
  isArabic,
  storageUrl,
  baseUrl,
} from "../../helpers/helpersFunctions";

// eslint-disable-next-line
import "swiper/css/bundle";
import "./styles.scss";
import { Pagination } from "swiper";
import NewsCardComponent from "./NewsCardComponent";
import { useEffect, useState } from "react";
import { Desktop, Tablet, Mobile } from "../../helpers/BreakPoints";

const NewsSwiper = ({ t, isMobile = false }) => {
  const [sliderNews, setSliderNews] = useState([]);

  const fetchSliderNews = async () => {
    await fetch(`${baseUrl}/GetNews/4`)
      .then((res) => res.json())
      .then(
        (news) => {
          setSliderNews(news.Result.data);
        },
        (error) => {
          console.log("something went wrong fetching slider News => ", error);
        }
      );
    return;
  };

  useEffect(() => {
    fetchSliderNews();
  }, []);

  return (
    <>
      {/* Desktop */}
      <Desktop>
        <Swiper
          key={isArabic}
          loop={true}
          dir={isArabic ? "rtl" : "ltr"}
          spaceBetween={50}
          className="mySwiper"
          moduules={{
            Pagination,
          }}
          breakpoints={{
            640: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 10,
              spaceBetween: 50,
            },
          }}
        >
          {sliderNews.map((item, index) => {
            return (
              <SwiperSlide key={item.id}>
                <NewsCardComponent
                  id={item.id}
                  thumb={storageUrl + item.thumb}
                  title={isArabic ? item.title_ar : item.title}
                  date={getTodaysDate()}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Desktop>

      {/* Tablet */}
      <Tablet>
        <Swiper
          key={isArabic}
          loop={true}
          dir={isArabic ? "rtl" : "ltr"}
          spaceBetween={40}
          className="mySwiper"
          moduules={{
            Pagination,
          }}
          breakpoints={{
            640: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
          }}
        >
          {sliderNews.map((item, index) => {
            return (
              <SwiperSlide key={item.id}>
                <NewsCardComponent
                  id={item.id}
                  thumb={storageUrl + item.thumb}
                  title={isArabic ? item.title_ar : item.title}
                  date={getTodaysDate()}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Tablet>

      {/* Mobile */}
      <Mobile>
        <Swiper
          slidesPerView={10}
          key={isArabic}
          loop={true}
          dir={isArabic ? "rtl" : "ltr"}
          spaceBetween={50}
          className="mySwiper"
          moduules={{
            Pagination,
          }}
          breakpoints={{
            640: {
              slidesPerView: 10,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
          }}
        >
          {sliderNews.map((item, index) => {
            return (
              <SwiperSlide
                className="mobile-swiper-slide"
                key={item.id}
                style={{ width: "250px !important" }}
              >
                <NewsCardComponent
                  id={item.id}
                  thumb={storageUrl + item.thumb}
                  title={isArabic ? item.title_ar : item.title}
                  date={getTodaysDate()}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Mobile>
    </>
  );
};

export default withTranslation()(NewsSwiper);
