import Pagination from "react-bootstrap/Pagination";
import _ from "lodash";
import "./pagination.css";

function createArrayWithSize(size) {
  return _.fill(Array(size), "");
}

const PaginationItems = ({ lastPage, currentPage, paginate }) => {
  const items = createArrayWithSize(lastPage);

  const handleClick = (pageNumber) => {
    paginate(pageNumber);
  };
  return (
    <Pagination size="sm">
      {items.map((_, i) => {
        return (
          <Pagination.Item
            key={i}
            active={i + 1 === currentPage}
            onClick={() => handleClick(i + 1)}
          >
            {i + 1}
          </Pagination.Item>
        );
      })}
    </Pagination>
  );
};
export default PaginationItems;
