import { useMediaQuery } from "react-responsive";


const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };

  const IsMobile = () => {return useMediaQuery({ query: '(max-width:  767px)' })};
  const IsTablet = () => {return useMediaQuery({ query: '( min-Width: 768) (max-width:  767px)'})};

  export  {Desktop, Tablet, Mobile, IsMobile, IsTablet};