import React from "react";
import { useEffect } from "react";
import "./FailurePayment.scss";

const FailurePaymnet = () => {
  useEffect(() => {
    document.title = "Payment Failure";
  });

  return (
    <>
      <div class="failure-holder">
        <div class="container">
          <div class="ui middle aligned center aligned grid">
            <div class="ui eight wide column">
              <form class="ui large form failure-form">
                <div class="ui icon negative message">
                  <i class="warning icon"></i>
                  <div class="content">
                    <div class="header">Oops! Something went wrong.</div>
                    <p>While trying to reserve money from your account</p>
                  </div>
                </div>
                <a href="/">
                  <span class="ui large teal submit fluid button">
                    Continue
                  </span>
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FailurePaymnet;
